import React, { useEffect, useRef, useState } from "react";
import { translations } from "../Language";
import api from "../api";
import MainCarouselBanner from "../styled_components/shared/MainCarouselBanner";
import { FiltersContainer, ViewContain } from "../styled_components/layout/ViewContain";
import { YellowButton } from "../styled_components/shared/YellowButton";
import { InfoTitle } from "../styled_components/shared/InfoTitle";
import { InfoText } from "../styled_components/shared/InfoText";
import InputField from "../styled_components/form/InputField";
import LoadingBars from "../styled_components/shared/LoadingBars";
import OpportunityCard from "../styled_components/marketplace/OpportunityCard";
import styled from "styled-components";
import GreenButton from "../layout/GreenButton";
import CardsContain from "../styled_components/shared/CardsContain";

const bannerData = [
  {
    "id": 7,
    "location": "plus",
    "title": "",
    "text": "",
    "content_width": 100,
    "content_position": "center",
    "title_color": "#ffffff",
    "text_color": "#ffffff",
    "highlight_color": "#fbcf0d",
    "button_text": null,
    "button_action": null,
    "banner_image": "/images2/banners/minkadev_plus_banner.png"
  }
]

const Plus = ({ texts, ods }) => {
  const [loading, setLoading] = useState(false);
  const [opportunities, setOpportunities] = useState([]);
  const [params, setParams] = useState({ plus: true });
  const [showPlusOpportunities, setShowPlusOpportunities] = useState(false)
  const [showMoreButton, setShowMoreButton] = useState(false)

  const timer = useRef(false);
  const page = useRef(1);

  const opportunityTypeOptions = [{tag: "NS",name: texts.marketplace_sustainable_opportunities_find_option_1}, {tag: "NC",name: texts.marketplace_sustainable_opportunities_find_option_2}, {tag: "OS",name: texts.marketplace_sustainable_opportunities_find_option_3}, {tag: "OC",name: texts.marketplace_sustainable_opportunities_find_option_4}]

  const getApiOpportunities = (fromButton) => {// call api "/api/opportunities/:page" for get other opportunities
		setLoading(true);
    if (fromButton) page.current = page.current + 1
    api.getOpportunities(params, (r) => {
      setLoading(false);
      setOpportunities(opp => [...opp, ...r])
      if(r.length < 6) setShowMoreButton(false)
    },
    page.current);
	}

  //Trigger on parameter change for filters
  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    if (opportunities.length > 0) {
      setOpportunities([])
      page.current = 1
      setShowMoreButton(true)
    }
    timer.current = setTimeout(() => {
      getApiOpportunities()
    }, [500]);
    return () => clearTimeout(timer.current);
  }, [params]);

  useEffect(() => {
    if (opportunities.length > 0 && !showPlusOpportunities) {
      setShowPlusOpportunities(true)
    }
  }, [opportunities])

  const addParam = (field, value) => {
    if (field === "type"){
      const setType = (op) => {
        let defaultOption = [null, null]
        let options = {
          "NS": [false, "cadena"],
          "NC": [false, "consumo"],
          "OS": [true, "cadena"],
          "OC": [true, "consumo"],
        }
        return options[op] ?? defaultOption
      }
      return setParams((e) => ({ ...e, ["offers"]: setType(value)[0], ["otype"]: setType(value)[1], ["type"]: value}));
    }
    setParams((e) =>({ ...e, [field]: value }));
  };

  const gotoContact = () => {
    let element = document.getElementById("footer");
    element.scrollIntoView({ block: "start", behavior: "smooth" });
  };
  
  console.log('opportunities', opportunities)

  return (
    <>
      <MainCarouselBanner {...{ bannerData }} marketplaceBanner smallText/>
      <ViewContain $paddingX={"10%"} $paddingY={"5%"} $alignItems={"center"}>
        <InfoTitle>
            {texts.marketplace_plus_title}
        </InfoTitle>
        <InfoText $textAlign={"justify"}>
          {texts.marketplace_plus_description}
        </InfoText>
        <ViewContain $paddingX={"10%"} $alignItems={"center"}>
          <YellowButton onClick={gotoContact} $textTransform={"uppercase"}>{texts.marketplace_plus_button}</YellowButton>
        </ViewContain>
      </ViewContain>
      <OportunitiesFilter $openFilter={showPlusOpportunities}>
        <OportunitiesFilterCont>
          <ViewContain $bgColor={"#EFF3F6"} $paddingY={"2%"}>
            <ViewContain $alignItems={"center"}>
              <InfoTitle $textTransform={"uppercase"}>
                {texts.marketplace_sustainable_opportunities}
              </InfoTitle>
            </ViewContain>
            <ViewContain $paddingX={"5%"}>
              <InputField t="select" data={params} field="type" initValue={texts.marketplace_sustainable_opportunities_find} options={opportunityTypeOptions} include_blank={false} saveData={(e) => addParam("type", e.target.value)} required={false} />
            </ViewContain>
            <FiltersContainer>
              <InputField t="sectors" data={params} field="sector" initValue={texts.marketplace_sustainable_opportunities_sector} include_blank={false} saveData={(e) => addParam("sector", e.target.value)} required={false} getOnly={true}//only for select values
              />
              <InputField t="countries" data={params} field="country" initValue={texts.marketplace_sustainable_opportunities_country} include_blank={false} saveData={(e) => addParam("country", e.target.value)} required={false} flag={false} />
              <InputField t="select" data={params} field="ods" options={ods} initValue={texts.marketplace_sustainable_opportunities_ods} include_blank={false} saveData={(e) => addParam("ods", e.target.value)} required={false} />
              <InputField t="debounced" data={params} field="q" saveData={(e) => addParam("q", e.target.value)} placeholder={texts.marketplace_sustainable_opportunities_keywords} value={params.q || ""} />
            </FiltersContainer>
          </ViewContain>
          <CardsContain>
          {opportunities.length > 0 && opportunities.map((e) => (
            <OpportunityCard opportunity={e} key={e.id + "op"} suitableMessage={texts.marketplace_plus_suitable_message}/>
            ))}
          </CardsContain>
        </OportunitiesFilterCont>
      </OportunitiesFilter>
      {showMoreButton && !loading &&
        <ViewContain $paddingY={"3%"} $alignItems={"center"}>
          <GreenButton textTransform={"uppercase"} text={texts.marketplace_more_button} action={()=>getApiOpportunities(true)}/>
        </ViewContain>
      }
      {loading &&
        <ViewContain $paddingY={"3%"} $alignItems={"center"}>
          <LoadingBars/>
        </ViewContain>}
      <ViewContain $paddingY={"5%"} $paddingX={"5%"} $alignItems={"center"}>
      </ViewContain>
    </>
  );
};

const OportunitiesFilter = styled.div`
  display: grid;
  grid-template-rows: ${({$openFilter}) => $openFilter ? "1fr" : "0fr"};
  transition: grid-template-rows 0.5s ease-out;
  align-items: center;
  overflow: hidden;
`

const OportunitiesFilterCont = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export default Plus;
