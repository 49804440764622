import React, {  useEffect, useState } from 'react'
import styled from "styled-components";
import { GreenButton } from './GreenButton';

const NewsCarouselBanner = ({ bannerData = [], showNew = false }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const contentWithArrows = bannerData.length >= 2

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % bannerData.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + bannerData.length) % bannerData.length);
  };

  const navigate = (url) => {
    window.location = url;
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     nextSlide()
  //   }, 7000);

  //   return () => clearInterval(interval);
  // }, [currentSlide]);

  return (
    <Banner $showNew={showNew}>
      {contentWithArrows && 
      <BannerArrowCont>
        <img src={"/images2/svg/banner_arrow.svg"} onClick={prevSlide}/>
      </BannerArrowCont>}

      <BannerCont>
        <SliderCont $currentSlide={currentSlide}>
        {bannerData.map((data, i)=>
          <SliderItem key={"slider" + i} $image={data?.banner_image}>
            <SliderInfo $titleColor={data?.title_color} $textWidth={data?.content_width}>
              <h2 dangerouslySetInnerHTML={{__html: data.title}}/>
              {data?.button_action &&
                <div>
                  <GreenButton onClick={()=>navigate(data?.button_action?.action)} $paddingX={"2rem"} $paddingY={".5rem"} $textTransform={"uppercase"}>{data?.button_action?.text}</GreenButton>
                </div>
              }
            </SliderInfo>
          </SliderItem>
        )}
        </SliderCont>
      </BannerCont>
      
      {contentWithArrows && 
      <BannerArrowCont $right={"true"}>
        <img src={"/images2/svg/banner_arrow.svg"} onClick={nextSlide}/>
      </BannerArrowCont>}

      {contentWithArrows && 
      <Indicators>
        {bannerData.map((_, i)=>
          <Indicator onClick={()=>setCurrentSlide(i)} $selected={currentSlide === i} key={"indicator"+i}/>
        )}
      </Indicators>}
    </Banner>
  )
}

const Banner = styled.div`
  height: ${({$showNew}) => $showNew ? "592px" : "500px"};
  position: relative;
  user-select: none;

  @media (max-width: 1368px) {
    height: 59.2rem;
  }
`

const BannerCont = styled.div`
  position: absolute;
  top: 0; 
  left: 50%;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  transform: translate(-50%, 0);
`

const BannerArrowCont = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  max-width: 4.5rem;
  width: 20%;
  position: absolute;
  left: ${({$right}) => $right !== "true" && "0"};
  right: ${({$right}) => $right === "true" && "0"};
  transform: scaleX(${({$right}) => $right === "true" ? "-1" : "1"});
  cursor: pointer;
  z-index: 1;

  img{
    width: 100%;
  }
  @media (max-width: 1688px) {
    left: ${({$right}) => $right !== "true" && "3%"};
    right: ${({$right}) => $right === "true" && "3%"};
  }
  @media (max-width: 1024px) {
    max-width: 4rem;
  }
  @media (max-width: 440px) {
    max-width: 3.5rem;
    left: ${({$right}) => $right !== "true" && "5%"};
    right: ${({$right}) => $right === "true" && "5%"};
  }
`

const SliderCont = styled.div`
  position: relative;
  display: flex;
  transition: transform 0.3s ease;
  transform: translateX(${({$currentSlide}) => -$currentSlide * 100}%);
  height: 100%;
`

const SliderItem = styled.div`
  &::before{
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${({$image}) => $image ? $image : "/images2/banners/bannermarketplace.png"});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }

  width: 100%;
  height: 100%;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
`

const SliderInfo = styled.div`
  max-width: 1512px;
  margin: 0 10%;
  padding: 0 8%;
  height: 100%;
  gap: 6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 

  div{
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  h2{
    width: 100%;
    margin: 0;
    font-family: var(--BarlowCondensed);
    font-size: 5rem;
    font-weight: 500;
    color: ${({$titleColor}) => $titleColor || "white"};
    text-align: center;
  }
  @media (max-width: 1688px) {
    padding: 0;
  }
  @media (max-width: 1024px) {
    h2{
      font-size: 5rem;
    }
  }
  @media (max-width: 568px) {
    align-items: center;

    h2{
      width: 100%;
      text-align: center;
    }
  }
`
const Indicators = styled.div`
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
`

const Indicator = styled.div`
  width: 1rem;
  height: 1rem;
  margin: 0 2rem;
  background-color: white;
  border-radius: 50%;
  opacity: ${({$selected}) => $selected ? "1" : "0.4" };
  cursor: pointer;
`

export default NewsCarouselBanner