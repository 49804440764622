import React from 'react'
import Button from "../shared/SimpleButton" 

function MdServices({icon, nameIcon, textService, listener}){
	return( 
		<div className="Service">
			<div className="contIcon rounded">
				<div className="ServiceIcon">
					<div className="flex flex-justify h100">
						<img src={icon} alt="" className="max100"/>
					</div>
				</div>
				<div className="IconName">{nameIcon}</div>
			</div>
			<div className="contButton">
				<div className="buttonTextService">
					<Button
						id={nameIcon}
						color="orange"
						label="Detalle"
						listener={listener}
						scale={1.05}
					/>
				</div>
			</div>
			<div className="ServiceText">{textService}</div>
		</div>
	)
}

export default MdServices;
