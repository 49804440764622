import React, { useEffect, useState } from "react";

import {languageSelector} from "../Language";
import HeaderMenu from "../layout/HeaderMenu";
import HeaderMenuMobile from "../layout/HeaderMenuMobile";
import DownIcon from "./icons/DownIcon";

const Header = ({ lang, currentUser, texts }) => {
  const [viewMobile, setViewMobile] = useState(null)
  const [lng, setLng] = useState("");
  const langSelector = Object.entries(languageSelector);

  const testEnvironment = process.env.REACT_APP_NODE_ENV === 'test'

  let homeLink 
  if (!process.env.REACT_APP_NODE_ENV || process.env.REACT_APP_NODE_ENV === 'development') homeLink = "http://localhost:3000/" // development environment
  else if (process.env.REACT_APP_NODE_ENV === 'test') homeLink = "http://minka-dev.onrender.com/" // test environment
  else homeLink = "https://www.minka-dev.com/" // production environment

  let marketPlaceLink 
    if (!process.env.REACT_APP_NODE_ENV || process.env.REACT_APP_NODE_ENV === 'development') marketPlaceLink = "http://marketplace.minka.com:3000" // development environment
    else if (process.env.REACT_APP_NODE_ENV === 'test') marketPlaceLink = "http://minka-dev.onrender.com/marketplace" // test environment
    else marketPlaceLink = "https://marketplace.minka-dev.com" // production environment

  const pathname = window.location.pathname
  const isMarketplace = pathname.includes("marketplace") || (window.location.hostname.includes("marketplace") && !pathname.includes("services") && !pathname.includes("business") && !pathname.includes("about_us") && !pathname.includes("blogentries"))
  
  const navigate = (url) => {
    window.location = url;
  };
  
  useEffect(() => {
    const selectInitials = (lang) => {
      const defaultInitial = "ESP"
      const initials = {
        "en-us": "ENG",
        "fr-fr": "FRA",
      }
      return initials[lang] ?? defaultInitial
    }
    setLng(selectInitials(lang));
  }, [lang]);

  const showSubMenu = (classN, n) => {
    let element = document.getElementsByClassName(classN)[n];
    element.classList.add("is-open")
  };
  const hideMenu = (classN, n) => {
    let element = document.getElementsByClassName(classN)[n];
    element.classList.remove("is-open")
  };

  useEffect(() => {
    const updateSize = ()=>{
      let width = window.innerWidth
      if (width > 720 && viewMobile) {
        setViewMobile(false)
      }else if (width <= 720 && !viewMobile) {
        setViewMobile(true)
      } 
    }
    if (viewMobile === null) updateSize()

    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  })

  return (
    <header className="header">
      {testEnvironment&&<div className="test_server_message">Servidor de prueba</div>}
      <a className="header_logo" href={homeLink}>
        <img src="/images/logos/logo.png" alt="logo"/>
      </a>
      {!viewMobile && <HeaderMenu texts={texts} isMarketplace={isMarketplace} marketPlaceLink={marketPlaceLink}/>}
      <div className="header_menu-2">
        {isMarketplace && !viewMobile && 
          <nav className="header_lang" >
            <div className="header_lang_cont">
              <a className="header_user" href={currentUser ? `${marketPlaceLink}/profile/` + currentUser.id : `${marketPlaceLink}/login`}>
                <img className="header_user_icon" src="/images2/icons/user_icon_desktop.png" alt="User"/>
                <span>{currentUser ? `${currentUser.first_name} ${currentUser.last_name}` : texts.header_log_in}</span>
              </a>
            </div>
          </nav>
        }
        <nav className="header_lang" onClick={() => showSubMenu("header_subMenu_lng", 0)} onMouseLeave={() => hideMenu("header_subMenu_lng", 0)} >
          <div className="header_lang_cont" onMouseOver={() => showSubMenu("header_subMenu_lng", 0)}>
            <a>{lng}</a>
            <div style={{margin: "0 0 0 .5rem"}}>
              <DownIcon width={"1.5rem"} height={".6rem"} color={"#D9D9D9"}/>
            </div>
          </div>
          <div className="header_subMenu_lng">
            <div className="header_subMenu_cont">
              {langSelector.map((lang) => (
                <div key={lang[0]}>
                  <span onClick={() => navigate('/lang/'+lang[0])}>
                    {lang[1].toUpperCase()}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </nav>
        {viewMobile && <HeaderMenuMobile texts={texts} currentUser={currentUser} isMarketplace={isMarketplace} marketPlaceLink={marketPlaceLink}/>}
      </div>
    </header>
  );
};

export default Header;