import React, { useState } from 'react'
import { translations } from "../Language"
import OrganizationController from "../Organizations/Controller"
import ShowInfoUser from '../layout/ShowInfoUser'
import SectionOporPropo from '../layout/SectionOporPropo'
import styled from 'styled-components'

export default ({ lang, user, currentUser, authenticity_token, opportunitiesCount, proposals = [], communities = [] , t}) => {
  const o = translations[lang].organizations
  const g = translations[lang].general
  const c = translations[lang].cuenta

  const [view, setView] = useState("org")
  const [organization, setOrganization] = useState(user.organization)
  
  if (!currentUser) {
    window.location = "/"
  }

  return (
    <>
      {user.id === currentUser.id ?
        <UserViewCont>
          <ShowInfoUser {...{user, authenticity_token, lang, t} }/>
            <ButtonsTab {...{ g, view, setView, opportunitiesCount, proposals, communities }} />
            {view === "org" && <Org {...{ lang, c, o, organization, setOrganization }}  />}
            {view === "op" && <OpPropTab {...{ c, o, organization, currentUser, opportunitiesCount }} opportunityTab/>}
            {view === "so" && <OpPropTab {...{ c, o, organization, currentUser, proposals }} />}
        </UserViewCont>
        :
        <UserViewCont>Cuenta privada</UserViewCont>
      }
    </>
  )
}

const Org = ({ lang, o, organization, setOrganization }) => (
  <>
    {!organization && <OrganizationMessage>{o.nocomplete}</OrganizationMessage>}
    <OrganizationController {...{ lang, organization, setOrganization }} />
  </>
)

const OpPropTab = ({ c, o, organization, currentUser, proposals, opportunityTab, opportunitiesCount }) => (
  <>
    {!organization && <OrganizationMessage>{o.nocomplete}</OrganizationMessage>}
    {organization && <SectionOporPropo {...{ c, currentUser, proposals, opportunityTab, opportunitiesCount }}/>}
  </>
)

const UserViewCont = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1688px) {
    margin: 0 10%;
  }
`

const ButtonsTab = ({ g, view, setView, opportunitiesCount, proposals }) => 
  <ButtonsCont>
    <UserTabButton onClick={() => setView("org")} $selected={view === "org"}>{g.organizacion}</UserTabButton>
    <UserTabButton onClick={() => setView("op")} $selected={view === "op"}>{g.oportunidades} ({opportunitiesCount})</UserTabButton>
    <UserTabButton onClick={() => setView("so")} $selected={view === "so"}>{g.proposals} ({proposals.length})</UserTabButton>
  </ButtonsCont>

const ButtonsCont = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`
const UserTabButton = styled.button`
  background-color: ${({$selected}) => $selected ? "var(--grey5)" : "var(--grey1)"};
  color: ${({$selected}) => $selected ? "white" : "var(--grey6)"};
  width: 100%;
  border: none;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 500;
  height: 4.8rem;
  border-radius: 6px;
  cursor: pointer;
  @media (max-width: 720px) {
    font-size: 3rem;
    height: auto;
    padding: 1rem;
  }
`
const OrganizationMessage = styled.div`
  margin: 5% 0;
  font-size: 2.5rem;
  color: var(--grey5);
`