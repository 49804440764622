import React from 'react'

export default ({text, titleCard, dataList=[]}) => {
    return (
        <div className="sectionInfowC" >
            <div className="sectionInfowC_text" >
                {text}
            </div>
            <div className="sectionInfowC_card" >
                <h4>{titleCard}</h4>
                <ul>
                    {dataList.map((data, i)=>
                        <li key={"data_list"+i} >{data[1]}</li>
                    )}
                </ul>
            </div>
        </div>
    )
}
