import React from "react";
import { LangContext, translations } from "../Language";
import StaticBanner from "../layout/StaticBanner";
import Title from "../layout/Title";
import ColorSection from "../layout/ColorSection";
import SectionCircle from "../layout/SectionCircle";
import SectionActions from "../layout/SectionActions";
import SectionContact from "../layout/SectionContact";
import SectionComent from "../layout/SectionComent";

const i = {
  banner_image: "/images2/banners/bannerinversiones.png",
  imgCircle1: "/images2/photos/inversiones2.png",
  // imgCircle2:"/images2/photos/home_servicios.jpg",
  // imgCircle3:"/images/photos/impacto1.jpg",
  // imgCircle4:"/images/photos/impacto2.jpg",
};

const Investments = ({ lang, t, i1, testimonies }) => {
  const imgCircle = Object.entries(i).filter((item) => {
    if (item[0].includes("imgCircle")) {
      return item;
    }
    return false;
  });
  const investActions = Object.entries(t).filter((item) => {
    if (item[0].includes("whyInvest_list")) {
      return item;
    }
    return false;
  });
  const invesTestimonies = Object.values(testimonies).filter((item) => {if (item["type"]==="Investments") {return item}
  return false})
  return (
    <LangContext.Provider value={lang}>
      <StaticBanner
        image={i.banner_image}
        title={t.banner_title}
        center={true}
      />
      <ColorSection style={{ backgroundColor: "#C8BEC9" }}>
        <div className="investments_intro">
          <Title
            text={t.intro_title}
            align="center"
            axiforma={true}
            margin={false}
          />
          <div className="investments_intro-text">{t.intro_text}</div>
        </div>
      </ColorSection>
      <SectionCircle
        title={t.directed_title}
        text={t.directed_text}
        img={imgCircle}
      />
      <SectionActions inv={true} data={investActions} title={t.whyInvest} />
      {invesTestimonies.length!==0 && (
        <SectionComent data={invesTestimonies} lang={lang} />
      )}

    </LangContext.Provider>
  );
};

export default Investments;
