import React from 'react'
import { LangContext } from "../Language"
import ColorSection from '../layout/ColorSection'
import Title from '../layout/Title'

export default ({lang, t, i}) => {
	return( 
		<LangContext.Provider value={lang}>
		<ColorSection style={{backgroundColor:"#C8BEC9", padding:"5% 15%", textAlign:"center"}}>
			<Title text={t.intro_title} axiforma={true} margin={false}/>
			<div className="contact_text">{t.intro_text}</div>
		</ColorSection>

		</LangContext.Provider>
	)
}

