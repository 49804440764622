import React, { useContext } from 'react'
import Flag from "../shared/RoundFlag"
import { LangContext, translations } from "../Language"

const testEnvironment = process.env.REACT_APP_NODE_ENV === 'test'

export default ({ opportunity, edit }) => {
	const l = useContext(LangContext)
  const d = translations[l].definition
	
	const handleIdoneoIconClick = (e) => {
		e.stopPropagation()
	}
	return (
		<div className="fichaOpportunity" onClick={()=>window.location = `${testEnvironment?"/marketplace":""}/opportunities/${opportunity.id}${edit?"/edit":""}`}>
			<img className="fichaOpportunity_img" src={opportunity.image} alt="image"/>
			{/* <ViewField {...opportunity} t="sector" field="sub_sector_id" label={""}/> */}
			<div className="fichaOpportunity_sector">{opportunity.sector.split(" - ")[0]}</div>
			<div className="fichaOpportunity_cont">
				<div className="fichaOpportunity_name">{opportunity.title}</div>
				<div className="fichaOpportunity_beneficiary">
					<img src="/icons2/beneficiarios@2x.png" alt="img" />
					<div>Beneficiarios: {opportunity.nb_affected}</div>
				</div>
				<div className="fichaOpportunity_infoIcons">
					<Flag id={opportunity.country}/>
					{opportunity.idoneo&&<img className="fichaOpportunity_idoneoImg" src="/icons/idoneidadIcon.png" alt="" onClick={handleIdoneoIconClick}/>}
					{opportunity.plus&&<img className="fichaOpportunity_plusImg" src="/icons2/plus@2x.png" alt="" />}
					<div className={`fichaOpportunity_idoneoInfo`}>{d.idoneo}</div>
				</div>
			</div>
			
		</div>
	)
}
