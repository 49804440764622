import React, { useState, useEffect, useRef } from "react";
//Allies

var timer;
export default ({ partners }) => {
  const [index, setIndex] = useState(0);
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = loop;
  });
  useEffect(() => {
    timer = setInterval(() => {
      savedCallback.current(1);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  const click = (qty) => {
    clearInterval(timer);
    loop(qty);
  };

  const loop = function (qty) {
    let newQty = index + qty;
    setIndex(partners[newQty] ? newQty : qty === 1 ? 0 : partners.length - 1);
    if (index === partners.length - 4) setIndex(0);
  };

  return (
    <div className="WorkTeam flex flex-justify">
      <img
        className="partners_arrows"
        onClick={() => click(-1)}
        src="/icons2/arrowLeft.png"
        alt="Left"
      />
      <div className="contWorkTeam" style={{width: "70vw", margin: "0 10%", overflow: "hidden"}}>
        {partners[index + 1] && partners[index + 2] ? (
          <div className="contObjectsTeam flex flex-justify flex-stretch">
            <div
              className="contPhotoTeam"
              style={{
                maxWidth: "200px",
                maxHeight: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "inherit",
              }}
            >
              <img
                src={partners[index].logo}
                alt="Logo"
                style={{ width: "auto", height: "auto" }}
              />
            </div>
            <div
              className="contPhotoTeam"
              style={{
                maxWidth: "200px",
                maxHeight: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "inherit",
              }}
            >
              <img
                src={partners[index + 1].logo}
                alt=""
                style={{ width: "auto", height: "auto" }}
              />
            </div>
            <div
              className="contPhotoTeam"
              style={{
                maxWidth: "200px",
                maxHeight: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "inherit",
              }}
            >
              <img
                src={partners[index + 2].logo}
                alt=""
                style={{ width: "auto", height: "auto" }}
              />
            </div>
          </div>
        ) : (
          <div className="contObjectsTeam flex flex-justify flex-stretch">
            <div
              className="contPhotoTeam"
              style={{
                width: "100px",
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={partners[index].logo}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
        )}
      </div>
      <img 
        className="partners_arrows"
        onClick={() => click(1)}
        src="/icons2/arrowRight.png"
        alt="Right"
      />
    </div>
  );
};
