import React from "react";

export default ({ listener, id, bgColor = "", color = "", disable, label, small, style = {}, purple, purple2, white, selected = false, shadow = true,}) => {
  const hoverButton = () => {
    const element = document.getElementById(id);
    if (!selected) {
      if (!disable) {
        if (purple) {
          element.style.backgroundColor = "White";
          element.style.color = "var(--darkPurple)";
          return
        }
        if (purple2) {
          element.style.backgroundColor = bgColor;
          element.style.color = color;
          return

        }
        if (white) {
          element.style.backgroundColor = "var(--creamPurple)";
          element.style.color = bgColor;
          return

        } else {
          element.style.backgroundColor = color;
          element.style.color = bgColor;
        }
      } else {
        element.style.backgroundColor = "grey";
        element.style.color = "white";
      }
    }
  };
  const leaveButton = () => {
    const element = document.getElementById(id);
    if (!selected) {
      if (!disable) {
        element.style.backgroundColor = bgColor;
        element.style.color = color;
      } else {
        element.style.backgroundColor = "grey";
        element.style.color = "white";
      }
    }
  };
  const styles = () => {
    let tmStyle;
    if (!disable) {
      if (purple2) {
        tmStyle = {
          ...style,
          color: selected ? "var(--darkPurple)" : color,
          backgroundColor: selected ? "var(--creamPurple)" : bgColor,
          boxShadow: `${!shadow && "none"}`,
        };
      } else {
        tmStyle = {
          ...style,
          color: selected ? "white" : color,
          backgroundColor: selected ? "var(--darkPurple)" : bgColor,
          boxShadow: `${!shadow && "none"}`,
        };
      }
    } else {
      tmStyle = {
        ...style,
        color: "white",
        backgroundColor: "grey",
        boxShadow: `${!shadow && "none"}`,
      };
    }
    return tmStyle;
  };
  return (
    <button
      className={`normalButton${selected ? "-selected" : ""}${
        small ? "-small" : ""
      }`}
      id={id}
      onClick={!disable? listener : ()=>{}}
      style={styles()}
      onMouseOver={bgColor ? () => hoverButton() : () => {}}
      onMouseLeave={bgColor ? () => leaveButton() : () => {}}
    >
      {label}
    </button>
  );
};
