import React from 'react'
import FlipableCard from "../shared/FlipableCard"
import Flag from "../shared/RoundFlag"

function FichaProyecto({project, lang}){
	return( 
		<div className="smMargin">
			<FlipableCard 
				width={280} height={375}
				front={
					<div className="FichaProyectoCont bordered " style={{backgroundColor:"var(--purple)"}}>
						<div className="contSuper">
							<img className="FichaImage" src={project.image} alt=""/>
							<div className="contInfoSuper rounded flex flex-justify flex-column">
								<div className="FichaTitle">{project.title}</div>
								<div className="FichaSubtitle">{project.opportunity_type.name[lang]}</div>
								<div className="FichaSubtitle">{project.sector.name}</div>
							</div>
							<div className="countryFlag">
								<Flag id={project.country} className="RoundFlag float-right"/>								
							</div>
						</div>
						<img alt="" className="clientLogo" src={project.logo}/>
						<div className="FichaOds">
							{project.ods.map((link, i) => <img alt="ODS" key={i+"ods"} className="FichaOdsIcon" src={link} />)}
						</div>
					</div>
				}
				back={
					<div className="FichaProyectoCont bordered paddingFicha">
						<div className="FichaAltura flex flex-end flex-column">
							<div className="FichaDescripcion">{project.description}</div>
							<div className="flex flex-wrap flex-justify">
								{project.keywords.map((e, i) => <div className="keywords rounded" key={i+"keywords"}>{e}</div>)}
							</div>
						</div>
					</div>
				}
			/>
		</div>
	)
}

export default FichaProyecto;
