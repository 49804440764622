import React from 'react'
import useController from "../hooks/useController"
import View from "./View"
import Form from "./Form"
import { LangContext } from '../Language'

export default function Controller({ lang, organization, setOrganization }) {
  const props = useController(lang, organization, "organizations", false, setOrganization)
  props.lang = lang
  return <LangContext.Provider value={lang}>
    {props.loading || (props.view === "view" ? <View {...props} /> : <Form {...props} />)}
  </LangContext.Provider>
}
