import React, { useEffect, useRef, useState } from "react";
import Title from "./Title";
import Loading from "../shared/Loading";
import api from "../api";

export default ({ title, type, services }) => {
  const [projects, setProjects] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const contentRef = useRef({});
  const leftRef = useRef({});
  const rightRef = useRef({});
  const itemRef = useRef({});

  const typesIndex = {
    diagnostic: "Diagnóstico",
		strategy: "Estrategia",
		evaluation: "Evaluación",
    identify: "Implementación - Identificar",
    connect: "Implementación - Conectar",
    structure: "Implementación - Estructurar",
    accompany: "Implementación - Acompañar",
		green: "Negocios Verdes",
		inclusive: "Negocios Inclusivos",
		circular: "Negocios Circulares",
	}

  useEffect(() => {
    setLoading(true);
    api.getProjects(
      (r) => {
        setLoading(false);
        setProjects((ser) => {
          if (r?.results) {
            return [...ser, ...r?.results];
          }else return []
        });
      },
      page,
      100
    );
  }, []);

  useEffect(() => {
    const content = contentRef.current;
    const left = leftRef.current;
    const right = rightRef.current;
    const item = itemRef.current;
    let limit = content.scrollWidth;
    let scroll = content.scrollLeft;
    let offset = content.offsetWidth;

    if (scroll === 0) {
      leftRef.current.style.visibility = "hidden";
    }
    if (offset >= limit) {
      right.style.visibility = "hidden";
    }

    const handleScroll = () => {
      scroll = content.scrollLeft;
      let limitCont =
        window.innerWidth <= 768 ? scroll + item.offsetWidth : scroll + offset;
      if (limitCont >= limit) {
        rightRef.current.style.visibility = "hidden";
      } else {
        leftRef.current.style.visibility = "visible";
        rightRef.current.style.visibility = "visible";
      }
      if (scroll === 0) {
        leftRef.current.style.visibility = "hidden";
      }
    };
    const handleClick = (dir) => {
      if (dir === -1) {
        content.scrollLeft += -item.offsetWidth - 50;
      } else {
        content.scrollLeft += item.offsetWidth + 50;
      }
    };
    if (content && left && right) {
      content.addEventListener("scroll", handleScroll);
      left.addEventListener("click", () => {
        handleClick(-1);
      });
      right.addEventListener("click", () => {
        handleClick(1);
      });
    }
    return () => {
      content.removeEventListener("scroll", handleScroll);
      left.removeEventListener("click", () => {
        handleClick(-1);
      });
      right.removeEventListener("click", () => {
        handleClick(1);
      });
    };
  }, [projects]);

  const projectsFilter = () => {
    if (projects.length > 0 ) {
      if (services) {
        return Object.values(projects).filter((item) => {if (item["service_types"].includes(typesIndex[type])) {return item} return false})
      } else {
        return Object.values(projects).filter((item) => {if (item["business_types"].includes(typesIndex[type])) {return item}return false})
      }
    }else return []
  }

  const viewProjects = projectsFilter.length===0 ? projects : projectsFilter()


  return (
    <>
      {loading ? (
        <Loading id="loadService" />
      ) : (
        <div className="sProjectswC">
          <Title text={title} color="#351A3C" />
          <div className="sProjectswC_cont">
            <img
              className="sProjectswC_arrow-left"
              src="/icons2/arrowpurpleleft.png"
              alt="Left"
              ref={leftRef}
            />
            <div className="sProjectswC_view" ref={contentRef}>
              
            </div>
            <img
              className="sProjectswC_arrow-right"
              src="/icons2/arrowpurpleright.png"
              alt="Right"
              ref={rightRef}
            />
          </div>
        </div>
      )}
    </>
  );
};
