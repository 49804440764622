import React from 'react'
import styled from 'styled-components'
import { ViewContain } from '../styled_components/layout/ViewContain'
import NewsCarouselBanner from '../styled_components/shared/NewsCarouselBanner'

const Show = ({ blog, texts }) => {

	const bannerData = [{title: blog.title, banner_image: blog.image, title_color: blog.title_color}]

	var dateObject = new Date(blog.publication_date);
	var day = dateObject.getUTCDate();
	var month = dateObject.getUTCMonth() + 1;
	var year = dateObject.getUTCFullYear();
	var formattedDate = day + '-' + (month < 10 ? '0' + month : month) + '-' + year;

	return (
		<>
			<NewsCarouselBanner {...{ bannerData }} showNew/>
			<ViewContain $paddingX={"5%"} $paddingY={"5%"} $alignItems={"center"}>
				<PublicationDate>
					{formattedDate}
				</PublicationDate>
				<ViewContain $paddingY={"5%"} $gap={"4rem"}>
					<NewContainer>
						<div dangerouslySetInnerHTML={{__html: blog.text}}/>
					</NewContainer>
					{blog.author && <AutorCont>{texts.news_author}: {blog.author}</AutorCont>}
				</ViewContain>
				<ViewContain $alignItems={"center"}>
					<h3>{texts.news_social_buttons}</h3>
					<SocialIcons>
						<a href="https://www.linkedin.com/company/minkadev/" target="_blank" rel="noopener noreferrer"><img src="/images2/svg/linkedin_icon.svg" alt="linkedin" /></a>
						<a href="https://twitter.com/intent/tweet" target="_blank" rel="noopener noreferrer"><img src="/images2/icons/x_icon.webp" alt="x" /></a>
						<img src="/images2/svg/instagram_icon.svg" alt="instagram" />
						<div className="fb-share-button" data-href={`https://www.minka-dev.com/blogentries/${blog.id}`} data-layout="button" data-size="small"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.minka-dev.com%2Fblogentries%2F&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore"><img src="/images2/svg/fecebook_icon.svg" alt="facebook" /></a></div>
					</SocialIcons>
				</ViewContain>
				
			</ViewContain>
		</>
	)
}

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;

	img{
  	height: 6rem;
	}
`
const NewContainer = styled.div`
	color: var(--purple2);
	text-align: justify;
	font-size: 3rem;
	font-weight: 400;

	strong{
		font-family: var(--BarlowCondensed);
		font-weight: 600;
		font-size: 5rem;
		text-align: left;
	}
	img{ 
		width: 100%
	}
`
const AutorCont = styled.div`
	color: var(--purple2);
	text-align: justify;
	font-size: 3rem;
	font-weight: 400;
	`
const PublicationDate = styled.div`
	font-family: var(--BarlowCondensed);
	font-weight: 400;
	font-size: 5rem;
`

export default Show