import React, { useState, useEffect, useContext } from "react";
import { LangContext } from "../../Language";
import api from "../../api";
import RoundFlag from "../../shared/RoundFlag";
import styled from "styled-components";
import DownIcon from "../../shared/icons/DownIcon";

const InputField = ({ data, field = "", t, options = false, saveData, include_blank, getOnly, flag = true, initValue, placeholder}) => {
  
  const text = { data, field, saveData, placeholder };
  const drop = { ...text, options, include_blank, getOnly, initValue };
  const generic = ["text", "number", "email", "password"];

  return (
    <FormGroup>
      {generic.includes(t) && <GenericField ftype={t} {...text} />}
      {t === "select" && <Dropdown {...drop} />}
      {t === "sectors" && <SectorDrop {...drop} />}
      {t === "countries" && <CountryDrop {...drop} flag={flag} />}
      {t === "debounced" && <DebouncedField {...text} />}
    </FormGroup>
  );
};

export default InputField;

const GenericField = ({ data, field, saveData, ftype, placeholder }) =>{
  let textToUpperCase = typeof placeholder === "string" ? placeholder.toUpperCase() : placeholder
  return(
    <InputContainer min="0" type={ftype} name={field} value={data[field] || ""} onChange={saveData} placeholder={textToUpperCase} />
  )
};

const DebouncedField = ({ data, field, saveData, placeholder, delay = 500 }) =>{
  let textToUpperCase = typeof placeholder === "string" ? placeholder.toUpperCase() : placeholder
  const [value, setValue] = useState(data[field] || "");

  useEffect(() => {
    const handler = setTimeout(() => {
      saveData({target: {value: value}});
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return(
    <InputContainer type="text" name={field} value={value} onChange={handleChange} placeholder={textToUpperCase} />
  )
};

//Send options for direct [{tag: "tag", name: "name"}] array
const Dropdown = ({ data, field, selector, selectors, options, saveData, include_blank = true, initValue }) => {
  const model = selector || field;
  const toMap = options || selectors[model];
  let initValueToUpperCase = typeof initValue === "string" ? initValue.toUpperCase() : initValue
  if (!toMap) return <div />;
  return (
    <SelectContainer>
      <DownIcon color={"#EFF3F6"}/>
      <Select name={field} value={data[field] || ""} onChange={saveData}>
        {include_blank && <option value=""></option>}
        {initValueToUpperCase && <option value="">{initValueToUpperCase}</option>}
        {toMap.map((e) => (
          <option key={field + "_" + e.tag} value={e.tag}>
            {e.name}
          </option>
        ))}
      </Select>
    </SelectContainer>
  );
};

const SectorDrop = ({ data, field, saveData, include_blank = true, getOnly = false, small = false, initValue }) => {
  const [options, setOptions] = useState(window.ops || []);
  useEffect(() => {
    let signal;
    if (!window.ops) {
      signal = api.getSectors((r) => {
        let ops = [];
        for (const sector of r)
          for (const sub of sector.sub_sectors)
            ops.push({ tag: sub.id, name: `${sector.name} - ${sub.name}` });
        window.ops = ops;
        setOptions(ops);
      }, getOnly);
    }
    return () => signal && signal.abort();
  }, []);

  return (
    <Dropdown
      {...{ data, field, options, include_blank, small, saveData, initValue }}
    />
  );
};

//Use if no selectors... to Refactor to get selectors if non send
const CountryDrop = ({ data, field, selectors, saveData, include_blank = true, small = false, flag = true, initValue }) => {
  const lang = useContext(LangContext);
  const [options, setOptions] = useState(selectors ? selectors.country : []);

  useEffect(() => {
    var signal;
    if (!selectors)
      signal = api.getSelectors(lang, (r) => {
        setOptions(r.country);
      });
    return () => {
      if (signal) signal.abort();
    };
  }, []);
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Dropdown {...{ data, field, options, include_blank, small, saveData, initValue }} />
      {flag && (
        <div style={{ margin: "0 2% 0" }}>
          <RoundFlag id={data[field]} />
        </div>
      )}
    </div>
  );
};

//Styled Components
const FormGroup = styled.div`
  margin: 2rem 0;
  font-size: 2.4rem;
`
const InputContainer = styled.input`
  width: 100%;
  font-family: var(--Roboto);
  font-weight: 300;
  font-size: 2rem;
  padding: 1.5rem 1rem 1rem;
  border: none;
  color: var(--grey2);
  text-align: center;
`
//select field
const SelectContainer = styled.div`
  width: 100%;
  height: 4.8rem;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  border: none;
  position: relative;
  background-color: white;
  
  svg{
    position: absolute;
    top: 40%;
    right: 1rem;
    z-index: 0;
  }
  `
const Select = styled.select`
  text-transform: none;
  appearance: none;
  background-color: #ffffff00;
  width: 100%;
  font-family: var(--Roboto);
  font-weight: 300;
  font-size: 2rem;
  padding: 1.5rem 1rem 1rem;
  border: none;
  color: var(--grey2);
  position: absolute;
  cursor: pointer;
  text-align: center;
`