import styled from "styled-components";

const images = ["/images2/images/half_circle1.png", "/images2/images/half_circle2.png", "/images2/images/half_circle3.png", "/images2/images/half_circle4.png", "/images2/images/half_circle5.png"]

const theme = ["#361A3C", "#ED245D", "#7E143A", "#ED6B33", "#361A3C"]

export const MarketplaceIndicator = styled.div`
  &::before{
    content: "";
    position: absolute;
    top: 50%; 
    left: ${({$index}) => $index%2 === 0 ? "4%": "96%"};
    transform: translate(-50%, -50%) scaleX(${({$index}) => $index%2 === 0 ? "-1": "1"});
    width: 17.5rem;
    height: 35.7rem;
    background-image: url(${({$index}) => images[$index%5]});
    background-repeat: no-repeat;
    background-size: cover;
  }

  position: relative;
  display: flex;
  flex-direction: ${({$index}) => $index%2 === 0 ? "row": "row-reverse"};
  align-items: center;
  gap: 5rem;
  font-family: var(--BarlowCondensed);

  div{
    font-size: 20rem;
    font-weight: 600;
    margin: ${({$index}) => $index%2 === 0 ? "0 0 0 12%": "0 12% 0 0"} ;
    color: ${({$index}) => theme[$index%5]};
  }

  span{
    font-size: 6rem;
    text-transform: uppercase;
    font-weight: 600;
    width: min-content;
    white-space: pre-line;
    overflow-wrap: break-word;
    text-align: ${({$index}) => $index%2 === 0 ? "start": "end"};
    color: ${({$index}) => theme[$index%5]};
  }

  @media (max-width: 900px) {
    &::before{
      width: 14rem;
      height: 29rem;
    }
    div {
      font-size: 14rem;
    }
    span{
      font-size: 5rem;
    }
  }
  @media (max-width: 900px) {
    margin: 3rem 0;
    &::before{
      width: 9rem;
      height: 19rem;
    }
    div {
      font-size: 10rem;
    }
  }
`