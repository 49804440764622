import React, { useState, useContext, useEffect } from "react";
import { translations } from "../Language";
import MapImage from "./MapImage";
import InfoMap from "./InfoMap";
import api from "../api";
import { pins } from "../utils/data";

function MinkaMap({ countries, texts, lang }) {
  const [selectedCountry, setSelectedCountry] = useState("CO");
  const [ops, setOps] = useState(0)
  const [projects, setProjects] = useState(0)

  useEffect(()=>{
    api.getFilteredMap(selectedCountry, (r) =>{
      if(r.status){
        setOps(r.ops)
        setProjects(r.pro)
      }
    })
  }, [selectedCountry])

	// const countries2 = [ "BR", "PY", "BO", "CL", "AR", "UY"]

  return (
    <div className="minkaMap_cont">
      <InfoMap
        countries={countries}
        selectCountry={(c) => setSelectedCountry(c)}
        iconCountry={selectedCountry}
        numOpportunity={ops}
        numProjects={"+" + projects}
        opportunityText={texts.homepage_map_opportunities}
        projectsText={texts.homepage_map_projects}
        lang={lang}
      />
      <MapImage selectedCountry={selectedCountry} pins={pins} countries={countries} />
    </div>
  );
}

export default MinkaMap;
