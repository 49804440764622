// import { hot } from 'react-hot-loader/root';
import React from "react";
import Controller from "./Controller";
import useBackButtonConfirmation from "../hooks/useBackButtonConfirmation";

const hot = (f) => f;
export default hot(({ proposal, lang, currentUser, opportunityUser }) =>{
  const { removeBackEvent } = useBackButtonConfirmation()
  return (
    <Controller {...{ proposal, lang, currentUser, opportunityUser, removeBackEvent }} />
  )
});
