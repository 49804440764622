import React from 'react'
export default ({icon, number, text, subText}) => ( 
	<div className="Stat">
		<div className="contIconStat rounded">
			<div className="icon">
				<img src={icon} alt="icon"/>
			</div>
			<div className="number">{number}</div>
		</div>
		<div className="statText">{text}</div>
		<div className="subText">{subText}</div>
	</div>
)

