import React, { useContext} from 'react'
import {LangContext, translations} from "../Language"
import useFormUpdate from "../hooks/useFormUpdate"
import Layout from "./Layout"
import FormField from "../Form/FormField"
import styled from "styled-components";
import { GreyButton } from '../styled_components/shared/GreyButton'
import { ViewContain } from '../styled_components/layout/ViewContain'

const debug = false
const model = "organization"
export default ({object, addImage, dispatch, saveObject, selectors, errors, selectedForm}) => {
  const l = useContext(LangContext)
  const f = translations[l].forms.organization
	const c = translations[l].forms.controls
	const t = translations[l].organizations
  const [data, saveData] = useFormUpdate(model, object)

  const lockGuidedForm = data?.department !== null

  const saveForm = () => {
    saveObject(data, lockGuidedForm?()=>{}:null);
    const timeout = setTimeout(() => {
      if (lockGuidedForm) dispatch({type:"change", selectedForm: -1 })
      clearTimeout(timeout)
    }, 1000);
  }
  
  const changeForm = (i) => { dispatch({type:"change", selectedForm: i})}

  const changeView = () => { 
    dispatch({type:"view", object}) 
    const content = document.getElementById("InfoUser")
    content.scrollIntoView({ behavior: "smooth"})
  }

  const common = {data, f, c, selectors, saveData, errors}
  const props = {
    data: [
      {title: t.title, component: <Data {...common} addImage={addImage} changeView={changeView}/>},
      {title: t.adicional, component: <Adicional {...common}/>},
      {title: t.sostenibilidad, component:  <Sostenibilidad {...common} />},
			{title: t.role, component: <Role {...common}/>}
    ],
    c,
    saveForm,
    changeForm,
    selectedForm,
  }

  return (
    <OrganizationForm>
      <ViewContain style={{ marginTop: "2%", marginBottom: "2%", paddingTop: "2%", paddingBottom: "2%", position: "sticky", top: 0, backgroundColor: "#fff", zIndex: 10}}>
        <GreyButton $width={"27.7rem"} $height={"3.9rem"} onClick={changeView} $textTransform={"uppercase"} type='button'>{c.return}</GreyButton>
      </ViewContain>
      <ViewContain style={{paddingBottom: "4%"}}>
        <Layout {...props} form/>
      </ViewContain>
      {debug && <pre>{JSON.stringify(data, undefined, 4)}</pre>}
    </OrganizationForm>
  )
}

const Data = (props) => {
  const l = useContext(LangContext)	
  const f = props.f
  return (
    <>
      <FormField {...props} t="text" field="name" label={f.name} fieldRequired/>
      <FormField {...props} t="image" field="image" label={f.logo} fieldRequired/>
      <FormField {...props} t="countries" field="country" label={translations[l].general.pais} fieldRequired/>
      <FormField {...props} t="text" field="city" fieldRequired/>
      <FormField {...props} t="text" field="address" fieldRequired/>
      <FormField {...props} t="text" field="phone" fieldRequired/>
      <FormField {...props} t="text" field="website"/>
      <FormField {...props} t="sectors" field="sector" fieldRequired/>
      <FormField {...props} t="text" field="nif" fieldRequired/>
      <FormField {...props} t="select" field="otype" selector="type" label={props.f.type} fieldRequired/>
      <FormField {...props} t="select" field="age" fieldRequired/>
      <FormField {...props} t="select" field="billing" fieldRequired/>
      <FormField {...props} t="select" field="employees" fieldRequired/>
    </>
  )
}

const Adicional = (props) =>(
  <>
    <FormField {...props} t="checkboxes" field="pr" fieldRequired/>
    <FormField {...props} t="checkboxes" field="act" include={true} fieldRequired/>
  </>
)

const Sostenibilidad = (props) =>(
  <>
    <FormField {...props} t="select" field="sustainability" fieldRequired/>
    <FormField {...props} t="checkboxes" field="sus" fieldRequired/>
  </>
)

const Role = (props) =>{
  return (
    <>
      <FormField {...props} t="select" field="role" fieldRequired/>
      <FormField {...props} t="select" field="department" fieldRequired/>
      <FormField {...props} t="boolean" field="representation"/>
    </>
  )
}

const OrganizationForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const ButtonsCont = styled.div`
  width: 100%;
  padding: 0 0 5% 0;
  display: flex;
  justify-content: flex-end;
`