export const header = {
  header_services: "SERVICIOS",
  header_consulting: "CONSULTORIA",
  header_diagnosis: "DIAGNÓSTICO",
  header_strategy: "ESTRATEGIA",
  header_implementation: "IMPLEMENTACIÓN",
  header_evaluation: "EVALUACIÓN",
  header_business: "NEGOCIOS",
  header_green: "NEGOCIOS VERDES",
  header_inclusive: "NEGOCIOS INCLUSIVOS",
  header_cicular: "NEGOCIOS CIRCULARES",
  header_marketplace: "MARKETPLACE",
  header_about_us: "NOSOTROS",
  header_news: "NOTICIAS",
}

export const footer = {
  footer_about: "SOBRE minkadev",
  footer_consulting: "Servicio - Consultoría",
  footer_service_bridge2zero: "Servicio - Bridge2Zero",
  footer_business: "Negocios",
  footer_marketplace: "Marketplace",
  footer_about_us: "Nosotros",
  footer_news: "Noticias",
  footer_where_are_we: "¿Donde estamos?",
  footer_colombia: "Colombia",
  footer_mexico: "México",
  footer_spain: "España",
  footer_contact_us: "Contáctanos",
  footer_contact_connect: "Conéctate y mantengamos el contacto por un futuro sostenible.",
  footer_contact_name: "Nombre",
  footer_contact_position: "Cargo",
  footer_contact_organization: "Organización",
  footer_contact_email: "E-mail",
  footer_contact_message: "Mensaje",
  footer_contact_terms1: "He leído y acepto los ",
  footer_contact_terms2: "Términos y condiciones",
  footer_contact_send: "Enviar",
  footer_rights: "© 2024 Minka-dev. Todos los derechos reservados",
  footer_privacy: "Política de privacidad",
}

export const hompageTexts = {
  homepage_info_1: "Acompañamos a las organizaciones en el camino de la sostenibilidad. Desarrollamos nuevas oportunidades y ventajas comparativas a través de la sostenibilidad: Potenciando la innovación y el rendimiento empresarial resiliente.",
  homepage_green_button: "¡Leer más!",
  homepage_info_title_1: "SERVICIOS QUE IMPULSAN EL CAMBIO HACIA UN DESARROLLO SOSTENIBLE",
  homepage_services_info_1_title: "DIAGNÓSTICO",
  homepage_services_info_1_description: "Analizamos e identificamos ineficiencias para impulsar estrategias que promuevan un desarrollo sostenible.",
  homepage_services_info_2_title: "ESTRATEGIA",
  homepage_services_info_2_description: "Diseñamos estrategias para conducir las organizaciones y territorios a nuevos mercados, modelos de innovación y ventajas competitivas a través del desarrollo sostenible.",
  homepage_services_info_3_title: "IMPLEMENTACIÓN",
  homepage_services_info_3_description: "De la idea a la acción: ejecución estratégica en todas las etapas.",
  homepage_services_info_4_title: "EVALUACIÓN",
  homepage_services_info_4_description: "Realizamos la medición y evaluación del impacto social, ambiental y económico.",
  homepage_services_button: "¡Leer más!",
  homepage_info_title_2: "TRANSFORMAMOS RETOS SOCIALES Y MEDIOAMBIENTALES EN : <span>NEGOCIOS SOSTENIBLES</span>",
  homepage_business_button: "¡Leer más!",
  homepage_business_info_1_title: "NEGOCIOS <span>VERDES</span>",
  homepage_business_info_1_description: "Negocios que integran prácticas y estrategias que minimizan el impacto ambiental, fomentan el uso eficiente de los recursos naturales, y contribuyen a la preservación y conservación del entorno.",
  homepage_business_info_2_title: "NEGOCIOS <span>INCLUSIVOS</span>",
  homepage_business_info_2_description: "Negocios que promueven la igualdad de oportunidades, la diversidad y la participación activa de grupos tradicionalmente marginados o excluidos. Se enfocan en la creación de empleo y el empoderamiento de comunidades en condiciones de vulnerabilidad, generando impacto social positivo y contribuyendo al desarrollo sostenible.",
  homepage_business_info_3_title: "NEGOCIOS <span>CIRCULARES</span>",
  homepage_business_info_3_description: "Negocios que operan bajo el principio de la economía circular, donde se busca maximizar el valor de los recursos, minimizar los residuos y reducir el impacto ambiental. Se enfocan en la reutilización, reciclaje y regeneración de productos y materiales, promoviendo la sostenibilidad y la eficiencia en los procesos empresariales.",
  homepage_map_title: "CONOCE NUESTROS INDICADORES </br><span>IMPACTO GLOBAL, ACCIÓN LOCAL</span>",
  homepage_map_opportunities: "Oportunidades",
  homepage_map_projects: "Proyectos",
  homepage_last_news: "ÚLTIMAS NOTICIAS",
};
export const servicesTexts = {
  services_info_title_1: "SERVICIOS QUE IMPULSAN EL CAMBIO HACIA UN DESARROLLO SOSTENIBLE",
  services_info_card_target: "Dirigido a",
  services_info_card_actions: "Acciones",
  services_info_card_see_actions: "Ver acciones",
  services_info_1_title: "DIAGNÓSTICO",
  services_info_1_description: "Analizamos e identificamos ineficiencias para impulsar estrategias que promuevan un desarrollo sostenible.",
  services_info_1_target: "Empresas u organizaciones que necesitan optimizar su impacto social y medioambiental para alcanzar la sostenibilidad.",
  services_info_1_action_1: "Análisis de impactos sociales y ambientales en el ciclo de vida de los desechos y el modelo de producción (evaluación de impacto ambiental, huella de carbono, gestión de residuos, entre otros).",
  services_info_1_action_2: "Análisis de riesgos para la sostenibilidad empresarial: evaluación de riesgos ambientales, sociales y económicos que afectan la resiliencia y el desempeño sostenible.",
  services_info_1_action_3: "Estudiamos la dimensión económica del impacto en tu negocio: evaluación de indicadores económicos para un desempeño sostenible y rentable a largo plazo.",
  services_info_2_title: "ESTRATEGIA",
  services_info_2_description: "Diseñamos estrategias para conducir las organizaciones y territorios a nuevos mercados, modelos de innovación y ventajas competitivas a través del desarrollo sostenible.",
  services_info_2_target: "Empresas interesadas en definir un modelo sostenible para su organización: operación, producción y desarrollo socioeconómico. Convirtiendo su organización en un líder sostenible.",
  services_info_2_action_1: "Evaluación de materialidad.",
  services_info_2_action_2: "Desarrollo de herramientas de gestión para la evaluación y seguimiento de metas de sostenibilidad.",
  services_info_2_action_3: "Desarrollo de un plan de acción detallado para la implementación efectiva de la estrategia empresarial sostenible.",
  services_info_2_action_4: "Análisis sistémico para estrategias adaptables en entornos empresariales dinámicos y no lineales.",
  services_info_3_title: "IMPLEMENTACIÓN",
  services_info_3_description: "De la idea a la acción: ejecución estratégica en todas las etapas.",
  services_info_3_target: "Empresas que necesitan poner en acción modelos específicos de sostenibilidad para impulsar el crecimiento de su organización.",
  services_implementation_1_title: "Identificar",
  services_implementation_1_description: "Identificamos oportunidades y modelos de negocio sostenibles que impulsan un impacto positivo en los retos actuales y futuros del planeta, a partir de datos estadísticos y nuevas tendencias tecnológicas y de mercado. </br>Más de 50 nuevas rutas de mercado que transforman retos sociales, medioambientales en nuevas oportunidades de negocios sustentables.",
  services_implementation_1_action_1: "Identificación de nuevas rutas de mercado sostenibles que promueven la conservación ambiental y la inclusión social.",
  services_implementation_1_action_2: "Estrategia de acceso a mercados ( estrategia de expansión comercial y estrategia de penetración de mercados) para impulsar el crecimiento empresarial sostenible.",
  services_implementation_1_action_3: "Dimensionamiento del tamaño y potencial del mercado de acuerdo al sector y la industria.",
  services_implementation_1_action_4: "Evaluación de la capacidad local para el mercado y variables de impacto en la estrategia de mercado.",
  services_implementation_2_title: "Conectar",
  services_implementation_2_description: "Articulación estratégica de socios empresariales y alianzas para generar mayor valor social y medioambiental por medio de negocios sustentables. </br>Más de 50 conexiones en negocios sostenibles.",
  services_implementation_2_action_1: "Desarrollo de prototipos de productos innovadores para la captación de nuevos clientes.",
  services_implementation_2_action_2: "Generación de conexiones estratégicas para impulsar negocios sostenibles y alianzas empresariales para promover su crecimiento.",
  services_implementation_2_action_3: "Gestión estratégica de acuerdos de compra para negocios sustentables y y optimización de procesos de adquisición de manera eficiente y rentable de acuerdo a la industria específica.",
  services_implementation_2_action_4: "Cerramos procesos de negociación de manera equitativa y sostenible, cerrando asimetrías e integrando el capital humano y natural en los acuerdos comerciales.",
  services_implementation_3_title: "Estructurar",
  services_implementation_3_description: "Estructuramos el modelo de negocio integrando variables de impacto social y medioambiental para un enfoque sostenible y rentable. </br>Más de 30 modelos de negocios sustentables diseñados.",
  services_implementation_3_action_1: "Estructuración de modelos económicos sostenibles que valoran el capital natural y promueven la inclusión.",
  services_implementation_3_action_2: "Desarrollamos hojas de ruta y guías estratégicas para proyectos sostenibles y accionables.",
  services_implementation_3_action_3: "Diseñamos los negocios sostenibles de manera participativa para una mayor apropiación, colaboración y empoderamiento.",
  services_implementation_3_action_4: "Transferimos herramientas de gestión y de monitoreo para impulsar la sostenibilidad y el rendimiento empresarial.",
  services_implementation_4_title: "Acompañar",
  services_implementation_4_description: "Brindamos acompañamiento experto para lograr avances efectivos y eficientes en la implementación del modelo de negocio de acuerdo a la industria, impulsando la sostenibilidad y el éxito empresarial. </br>Más de 10 negocios que acompañamos en su implementación.",
  services_implementation_4_action_1: "Proporcionamos acompañamiento especializado en las actividades del piloto para garantizar el éxito, la continuidad y la escalabilidad del proyecto.",
  services_implementation_4_action_2: "Fortalecemos las capacidades de la organización para lograr la continuidad y la exitosa autogestión del modelo de negocio sostenible.",
  services_implementation_4_action_3: "A lo largo del proceso, construimos una visión estratégica del futuro para que la organización se apropie con éxito de los modelos implementados.",
  services_implementation_4_action_4: "Colaboramos estratégicamente con nuestros clientes como un aliado, en el desarrollo de negocios verdes para impulsar el crecimiento sostenible.",
  services_info_4_title: "EVALUACIÓN",
  services_info_4_description: "Evaluamos y medimos el rendimiento en términos de impacto social, ambiental y económico, a través de métodos de evaluación avanzados y meticulosos, para impulsar el desarrollo de negocios sostenibles.",
  services_info_4_target: "Organizaciones interesadas en evaluar el desempeño y la relación entre los impactos sociales, medioambientales y económicos de los proyectos.",
  services_info_4_action_1: "Evaluación del impacto en el rendimiento financiero de empresas sustentables.",
  services_info_4_action_2: "Proceso de evaluación con grupos de control para obtener evidencia científica del impacto en el desarrollo de negocios sostenibles.",
  services_info_4_action_3: "Herramientas de monitoreo y evaluación.",
  services_info_4_action_4: "Planes de mejora para abordar retos identificados en la evaluación de empresas comprometidas con la sostenibilidad.",
};
export const aboutUsTexts = {
  about_us_title_1: "NUESTRO MANIFIESTO: Desarrollo de Modelos </br> Empresariales Justos y Sostenibles",
  about_us_info_1: "En minkadev, fomentamos la creación de modelos empresariales más justos y sostenibles mediante la colaboración de fuerzas transformadoras. Reconocemos la complejidad de los desafíos sociales y medioambientales, y entendemos la importancia de abordar la vulnerabilidad en todas sus dimensiones.</br></br>En estos contextos complejos, asumimos el desafío de trabajar en pro de la inclusión y la sostenibilidad, desarrollando negocios que impulsan la inclusión económica y contribuyen a la protección del medioambiente. Nuestra labor se basa en la promoción de alianzas intersectoriales y la conexión de datos de mercado e innovación con los valores y atributos sociales y medioambientales que abordamos.</br></br>A través de nuestras alianzas, destacamos el potencial de las organizaciones locales como agentes de su propio desarrollo. Nuestro compromiso con la inclusión y la sostenibilidad se refleja en la autenticidad, honestidad y profesionalismo con la que el equipo de minkadev asume su labor, siempre en profunda conexión con nuestro propósito.",
  about_us_info_2: "Un <strong>equipo interdisciplinario</strong> comprometido con la creación de <strong>negocios sustentables</strong>, desarrolla <strong>modelos innovadores</strong> y metodologías centradas en la <strong>integración de soluciones sostenibles</strong>, aprovechando la <strong>diversidad de profesionales</strong> para abordar desafíos desde diversos ángulos y convertir necesidades sociales y ambientales en <strong>oportunidades de negocio</strong>",
  about_us_title_2: "PERSONAS QUE HACEN LA DIFERENCIA",
  about_us_work_team_card_read: "Leer",
  about_us_title_3: "¿QUÉ DICEN NUESTROS CLIENTES?",
}
export const businessTexts = {
  business_title_1: "LO HACEMOS REALIDAD: Desarrollo de Negocios Sustentables",
  business_info_1: "En minkadev, nos especializamos en convertir problemas e ineficiencias en negocios viables, innovadores y sostenibles. Nuestra experiencia se basa en la transformación de problemáticas como el desperdicio de alimentos o la amenaza de especies naturales, mediante alianzas público-privadas catalizadas por nuestra consultora. Los negocios que impulsamos permiten a las empresas involucradas innovar y expandir sus mercados.",
  business_title_2: "Explora nuestros tres tipos de negocios sostenibles:",
  business_info_card_key_data:"Datos Claves",
  business_type_1_title: "NEGOCIOS <span>VERDES</span>",
  business_type_1_description: "Contribuimos al desarrollo de negocios que generan beneficios ambientales directos, aportando valor y contribuyendo a la conservación del medioambiente.",
  business_type_1_data_1: "Hemos abordado el desarrollo de modelos empresariales vinculados a la conservación en más de una decena de espacios naturales y reservas de Biosfera",
  business_type_1_data_2: "Estructuramos modelos de negocios verdes que permiten reflejar el valor del bosque en el modelo económico.",
  business_type_1_data_3: "Más de una decena de cadenas de aprovisionamiento de commodities en espacios de protección medioambiental, llevadas a mercados de mayor valor agregado para generar mayores incentivos a la conservación.",
  business_type_1_data_4: "Identificación de decenas de nuevas rutas de mercado y modelos de aprovechamiento y uso sostenible a partir de valores, atributos y principios activos del capital natural.",
  business_type_2_title: "NEGOCIOS <span>INCLUSIVOS</span>",
  business_type_2_description: "Impulsamos el desarrollo de negocios que inciden directamente en la reducción de la pobreza y brindan oportunidades a poblaciones en contextos de vulnerabilidad.",
  business_type_2_data_1: "Más de 100 comunidades vinculadas en negocios inclusivos.",
  business_type_2_data_2: "Negocios estructurados, que colocan a las personas en el centro.",
  business_type_2_data_3: "Uso de metodologías participativas que empoderan y permiten una mayor apropiación de los negocios estructurados.",
  business_type_3_title: "NEGOCIOS <span>CIRCULARES</span>",
  business_type_3_description: "Generamos negocios que promueven la producción de bienes y servicios sostenibles, reduciendo el consumo, las fuentes de energía, los desperdicios y el tiempo.",
  business_type_3_data_1: "Desarrollo de modelos que utilizan lógicas de gamificación",
  business_type_3_data_2: "Estructuración de modelos que han permitido reducir toneladas de desperdicios en cadenas de valor",
  business_type_3_data_3: "Desarrollo de nuevos productos a partir de desperdicios y/o otros materiales.",
  business_filter_title: "Descubre nuestros casos de éxito y cómo hemos logrado convertir desafíos en oportunidades sustentables",
  business_project_card_keywords: "Palabras clave",
  business_project_show_business: "Tipo de negocio",
  business_project_show_service: "Tipo de servicio",
  business_project_show_sector: "Sector",
  business_project_show_results: "Resultados",
  business_project_show_keywords: "Palabras clave",
}
export const newsTexts = {
  news_filter_title: "Descubre las Últimas Novedades en Desarrollo Sostenible con Impacto Social",
  news_social_subtitle: "Síguenos en Redes Sociales para más Información",
  news_project_card_keywords: "Palabras clave",
  news_more_button: "Ver Más",
}

export const bridge = {
  bridge_banner_info_1: "SERVICIO PARA EXPORTADORAS E IMPORTADORAS DE MATERIAS PRIMAS A EUROPA",
  bridge_banner_info_2: "GARANTIZANDO EL CUMPLIMIENTO A LA REGULACIÓN EUROPEA DE CERO DEFORESTACIÓN",
  bridge_intro_text: "Garantiza la Sostenibilidad de tus Importaciones y exportaciones: Protege el medio ambiente, cumple con los estándares de cero deforestación exigidos por el <span>REGLAMENTO EUROPEO</span>, obtén materias primas de calidad y forma parte de una nueva era de importaciones y exportaciones responsables con la ayuda la tecnología de IA de LAYERS®, desarrollado por HEMAV.",
  bridge_map_title: "DIRIGIDO A",
  bridge_map_description: "<span>Importadoras en Europa y exportadoras globales</span> interesadas en mantener sus exportaciones a Europa. </br>Materias primas (ganado vacuno, cacao, café, palma aceitera, soja, madera y caucho) y productos derivados.",
  bridge_info_title: "¿EN QUÉ CONSISTE",
  bridge_info_text_1: "Recopilación de información detallada sobre las materias primas importadas.",
  bridge_info_text_2: "Evaluación y reducción del riesgo asociado a la deforestación.",
  bridge_info_text_3: "Elaboración de los registros exigidos por el reglamento.",
  bridge_info_text_4: "Contacto con productores que garantizan cadenas de suministro sostenibles.",
  bridge_info_text_5: "Generación de datos descriptivos para garantizar cero deforestación a partir de la plataforma LAYERS® (HEMAV) y modelos de IA.",
  bridge_regulation_text: "Importadores y exportadores, cumplan con los requisitos obligatorios exigidos por el REGLAMENTO EUROPEO referentes a la cero deforestación y ¡abran las puertas a Europa!",
  bridge_text_button: "¡CONTACTANOS YA!", 
}

export const marketplace = {
  marketplace_info_intro: "En nuestro Marketplace, podrás crear y conectarte con oportunidades de negocio que generan un impacto positivo tanto social como medioambiental. Trabajamos para desarrollar cadenas de aprovisionamiento sostenibles, llevando productos, servicios y otras soluciones que impulsan modelos de producción y consumo más responsables y sostenibles. Si buscas potenciar tu negocio sustentable o quieres conectarte con otros negocios que comparten tus valores, ¡has llegado al lugar indicado!",
  marketplace_benefit_title: "Beneficios y Ventajas",
  marketplace_benefits_advantages_1_title: "CADENA DE APROVISIONAMIENTO SOSTENIBLE",
  marketplace_benefits_advantages_1_description: "Aprovecha nuestra plataforma para desarrollar cadenas de suministro responsables y sostenibles, asegurando la procedencia ética y \"eco-friendly\" de los productos y servicios que ofreces.",
  marketplace_benefits_advantages_2_title: "MODELOS DE PRODUCCIÓN Y CONSUMO MÁS RESPONSABLES",
  marketplace_benefits_advantages_2_description: "Únete a la transformación hacia modelos de producción y consumo más sostenibles, promoviendo la economía circular y minimizando el impacto ambiental.",
  marketplace_benefits_advantages_3_title: "DIVERSIFICACIÓN DE LA OFERTA",
  marketplace_benefits_advantages_3_description: "El Marketplace te brinda la posibilidad de diversificar tu oferta de productos o servicios. Al conectarte con diferentes negocios sustentables, podrás ampliar tu catálogo y ofrecer una mayor variedad de opciones a tus clientes, lo que a su vez te permitirá captar nuevos segmentos de mercado y aumentar tu base de clientes.",
  marketplace_benefits_advantages_4_title: "ACCESO A NUEVOS MERCADOS",
  marketplace_benefits_advantages_4_description: "A través del Marketplace, podrás explorar y acceder a nuevos mercados que comparten tus valores de sustentabilidad. Esto te brinda la oportunidad de expandir tus operaciones a nivel nacional e internacional, alcanzando a clientes potenciales que buscan productos y servicios sustentables.",
  marketplace_benefits_advantages_5_title: "SINERGIAS Y COLABORACIONES ESTRATÉGICAS",
  marketplace_benefits_advantages_5_description: "Al interactuar con otros negocios sustentables, podrás identificar oportunidades de colaboración estratégica. Esto incluye asociaciones, alianzas comerciales y proyectos conjuntos que pueden generar sinergias y beneficios mutuos, como compartir recursos, conocimientos y experiencia.",
  marketplace_benefits_advantages_6_title: "CONTACTO DIRECTO CON NEGOCIOS SUSTENTABLES",
  marketplace_benefits_advantages_6_description: "En nuestro Marketplace, conectamos negocios sustentables para que establezcan relaciones comerciales sólidas, aprovechen sinergias y fomenten el crecimiento sostenible. Facilitamos el contacto directo, mejorando la comunicación y la colaboración, promoviendo así relaciones duraderas y beneficiosas para ambas partes.",
  marketplace_info_explore: "Explora nuestro Marketplace ahora y descubre las oportunidades de negocio que impulsarán el crecimiento sostenible de tu empresa. Conecta con negocios comprometidos con la sustentabilidad y juntos, construyamos un futuro más verde y responsable",
  marketplace_indicators_opportunities: "OPORTUNIDADES CREADAS",
  marketplace_indicators_sectors: "SECTORES DESARROLLADOS",
  marketplace_indicators_connections: "CONEXIONES AL MERCADO",
  marketplace_indicators_business: "NEGOCIOS ESTRUCTURADOS",
  marketplace_indicators_estructured_business: "15",
  marketplace_indicators_users: "USUARIOS ACTIVOS",
  marketplace_featured_opportunities: "OPORTUNIDADES DESTACADAS",
  marketplace_sustainable_opportunities: "EXPLORA OPORTUNIDADES SOSTENIBLES",
  marketplace_sustainable_opportunities_find: "Busco",
  marketplace_sustainable_opportunities_find_option_1: "Oportunidades que buscan productos y/o servicios sostenibles",
  marketplace_sustainable_opportunities_find_option_2: "Oportunidades que buscan soluciones para una producción y/o consumo sostenible",
  marketplace_sustainable_opportunities_find_option_3: "Oportunidades que ofrecen productos y/o servicios sostenibles",
  marketplace_sustainable_opportunities_find_option_4: "Oportunidades que ofrecen soluciones para una producción y/o consumo sostenible",
  marketplace_sustainable_opportunities_sector: "Sector",
  marketplace_sustainable_opportunities_country: "País",
  marketplace_sustainable_opportunities_ods: "ODS",
  marketplace_sustainable_opportunities_keywords: "Palabras claves",
  marketplace_creation_title: "¿CÓMO FUNCIONA LA CREACIÓN DE UNA OPORTUNIDAD EN EL MARKETPLACE?",
  marketplace_creation_item_1_title: "IDENTIFICA SI LA OPORTUNIDAD QUE QUIERES CONECTAR ES SOSTENIBLE",
  marketplace_creation_item_1_desc: "¿Ofreces o buscas, un producto, servicio o una solución que beneficia al medioambiente o las personas a través de una cadena de aprovisionamiento sostenible?Si la respuesta es positiva este marketplace para el desarrollo de negocios sostenibles, ¡es para ti!",
  marketplace_creation_item_2_title: "CREA TU OPORTUNIDAD",
  marketplace_creation_item_2_desc: "Crea tu oportunidad dando click al “+” en el costado derecho o en el siguiente botón “Crear oportunidad”",
  marketplace_creation_item_3_title: "¿OFRECES O BUSCAS?",
  marketplace_creation_item_3_desc: "Se abrirá una ventana en la que determinarás si ofreces o buscas un producto, servicio u otra solución.",
  marketplace_creation_item_4_title: "DILIGENCIA UN SENCILLO FORMULARIO",
  marketplace_creation_item_4_desc: "En seguida llenarás un sencillo formulario con ciertas preguntas que definirán tu tipo de negocio para de esta manera conectarte con los negocios indicados de acuerdo a tu necesidad.",
  marketplace_creation_subtitle: "Un proceso sencillo que te brindará grandes beneficios",
  marketplace_create_opportunity_button: "Crear una oportunidad",
  marketplace_fixed_add_button: "Crear oportunidad",
  marketplace_closed_opportunities_button: "Nuestra trayectoria: ver las oportunidades cerradas",
  marketplace_new_opportunity_title: "Define por favor a continuación si buscas u ofreces productos y/o servicios o soluciones sustentables",
  marketplace_new_opportunity_subtitle: "(selecciona una única opción y oprime el botón de guardar)",
  marketplace_new_opportunity_card_option_1: "Productos y/o servicios sostenibles",
  marketplace_new_opportunity_card_option_2: "Soluciones para una producción y/o consumo sostenible",
  marketplace_new_opportunity_find_card: "Busco",
  marketplace_new_opportunity_find_card_description: "Oportunidades que buscan productos, servicios sostenibles y/o soluciones para una producción sostenible, que generan un impacto positivo en el medio ambiente y en las personas.",
  marketplace_new_opportunity_offer_card: "Ofrezco",
  marketplace_new_opportunity_offer_card_description: "Oportunidades que ofrecen productos, servicios sostenibles y/o soluciones para una producción sostenible, que generan un impacto positivo en el medio ambiente y en las personas",
  marketplace_new_opportunity_save_button: "Guardar",
}