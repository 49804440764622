import React, { useState } from "react";
import api from "../api";
import Loading from "../shared/Loading";
import { ContactButton } from "../styled_components/shared/ContactButton";

// Change for setups
const configuration = {
  name_empty: "name_empty",
  post_empty: "post_empty",
  organization_empty: "organization_empty",
  invalid_email: "invalid_email",
  message_empty: "message_empty",
  check_terms: "check_terms",
  error_contact: "error_contact",

  message_color: "green",
  error_color: "red",
  bgColor: "green",
  labelColor: "white",

  fields: {
    name: "",
    post: "",
    email: "",
    organization: "",
    message: "",
  },
};

export default ({ texts }) => {
  const [data, setData] = useState(configuration.fields);
  const [loading, setLoading] = useState(false);

  const callError = (msg, info) => {
    alert(msg)
    setLoading(false);
  };

  const handleInputs = (e) => {
    let value = e.target.value;
    if (value === undefined || value === null) {
      value = e.target.getAttribute("value");
    }
    const keys = e.target.name.split(".");
    setData((prev) => {
      return { ...prev, [keys[0]]: value };
    });
  };

  const processResponse = (res) => {
    if (!res.success || res.success === false) {
      setLoading(false);
      callError(texts[configuration.error_contact]);
    } else {
      window.location.reload();
    }
  };

  const contact = () => {
    const information = {
      data: data,
    };
    api.contact(information, processResponse);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let element = document.getElementById("contact");
    element.scrollIntoView({ block: "start", behavior: "smooth" });
    /* Validations */
    if (loading) return;
    /* After Validations */
    setLoading(true);
    /* API CALLS */
    contact();
  };
  return (
    <div id="contact" className="sectionContact">
      <div className="sectionContact_title">
        <h3>{texts.footer_contact_us}</h3>
        <span>{texts.footer_contact_connect}</span>
      </div>
      {loading && <Loading id="login_load" />}
      {/* <ContactForm {...{t}}/> */}
      <form className="sectionContact_form" onSubmit={handleSubmit}>
        <input className="sectionContact_formInput" placeholder={texts.footer_contact_name} type="text" name="name" value={data.name} autoComplete="name" onChange={handleInputs} required/>
        <input className="sectionContact_formInput" placeholder={texts.footer_contact_position} type="text" name="post" value={data.post} autoComplete="organization-title" onChange={handleInputs} required/>
        <input className="sectionContact_formInput" placeholder={texts.footer_contact_organization} type="text" name="organization" value={data.organization} autoComplete="organization" onChange={handleInputs} required/>
        <input className="sectionContact_formInput" placeholder={texts.footer_contact_email} type="email" name="email" autoComplete="email" value={data.email} onChange={handleInputs} required/>
        <textarea className="sectionContact_formTextarea" placeholder={texts.footer_contact_message} name="message" value={data.message} onChange={handleInputs} required/>
        <div className="sectionContact_form-terms">
          <div className="terms_info">
            <input type="checkbox" required/>
            <span>
              {texts.footer_contact_terms1} <a href="/privacy">{texts.footer_contact_terms2}</a>
            </span>
          </div>
          <ContactButton type="submit">{texts.footer_contact_send}</ContactButton>
        </div>
      </form>
    </div>
  );
};
