import styled from "styled-components";

const rectangleColors = ["#FBCF0D", "#ED245D", "#ED6B33", "#7E143A", "#351A3C", "#C7BEC8"]
const rectangleOpacityColors = ["#FBCF0DB3", "#ED245DB3", "#ED6B33B3", "#7E143AB3", "#351A3CB3", "#C7BEC8B3"]

export const RectangleInfo = styled.div`
  width: 100%;
  height: 25.6rem;
  background-color: ${({$index}) => rectangleColors[$index%6]};
  display: flex;
  align-items: center;
  padding: 2rem 5rem;
  gap: 5%;
  user-select: none;
  position: relative;

  &:hover {
    background-color: ${({$index}) => rectangleOpacityColors[$index%6]};
  }
  @media (max-width: 900px) {
    padding: 2rem 2rem
  }
  @media only screen and (orientation: portrait) {
    height: 48rem;
    flex-direction: column;
    align-items: center;
    padding: 5rem;
  }
`

export const RectangleInfoImg = styled.div`
  width: 21.4rem;
  height: 21.4rem;
  border-radius: 50%;
  img{
    max-width: 100%;
    max-height: 100%;
  }
  @media only screen and (orientation: portrait) {
    ${RectangleInfo}:hover & {
      display:none;
    }
  }
`

export const RectangleInfoCont = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  position: relative;
  color: ${({$index}) => $index%6 === 0 ? "var(--darkPurple)": $index%6 === 5 ? "var(--grey2)" : "#FFF" };

  @media only screen and (orientation: portrait) {
    position: static;
    display: block;
  }
`

export const RectangleInfoTitle = styled.span`
  position: absolute;
  transform: translate(0, -50%);
  left: 0;
  top: 50%;
  font-family: var(--Roboto);
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
  visibility: visible;
  ${RectangleInfo}:hover & {
    visibility: hidden;
  }
  @media only screen and (orientation: portrait) {
    display: flex;
    text-align: center;
    position: relative;
  }
  `

export const RectangleInfoDescription = styled.span`
  flex: 1;
  position: relative;
  font-family: var(--Roboto);
  font-size: 3rem;
  font-weight: 400;
  visibility: hidden;
  display: flex;
  align-items: ${({$index}) => $index%6 === 5 ? "flex-start" : "center" };
  height: 100%;
  ${RectangleInfo}:hover & {
    visibility: visible;
  }
  @media (max-width: 900px) {
    font-size: 2.5rem;
  }
  @media only screen and (orientation: portrait) {
    align-items: center;
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding: 5rem;
  }
`