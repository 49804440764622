import React from 'react'
import Title from './Title'
import MinkaMap from './MinkaMap'

export default ({ title, info, countries, tr }) =>( 
	<div className="sectionMinkaMap">
        <div className="sectionMinkaMap_title">
            <Title text={title}/>
        </div>
		<div className="sectionMinkaMap_cont">
            <MinkaMap {...{info, countries, tr}}/>
        </div>
	</div>
)