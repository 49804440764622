import React from 'react'
export default ({className, title, text}) =>( 
	<div className={"ContentBusiness " + className}>
		<div className="BusinessLine">
			<div className="ContentTitleBusiness">
				<div className="TitleBusiness">{title}</div>
			</div>
			<div className="ContentTextBusiness">
				<div className="TextBusiness">{text}</div>
			</div>
		</div>
	</div>
)
