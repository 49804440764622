import React from 'react'
import styled from "styled-components";

const NewsCard = ({ blogEntrie, text }) => {
	const regex = /(<([^>]+)>)/ig;
	const projectKeywords = blogEntrie?.keywords.join(" - ")

	var dateObject = new Date(blogEntrie.publication_date);
	var day = dateObject.getUTCDate();
	var month = dateObject.getUTCMonth() + 1;
	var year = dateObject.getUTCFullYear();
	var formattedDate = day + '-' + (month < 10 ? '0' + month : month) + '-' + year;

	return (
		<Card onClick={()=>window.location = `/blogentries/${blogEntrie?.info_link || blogEntrie?.id}`}>

			<CardImg $withoutLogo={!blogEntrie.logo} $titleColor={blogEntrie.title_color}>
				<img src={blogEntrie.logo || blogEntrie.image}  alt="image"/>
				<h3>{blogEntrie.title}</h3>
			</CardImg>
			<CardInfoCont>
				<CardDate>{formattedDate}</CardDate>
				<CardDescription dangerouslySetInnerHTML={{__html: (blogEntrie?.text.replace(/<br>/gi, "").substring(0, 150) + "...").replace(regex, '')}}/>
				<CardKeyWords>
				{projectKeywords && 
					<>
						<CardKeyWordsTitle>{text.news_project_card_keywords}:</CardKeyWordsTitle>
						{projectKeywords}
					</>}
				</CardKeyWords>
			</CardInfoCont>
			
		</Card>
	)
}

export default NewsCard

//Styles
const Card = styled.div`
  display: flex;
	position: relative;
	flex-direction: column;
	width: 36.7rem;
	height: 67.8rem;
	cursor: pointer;
	font-family: var(--Roboto);
	margin: auto;
	@media (max-width: 768px) {
		width: 234px;
		height: 433px;
	}
`
const CardImg = styled.div`
  height: 36.6rem;
	padding: .5rem;
  overflow: hidden; 
  display:flex; 
	border: 1px solid rgba(0, 0, 0, 0.40);
	position: relative;
	
	img{
		max-width: 100%;
		max-height: 100%;
		object-fit: ${({$withoutLogo})=>$withoutLogo?"cover":"contain"};
	}
	h3{
		position: absolute;
		width: 80%;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 3rem;
		font-weight: 500;
		color: ${({$titleColor}) => $titleColor || "white"};
		font-family: var(--BarlowCondensed);
		margin: 0;
	}
	@media (max-width: 768px) {
		height: 234px;
		
		h3{
			font-size: 18px;
		}
	}
`
const CardInfoCont = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--grey1);
	color: var(--grey2);
  padding: 7%;
  overflow-y: auto;
  position: relative;
`
const CardDate = styled.h3`
	font-size: 2.5rem;
  font-weight: 400;
	margin: 1rem;
	text-transform: uppercase;
	text-align: center;
	font-family: var(--BarlowCondensed);
	@media (max-width: 768px) {
		font-size: 16px;
	}
`
const CardDescription = styled.div`
	font-size: 1.5rem;
	font-weight: 300;
	@media (max-width: 768px) {
		font-size: 10px;
	}
`
const CardKeyWords = styled.div`
  display: flex;
  margin: 5% 0;
	flex-direction: column;
  font-size: 1.5rem;
  font-weight: 300;
	
	@media (max-width: 768px) {
		font-size: 10px;
	}
`
const CardKeyWordsTitle = styled.div`
	text-transform: uppercase;
`