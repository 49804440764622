import React from "react";
import { LangContext } from "../Language";
import useController from "../hooks/useController";
import View from "./View";
import Form from "./Form";

export default function Controller({ proposal, lang, fromShowComponent, currentUser, opportunityUser, removeBackEvent = ()=>{} }) {
  let props = useController(lang, proposal, "proposals", fromShowComponent ? "view" : "form");
  props.currentUser = currentUser;
  props.opportunityUser = opportunityUser;

  return <LangContext.Provider value={lang}>{props.loading || (props.view === "view" ? <View {...props} hiddenPanelButtons={fromShowComponent} {...{removeBackEvent}} /> : <Form {...props} {...{removeBackEvent}}/>)}</LangContext.Provider>;
  return;
}
