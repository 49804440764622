import styled from "styled-components";

export const BusinessInfo = styled.div`
  width: 100%;
  height: 28.4rem;
  background-image: url(${({$image}) => $image ? $image : "/images2/cards/green-business.webp"});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  display: flex;
  
  margin: 1rem 0;
  gap: 5%;
  user-select: none;

  @media (max-width: 440px) {
    height: 48rem;
    flex-direction: column;
    align-items: center;
  }
`

export const BusinessInfoCont = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  color: white;
  backdrop-filter: brightness(0.6);
  -webkit-backdrop-filter: brightness(0.6);
  padding: 2rem 5rem;
  
  @media (max-width: 900px) {
    padding: 2rem 2rem
  }
  @media (max-width: 440px) {
    padding: 1rem;
  }
`

export const BusinessInfoTitle = styled.span`
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-family: var(--BarlowCondensed);
  font-size: 5rem;
  font-weight: 600;
  text-transform: uppercase;
  visibility: visible;
  white-space: nowrap;
  
  span{
    font-weight: 300;
  }
  ${BusinessInfo}:hover & {
    visibility: hidden;
  }
  @media (max-width: 440px) {
    text-align: center;
  }
  `

export const BusinessInfoDescription = styled.span`
  flex: 1;
  position: relative;
  font-family: var(--BarlowCondensed);
  font-size: 3rem;
  font-weight: 400;
  visibility: hidden;
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 0 8%;

  ${BusinessInfo}:hover & {
    visibility: visible;
  }
  @media (max-width: 900px) {
    font-size: 2.5rem;
  }
  @media (max-width: 440px) {
    font-size: 3.5rem;
    padding: 0;
  }
`