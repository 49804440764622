import React from 'react'

export default ({photo, text}) => ( 
	<div className="Square relative rounded"
		style={{
			backgroundImage: "url("+photo+")",
			backgroundRepeat: "no-repeat",
				backgroundOrigin: "border-box",
				backgroundPosition:"center"
		}}
	>	
		<div className="MinkaCircle MinkaCircleSmall">
			{text}
		</div>
	</div>
)
