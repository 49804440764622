import React, { useState } from 'react'
import { ViewContain } from '../layout/ViewContain';
import { InfoTitle } from '../shared/InfoTitle';
import InputField from '../form/InputField';

const busineesTypeOptions = [{tag: "GR",name: "Negocios Verdes"}, {tag: "IN",name: "Negicios Inclusivos"}, {tag: "CR",name: "Negocios Circulares"}]
const servicesTypeOptions = [{tag: "01",name: "Servicio 1"}, {tag: "02",name: "Servicio 2"}, {tag: "03",name: "Servicio 3"}]
const sectorOptions = [{tag: "01",name: "Sector 1"}, {tag: "02",name: "Sector 2"}, {tag: "03",name: "Sector 3"}] //TODO:Guardar opciones en la base de datos

const BusinessFilter = ({ title }) => {
  const [params, setParams] = useState({});

  const addParam = (e) => {
    const { name, value } = e.target;
    setParams({ ...params, [name]: value });
  }

  return (
    <ViewContain $bgColor={"#EFF3F6"} $paddingY={"2%"} $paddingX={"5%"}>
      <ViewContain $alignItems={"center"}>
        <InfoTitle $textTransform={"uppercase"} dangerouslySetInnerHTML={{__html: title}}/>
      </ViewContain>
      <InputField t="select" data={params} field="business" initValue={"TIPO DE NEGOCIO"} options={busineesTypeOptions} include_blank={false} saveData={addParam} required={false}/>
      <InputField t="select" data={params} field="services" initValue={"TIPO DE SERVICIO"} options={servicesTypeOptions} include_blank={false} saveData={addParam} required={false}/>
      <InputField t="select" data={params} field="sector" initValue={"SECTOR"} options={sectorOptions} include_blank={false} saveData={addParam} required={false}/>
    </ViewContain>
  )
}

export default BusinessFilter