import React from 'react'
import {translations} from "../Language"
import Section from "../layout/Section"
import StaticBanner from "../layout/StaticBanner"
import MainCarouselBanner from '../styled_components/shared/MainCarouselBanner'
import { ViewContain } from '../styled_components/layout/ViewContain'

function Privacy({lang}){
	const n = translations[lang].privacidad 

  const bannerData = [
    {
      "id": 9,
      "location": "privacy",
      "title": n.title,
      "text": "",
      "content_width": 100,
      "content_position": "center",
      "title_color": "#ffffff",
      "text_color": "#ffffff",
      "highlight_color": "#fbcf0d",
      "button_text": null,
      "button_action": null,
      "banner_image": "/images2/banners/privacityBanner.png"
    }
  ]

	return( 
    <>
      <MainCarouselBanner {...{ bannerData }} marketplaceBanner smallText/>
      <ViewContain $paddingX={"10%"} $paddingY={"5%"} $alignItems={"center"}>
        <p>{n.priva1}<strong>{n.priva1a + "."}</strong></p>
        <p>{n.priva2}</p>
        <p>{n.priva3}</p>
        <p>{n.priva4}</p>
        <p>{n.priva5}</p>
        <p>{n.priva6}<br></br>{n.priva6a}<br></br>{n.priva6b}<br></br>{n.priva6c}<br></br>{n.priva6d}</p>
        <p>{n.priva7}</p>
        <p>{n.priva8}</p>
        <p>{n.priva9}</p>
        <p>{n.priva10}</p>
        <p>{n.priva11}<a href="mailto:info@minka-dev.com">info@minka-dev.com</a>{n.priva11a}</p>
        <p>{n.priva12}</p>
        <p>{n.priva13}</p>
        <p>{n.priva14}</p>
      </ViewContain>
    </>
	)
}

export default Privacy;
