import React from 'react'
import styled from 'styled-components'

const GreenButton = ({ text = "GreenButton", action, smallButton, textTransform }) => {
  return (
    <Button onClick={action} $smallButton={smallButton} $textTransform={textTransform}>{text}</Button>
  )
}

const Button = styled.button`
  max-width: ${({$smallButton}) => $smallButton ? "28.2rem" : "54.8rem" };
  width: 100%;
  height: ${({$smallButton}) => $smallButton ? "auto" : "5rem" };
  padding: 0.5rem 0;
  border-radius: 6px;
  border: none;
  background-color: #7DA03C;
  font-size: ${({$smallButton}) => $smallButton ? "2rem" : "2.4rem" };
  font-weight: 400;
  color: white;
  cursor: pointer;
  transition: all .5s;
  text-transform: ${({$textTransform}) => $textTransform || ""};

  &:hover {
    transform: scale(1.1);
  }
`

export default GreenButton