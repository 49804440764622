import React, { useEffect, useRef } from 'react'

const SectionComents = ({data=[], lang}) => {
    const contentRef = useRef({})
    const leftRef = useRef({})
    const rightRef = useRef({})
    const itemRef = useRef({})

	useEffect(() => {
        const content = contentRef.current
        let item = itemRef.current
        let left = leftRef.current
        let right = rightRef.current
        let limit = content.scrollWidth
        let scroll = content.scrollLeft
        let offset = content.offsetWidth
        if (scroll === 0){
            leftRef.current.style.visibility = "hidden"
        }
        if (offset >= limit) {
            right.style.visibility = "hidden";
        }
        const handleScroll=()=>{
            scroll = content.scrollLeft
            let limitCont=window.innerWidth<=1200?scroll+item.offsetWidth:scroll+offset
            if((limitCont)>=limit){
                rightRef.current.style.visibility = "hidden"
            }else{
                leftRef.current.style.visibility = "visible"
                rightRef.current.style.visibility = "visible"
            }
            if (scroll === 0){
                leftRef.current.style.visibility = "hidden"
            }
        }
        const handleClick = (dir)=>{
            if (dir===-1) {
                content.scrollLeft += -item.offsetWidth
            }else{
                content.scrollLeft += item.offsetWidth
            }
        }
        if(content&&left&&right){
            content.addEventListener('scroll', handleScroll)
            left.addEventListener('click',()=>{handleClick(-1)})
            right.addEventListener('click',()=>{handleClick(1)})
        }
        return ()=> {
            content.removeEventListener('scroll',handleScroll)
            left.removeEventListener('click',()=>{handleClick(-1)})
            right.removeEventListener('click',()=>{handleClick(1)})
        }
    }, [])

    return (
        <div className="sectionComents">
            <img className="sectionComents_left" src="/icons2/arrowpurpleleft.png" alt="Left" ref={leftRef}/>
            <div className="sectionComents_cont" ref={contentRef}>
                {data.map((coment, i)=>
                    <div key={'coment_'+i} className={`sectionComents_item${i%2===0?"-change":""}`} ref={itemRef}>
                        <div className="sectionComents_title">
                            <div className="sectionComents_name">
                                {coment.name}
                            </div>
                            <div className="sectionComents_position">
                                {coment.position[lang]}
                            </div>
                        </div>
                        <div className="sectionComents_text">
                            <img className="sectionComents_quotes-init" src="/images2/graphics/comillasmoradas@2x.png" alt="quotes" />
                            <div>
                                {coment.description[lang]}
                            </div>
                            <img className="sectionComents_quotes-end" src="/images2/graphics/comillasmoradas@2x.png" alt="quotes" />
                        </div>
                    </div>
                )}
            </div>
            <img className="sectionComents_right" src="/icons2/arrowpurpleright.png" alt="Right" ref={rightRef}/>
        </div>
    )
}

export default SectionComents
