import React, { useContext } from 'react'
import { ViewContain } from '../styled_components/layout/ViewContain'
import styled from 'styled-components'
import RoundFlag from '../shared/RoundFlag'
import { LangContext, translations } from '../Language'
import { businessTexts } from '../utils/newTexts'

const text = businessTexts

export default ({project, texts}) => {
	const g = translations[useContext(LangContext)].general
	const projectBusinessTypes = project?.business_types.join(" - ")
	const projectServiceTypes = project?.service_types.join(", ")
	const projectKeywords = Array.isArray(project?.keywords) ? project?.keywords.join(" - ") : project?.keywords.split(",").join(" - ") || []
	console.log('project :>> ', project);
	return (
		<ViewContain $paddingX={"10%"} $alignItems={"center"}>
			<ProjectTitle>
				<ProjectTitleCont>
					<Title>{project.title}</Title>
				</ProjectTitleCont>
			</ProjectTitle>
			<Project>
				<ProjectManinInfo>
					<ProjectManinInfoLogo>
						<img src={project.logo} alt="logo" />
					</ProjectManinInfoLogo>
					<ProjectManinInfoText>
						<ProjectManinInfoCountry>
							<RoundFlag id={project.country} width={"11.8rem"} height={"11.8rem"}/>
							{g.country[project.country]}
						</ProjectManinInfoCountry>
						<p dangerouslySetInnerHTML={{ __html: project.description }}/>
					</ProjectManinInfoText>
				</ProjectManinInfo>
				<ProjectAdditionalInfo>
					<ProjectTypesInfo><h3>{text.business_project_show_business}</h3><span>{projectBusinessTypes}</span></ProjectTypesInfo>
					<ProjectTypesInfo><h3>{text.business_project_show_service}</h3><span>{projectServiceTypes}</span></ProjectTypesInfo>
					{project.sector &&
						<ProjectTypesInfo><h3>{text.business_project_show_sector}</h3><span>{project.sector}</span></ProjectTypesInfo>}
					<ProjectResults>
						<h3>{text.business_project_show_results}</h3>
						<span>{project.results}</span>
					</ProjectResults>
					<ProjectInfoOds>
						{project.ods.map((item, i) => (
							<img key={"ods" + i} src={item} alt="Ods" />
						))}
					</ProjectInfoOds>
					<ProjectTypesInfo><h3>{text.business_project_show_keywords}</h3><span>{projectKeywords}</span></ProjectTypesInfo>
				</ProjectAdditionalInfo>
			</Project>
		</ViewContain>
	)
}

const ProjectTitle = styled.div`
	height: 17.2rem;
  position: relative;
`
const ProjectTitleCont = styled.div`
	position: absolute;
  top: 0; 
  left: 50%;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  transform: translate(-50%, 0);
	background-color: #474747;
	display: flex;
	align-items: center;
	justify-content: center;
`
const Title = styled.h2`
	font-family: var(--BarlowCondensed);
	font-size: 4.5rem;
	text-transform: uppercase;
	font-weight: 500;
	color: #fff;
`

const Project = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 5%;
`
const ProjectManinInfo = styled.div`
	display: flex;
	gap: 5rem;
	margin-bottom: 5%;

	@media (max-width: 768px) {
		flex-direction: column;
    align-items: center;
	}
`
const ProjectManinInfoLogo = styled.div`
	width: 37rem;
  height: 37rem;
	padding: .5rem;
  display: flex;
  align-items: center;
	justify-content: center;
	border: 1px solid rgba(0, 0, 0, 0.40);

	img{
		max-width: 100%;
    max-height: 100%;
	}
`
const ProjectManinInfoText = styled.div`
	flex: 1;
	display: flex;
  flex-direction: column;

	p{
		font-family: var(--Roboto);
		font-size: 3rem;
		font-weight: 400;
		color: #361A3C;
	}
`
const ProjectManinInfoCountry = styled.div`
	display: flex;
	align-items: center;
	gap: 3rem;
	font-size: 6rem;
	font-family: var(--BarlowCondensed);
	text-transform: uppercase;
	color: #7E143B;
	font-weight: 600;
	margin-bottom: 5%;
`

const ProjectAdditionalInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-bottom: 10%;
`
const ProjectTypesInfo = styled.div`
	font-family: var(--BarlowCondensed);
	font-size: 5rem;
	color: var(--purple2);
	
	h3{
		white-space: nowrap;
		font-weight: 600;
		margin: 0;
		font-size: 5rem;
	}
	span{
		font-weight: 400;
	}
`
const ProjectResults = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	color: var(--purple2);
	margin: 4% 0;
	
	h3{
		font-family: var(--BarlowCondensed);
		font-size: 5rem;
		font-weight: 600;
		text-transform: uppercase;
		margin: 0;
	}
	span{
		font-family: var(--Roboto);
		font-size: 3rem;
		font-weight: 400;
		color: var(--purple2);
	}
`
const ProjectInfoOds = styled.div`
 	display: flex;
	flex-wrap: wrap;
	gap: 3rem;
	margin-bottom: 4%;

	img{
		max-width: 24.7rem;
		max-height: 24.7rem;
	}
`