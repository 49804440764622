import React, { useEffect, useState } from 'react'

const HeaderMenuMobileMarketplace = ({tr, showMenu, setShowMenu, currentUser}) => {
  const [subAbout, setSubAbout] = useState(false)
  const navigate = (url) => {
    window.location = url;
  };

  const testEnvironment = process.env.REACT_APP_NODE_ENV === 'test'
  
  let link 
  if (!process.env.REACT_APP_NODE_ENV || process.env.REACT_APP_NODE_ENV === 'development') link = "http://localhost:3000/" // development environment
  else if (process.env.REACT_APP_NODE_ENV === 'test') link = "http://minka-dev.onrender.com/" // development environment
  else link = "https://www.minka-dev.com/" // production environment

  const showSubMenu = (classN, n) => {
    let element = document.getElementsByClassName(classN)[n];
    let element2 = document.querySelector(".header_menu-mobile");
    element.style.height = `${element.scrollHeight}px`;
    if (classN !== "header_lang-select") {
      element2.style.height = `auto`;
    }
    if (classN === "header_lang-select") {
      let img = document.querySelector(".header_lang-dd");
      img.style.transform = `rotate(180deg)`;
    }
  };
  const hideMenu = (classN, n) => {
    let element = document.getElementsByClassName(classN)[n];
    let element2 = document.querySelector(".header_menu-mobile");
    element.style.height = `0px`;
    if (classN !== "header_lang-select") {
      element2.style.height = `auto`;
    }
    if (classN === "header_lang-select") {
      let img = document.querySelector(".header_lang-dd");
      img.style.transform = `rotate(0deg)`;
    }
  };

  useEffect(() => {
    let element = document.querySelector(".header_menu-mobile");
    let element2 = document.getElementsByClassName("app_content")[0];
    const clickEvent = () => {
      setShowMenu(!showMenu);
    };
    if (showMenu) {
      element.style.height = `${element.scrollHeight}px`;
    }
    if (!showMenu) {
      element.style.height = `0px`;
    }
    if (showMenu) {
      element2.addEventListener("click", clickEvent);
    }
    return () => {
      if (element2) {
        element2.removeEventListener("click", clickEvent);
      }
    };
  }, [showMenu]);

  return (
      <div className="header_menu-mobile">
      <div className="header_menu-cont">
        <nav>
          <a onClick={() => navigate(`${testEnvironment?"/marketplace":""}/`)}>
            Home
          </a>
        </nav>
        <nav className="pointer" onClick={!subAbout?() => {showSubMenu("header_subMenu-mobile", 0);setSubAbout(!subAbout)}:() => {hideMenu("header_subMenu-mobile", 0);setSubAbout(!subAbout)}}>
          <a>
            Marketplace
          </a>
        </nav>
        <div
          className="header_subMenu-mobile"
          onMouseLeave={() => hideMenu("header_subMenu-mobile", 1)}
        >
          <nav>
            <span className="pointer" onClick={() => navigate(`${testEnvironment?"/marketplace":""}/opportunities`)}>
              Marketplace
            </span>
          </nav>
          <nav>
            <span
              className="pointer"
              onClick={() => navigate(`${testEnvironment?"/marketplace":""}/plus`)}
            >
              Minkadev Plus
            </span>
          </nav>
        </div>
        <nav>
          <a href={link}>Minkadev</a>
        </nav>
      </div>
      <nav className="header_social">
        <a href="https://www.linkedin.com/company/minkadev/" target="_blank" rel="noopener noreferrer">
          <img src="/icons2/linkidmenu@2x.png" alt="in" />
        </a>
        <a href="https://twitter.com/MinkaDev" target="_blank" rel="noopener noreferrer">
          <img src="/icons2/twittermenu@2x.png" alt="tw" />
        </a>
        <a href="https://www.youtube.com/user/MinkaDev/" target="_blank" rel="noopener noreferrer">
          <img src="/icons2/youtubemenu@2x.png" alt="yt" />
        </a>
        <a href="https://www.facebook.com/MinkaDev/" target="_blank" rel="noopener noreferrer">
          <img src="/icons2/facebook@2x.png" alt="fb" />
        </a>
      </nav>
      <nav className="header_profile">
        {currentUser?
          <a href={`${testEnvironment?"/marketplace":""}/profile/` + currentUser.id}>
            <img src="/icons2/userMobile.png" alt="User"/>
          </a>
          :
          <a onClick={() =>  navigate(`${testEnvironment?"/marketplace":""}/login`)}><span>{tr.login}</span></a>
        }
      </nav>
    </div>
  )
}

export default HeaderMenuMobileMarketplace
