import React from 'react'
import MainCarouselBanner from '../styled_components/shared/MainCarouselBanner'
import { ViewContain } from '../styled_components/layout/ViewContain'
import { InfoTitle } from '../styled_components/shared/InfoTitle'
import { InfoText } from '../styled_components/shared/InfoText'
import WorkTeamCard from '../styled_components/about_us/WorkTeamCard'
import TestimonyBanner from '../styled_components/about_us/TestimonyBanner'

const Index = ({ texts, work_team, testimonies, bannerData }) => {

	return(
		<>
			<MainCarouselBanner {...{ bannerData }} smallText/>
			<ViewContain $paddingX={"10%"} $paddingY={"5%"} $alignItems={"center"}>
				<InfoTitle dangerouslySetInnerHTML={{__html: texts.about_us_title_1}} />
				<InfoText dangerouslySetInnerHTML={{__html: texts.about_us_info_1}} $textAlign={"center"} $fontSize={"3.5rem"} $margin={"5rem 0"}/>
				<InfoText dangerouslySetInnerHTML={{__html: texts.about_us_info_2}} $textAlign={"center"} $fontSize={"5rem"} $fontFamily={"var(--BarlowCondensed)"} $color={"#ED6B33"} $margin={"5rem 0"}/>
				<InfoTitle dangerouslySetInnerHTML={{__html: texts.about_us_title_2}} $textTransform={"uppercase"} />
				<ViewContain $flexDirection={"row"} $flexWrap={"wrap"} $justifyContent={"center"} >
					{work_team.map((member, i)=>
						<WorkTeamCard {...{member}} key={"team_card"+i}/>
						)}
				</ViewContain>
				<InfoTitle dangerouslySetInnerHTML={{__html: texts.about_us_title_3}} />
			</ViewContain>
			<TestimonyBanner testimonyData={testimonies}/>
		</>
	)
}

export default Index