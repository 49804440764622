import styled from "styled-components";

export const MarketplaceSteps = styled.div`

  &::before{
    counter-increment: section;
    content: counter(section);
    max-width: 20.8rem; 
    max-height: 20.8rem; 
    width: 100%; 
    height: 100%;
    border: solid 2.8rem var(--darkPurple);
    border-radius: 50%;
    font-family: var(--BarlowCondensed);
    font-size: 9rem;
    font-weight: 600;
    color: var(--darkPurple);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  position: relative;
  display: flex;
  align-items: center;
  gap: 2rem;
  height: 25rem;
  width: 100%;
  font-family: var(--Roboto);

  div{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    color: var(--grey2);
  }
  
  h3{
    font-size: 3rem;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
  }

  span{
    font-size: 3rem;
    font-weight: 300;
  }

  @media (max-width: 1370px) {
    h3, span{
      font-size: 2.5rem;
    }
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    margin: 3rem 0;
    text-align: center;

    &::before{
      max-width: 100%; 
      max-height: 100%; 
      width: 18rem;
      height: 18rem;
      border: solid 2rem var(--darkPurple);
    }
  }
`