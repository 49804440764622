import React from "react";
import DownIcon from "../shared/icons/DownIcon";

const HeaderMenu = ({ texts, isMarketplace, marketPlaceLink }) => {

  const pathname = window.location.pathname
  const navigate = (url) => {
    window.location = url;
  };

  const showSubMenu = (classN, n) => {
    let element = document.getElementsByClassName(classN)[n];
    element.classList.add("is-open")
  };
  
  const hideMenu = (classN, n) => {
    let element = document.getElementsByClassName(classN)[n];
    element.classList.remove("is-open")
  };

  return (
    <div className="header_menu">
      <nav onMouseLeave={() =>{hideMenu("header_subMenu", 0); hideMenu("header_services_menu", 0)}} >
        <div className="pointer main_menu" onMouseOver={() => showSubMenu("header_subMenu", 0)} style={{display: "flex"}}>
          <a className={pathname.includes("services") ? "selected_menu" : ""}>
            {texts.header_services.toUpperCase()}
          </a>
          <div style={{margin: "0 0 0 .5rem"}}>
            <DownIcon width={"1.5rem"} height={".6rem"} color={"#D9D9D9"}/>
          </div>
        </div>
        <div className="header_subMenu">
          <div className="header_subMenu_cont">
            <div className="pointer" onMouseOver={() => showSubMenu("header_services_menu", 0)}>
              <div className="header_consultancy">
                <span>{texts.header_consulting.toUpperCase()}</span>
                <DownIcon width={"1.5rem"} height={".6rem"} color={"#D9D9D9"}/>
                <div className="header_services_menu">
                  <div className="header_services_menuCont">
                    <div className="pointer" onClick={() => navigate("/services")}>
                      {texts.header_diagnosis.toUpperCase()}
                    </div>
                    <div className="pointer" onClick={() => navigate("/services")}>
                      {texts.header_strategy.toUpperCase()}
                    </div>
                    <div className="pointer" onClick={() => navigate("/services")}>
                      {texts.header_implementation.toUpperCase()}
                    </div>
                    <div className="pointer" onClick={() => navigate("/services")}>
                      {texts.header_evaluation.toUpperCase()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pointer" onClick={() => navigate("/bridge2zero")}>
              <span>
                BRIDGE<span style={{ color: "#7DA03C", margin: "0" }}>2</span>
                ZERO
              </span>
            </div>
          </div>
        </div>
      </nav>
      <nav onMouseLeave={() => hideMenu("header_subMenu", 1)} >
        <div className="pointer main_menu" onMouseOver={() => showSubMenu("header_subMenu", 1)} style={{display: "flex"}}>
          <a className={pathname.includes("business") ? "selected_menu" : ""}>
            {texts.header_business.toUpperCase()}
          </a>
          <div style={{margin: "0 0 0 .5rem"}}>
            <DownIcon width={"1.5rem"} height={".6rem"} color={"#D9D9D9"}/>
          </div>
        </div>
        <div className="header_subMenu">
          <div className="header_subMenu_cont">
            <div className="pointer" onClick={() => navigate("/business")} >
              <span style={{ width: "18rem" }}>{texts.header_green.toUpperCase()}</span>
            </div>
            <div className="pointer" onClick={() => navigate("/business")} >
              <span style={{ width: "18rem" }}>{texts.header_inclusive.toUpperCase()}</span>
            </div>
            <div className="pointer" onClick={() => navigate("/business")} >
              <span style={{ width: "18rem" }}>{texts.header_cicular.toUpperCase()}</span>
            </div>
          </div>
        </div>
      </nav>
      <nav onMouseLeave={() => hideMenu("header_subMenu", 2)}>
        <div className="pointer main_menu" onMouseOver={() => showSubMenu("header_subMenu", 2)} style={{display: "flex"}}>
          <a className={isMarketplace ? "selected_menu" : ""}>{texts.header_marketplace.toUpperCase()}</a>
          <div style={{margin: "0 0 0 .5rem"}}>
            <DownIcon width={"1.5rem"} height={".6rem"} color={"#D9D9D9"}/>
          </div>
        </div>
        <div className="header_subMenu">
          <div className="header_subMenu_cont">
            <div className="pointer" onClick={() => navigate(marketPlaceLink)} >
              <span style={{ width: "13rem" }}>{texts.header_marketplace.toUpperCase()}</span>
            </div>
            <div className="pointer" onClick={() => navigate(marketPlaceLink + "/plus")} >
              <span style={{ width: "13rem" }}>MINKADEV PLUS</span>
            </div>
          </div>
        </div>
      </nav>
      <nav className="pointer main_menu" >
        <a className={pathname.includes("about_us") ? "selected_menu" : ""} href="/about_us">{texts.header_about_us.toUpperCase()}</a>
      </nav>
      <nav className="pointer main_menu">
        <a className={pathname.includes("blogentries") ? "selected_menu" : ""} href="/blogentries">{texts.header_news.toUpperCase()}</a>
      </nav>
    </div>
  );
};

export default HeaderMenu;
