// import { hot } from 'react-hot-loader/root';
import React from 'react'

import OpportunityController from "./Controller"
import useBackButtonConfirmation from '../hooks/useBackButtonConfirmation'

const hot = (f) => f
export default hot(({ opportunity, ods, identify, lang, currentUser, currentUserOrg, adminUser }) =>{ 
  const { removeBackEvent } = useBackButtonConfirmation()
  return(
  <OpportunityController {...{ opportunity, ods, identify, lang, currentUser, currentUserOrg, adminUser, removeBackEvent }} fromShowComponent={false}/>
  )
})

