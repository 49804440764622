import React from 'react'
import Title from './Title'

export default ({title, circle1, text1, circle2, text2, circle3, text3}) => {
    return (
        <div className="sectionBusiness">
            <div className="sectionBusiness_title">
                <Title text={title} axiforma={true}/>
            </div>
            <div className="sectionBusiness_cont">
                <div className="sectionBusiness_item">
                    <div className="sectionBusiness_item-img">
                        <img src="/icons2/negociosverdes@2x.png" alt="Negocios Verdes" />
                        <span>{circle1}</span>
                    </div>
                    {text1&&
                    <div className="sectionBusiness_item-text">
                        {text1}
                    </div>}
                </div>
                <div className="sectionBusiness_item">
                    <div className="sectionBusiness_item-img">
                        <img src="/icons2/negociosinclusivos@2x.png" alt="Negocio Inclusivo" />
                        <span>{circle2}</span>
                    </div>
                    {text2&&
                    <div className="sectionBusiness_item-text">
                        {text2}
                    </div>}
                </div>
                <div className="sectionBusiness_item">
                    <div className="sectionBusiness_item-img">
                        <img src="/icons2/negocioscirculares@2x.png" alt="Negocios Circulares" />
                        <span>{circle3}</span>
                    </div>
                    {text3&&
                    <div className="sectionBusiness_item-text">
                        {text3}
                    </div>}
                </div>
            </div>
        </div>
    )
}
