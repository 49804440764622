import styled from "styled-components";

export const MapIndicators = styled.div`
  display: flex;
  margin: 5%;
  font-family: var(--BarlowCondensed);

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`

export const MapIndicatorsCountry = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1200px) {
    width: auto;
  }
`

export const MapIndicatorsSelectCont = styled.div`
  margin: 0 0 0 3rem;
  position: relative;

  select{
    font-size: 6rem;
    font-weight: 600;
    color: #7E143B;
    border: none;
    padding: 0 4rem 0 0;
    background: none;
  }
  option{
    font-size: 4rem;
  }
  svg{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
`

export const MapIndicatorsCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: #7E143B;
`

export const MapIndicatorsItem = styled.div`
  font-size: 15rem;
  font-weight: 600;
  display: flex;
  align-items: center;

  span{
    font-size: 6rem;
    margin: 0 0 0 2rem;
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    font-size: 12rem;
  }
`