import React, { useContext, useState } from "react";
import SimpleButton from "../shared/SimpleButton"
import { LangContext, translations } from "../Language"
import ModalDetail from "../layout/ModalDetail"
import NormalButton from "../shared/NormalButton";
// import {sendServiceMail, getUser, getEspecificUsers} from "../../Utils/api";

export default ({ user_id, identify }) => {
  const l = useContext(LangContext)
  const s = translations[l].servicios
  const [llave, setLlave] = useState(null)

  const listener = () => {
    let id = user_id
    // let id = getUser();
    // if(id){
    // 	getEspecificUsers(id,r=>{
    // 		const data = {
    // 			'name': " ", 
    // 			'email': r.email,
    // 			'title': "Solicitud de servicio",
    // 			'message': 'De una organización está solicitando información sobre el servicio Creación de ' + s.serviceTable[llave].name +' Su contacto es '+r.email+"'"
    // 		}
    // 		sendServiceMail(data,r=>{
    // 			window.flash("Información solicitada. En breve nos ponemos en contacto contigo.", "success")
    // 		});
    // 	});
    // }
    // else{
    // 	window.flash("Favor ingresa y vuelve a intentarlo.", "success")
    // }
  }

  return (
    <div className="ServicesInvite">
      <p>{s.invite}</p>
      <p>{s.invite2}</p>
      <NormalButton id="details" label={translations[l].opportunity.accordion.details} selected={true} listener={() => setLlave("identificar")} />
      {llave &&
        <ModalDetail
          currentUser={user_id}
          service={identify}
          close={() => setLlave(null)}
          sendMail={listener}
        />
      }
    </div>
  )
}