import React from 'react'

export default ({ listener }) =>
  <div className="AddBtn pointer" onClick={listener}>
    <div className="greyBg">
      <img src="/icons2/agregarmorado@2x.png" alt="Add"
        className="iconed" style={{boxShadow: "0px 4px 6px #00000041",borderRadius: "50%"}}
      />
    </div>
  </div>

