import React from 'react'

function ColorSection(props){
	var style = {...props.style,
		// backgroundColor: "rgba(58,19,62,1)"
	}

	if(props.bg){
		style = {...props.style,
			background: "url("+ props.bg +") rgba(58,19,62,0.65)",
			backgroundBlendMode: "multiply"
		}
	}
	return( 
		<div 
			className="ColorSection"
			style={style}
		>
			{props.children}
		</div>
	)
}

export default ColorSection;
