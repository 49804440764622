import React, {useState, useEffect, useRef} from 'react'
import ColorTitle from "./ColorTitle"
import anime from "../shared/anime"


var timer;
export default ({prensaTitle, press, lang}) =>{
	const [index, setIndex] = useState(0)
	const savedCallback = useRef();
	useEffect(()=>{savedCallback.current = loop})
	useEffect(()=>{
		timer = setInterval(()=>{savedCallback.current(1)}, 8000)
		return ()=> clearInterval(timer)
	}, [])
	useEffect(()=>anime({targets: "#pressWidget",scale:[0,1],duration:200,easing: "easeInSine"}), [index])

	const click = (qty) => {clearInterval(timer); loop(qty)}
	const loop = function(qty){
		let newQty = index + qty
		if(press[newQty]){setIndex(newQty); return}
		setIndex(qty === 1 ? 0 : press.length - 1)
	}

	return( 
		<div className="Testimonials flex flex-justify">
			<div><img src="/icons/arrowLeft.png" onClick={()=> click(-1)} alt="Left Arrow"/></div>
			<div id="pressWidget" className="flex flex-justify flex-column">
				<ColorTitle text={prensaTitle}/>
				<div className="logoPrensa"><img alt="Image Press" className="imgLogoPrensa" src={press[index].image}/></div>
				<div className="parrafoSection">
					<a style={{color: "white",fontSize:"1.2em"}} href={press[index].url}>{press[index].description[lang]}</a>
				</div>
			</div>
			<div><img src="/icons/arrowRight.png" onClick={()=> click(1)} alt="Right Arrow"/></div>
		</div>
	)
}