/* requires anime.js
<SimpleButton
	id={uuid}
	color="String: purple, orange, pink..."
	label=""
	labelColor optional
	bgColor options
	fill={optional bool}
	listener={func} optional
	scale={1.1} optional
	disable=optional bool
/>
*/
import React, {useState} from 'react'
import anime from "../shared/anime"

export default ({id, label, disable, listener, labelColor, bgColor, scale = 1.05, color = "purple", fill = false}) => {
	const [clicked, setClicked] = useState(false)
	let labelToUpperCase = typeof label === "string" ? label.toUpperCase() : label
	
	const clickHandler = () => {
	 	if(!clicked){
			setClicked(true)
			if(disable){return false}
			anime({
				targets: "#"+id, scale: scale, duration: 100,
				direction: "alternate",easing: "linear",
				complete: function(anim){
					setClicked(false)
					setTimeout(()=>listener && listener(id), 50)
				}
			})
		}
	}
   if(color === "var(--purple)") color = "purple";
   if(color === "var(--pink)") color = "pink";
   if(color === "var(--orange)") color = "orange";
   if(color === "var(--yellow)") color = "yellow";
   
   if(disable) color = "grey";
   const style = {}
   if(labelColor){style.color = labelColor}
   if(bgColor){style.backgroundColor = bgColor}

 	return( 
    	<div 
    		id={id}
			onClick={clickHandler}
			className={"simple-btn btn-"+color + (fill ? " btn-fill" : " btn-outline")}
			style={style}
		>
			{labelToUpperCase}
		</div>
    )
}

