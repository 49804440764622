import React, {  useEffect, useState } from 'react'
import styled from "styled-components";
import { YellowButton } from './YellowButton';

const testEnvironment = process.env.REACT_APP_NODE_ENV === 'test'

const MainCarouselBanner = ({ bannerData = [], smallText, marketplaceBanner }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const contentWithArrows = bannerData.length >= 2

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % bannerData.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + bannerData.length) % bannerData.length);
  };

  const navigate = (url) => {
    if (url.startsWith("#")) {
      const element = document.querySelector(url);
      if (!element) return;
      element.scrollIntoView({behavior: "smooth"});
    }else {
      if (marketplaceBanner) window.location = `${testEnvironment?"/marketplace":""}` + url;
      else window.location = url;
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     nextSlide()
  //   }, 7000);

  //   return () => clearInterval(interval);
  // }, [currentSlide]);

  return (
    <Banner>
      {contentWithArrows && 
      <BannerArrowCont>
        <img src={"/images2/svg/banner_arrow.svg"} onClick={prevSlide}/>
      </BannerArrowCont>}

      <BannerCont>
        <SliderCont $currentSlide={currentSlide}>
        {bannerData.map((data, i)=>
          <SliderItem key={"slider" + i} $image={data?.banner_image}>
            <SliderInfo $smallText={smallText} $textColor={data?.text_color} $highlightColor={data?.highlight_color} $textWidth={data?.content_width} $contentPosition={data?.content_position}>
              {data?.title !== "" && <h1 dangerouslySetInnerHTML={{__html: data.title}}/>}
              <h2 dangerouslySetInnerHTML={{__html: data.text}}/>
              {data?.button_action &&
                <YellowButton onClick={()=>navigate(data?.button_action)}>{data?.button_text}</YellowButton>
              }
            </SliderInfo>
          </SliderItem>
        )}
        </SliderCont>
      </BannerCont>
      
      {contentWithArrows && 
      <BannerArrowCont $right={"true"}>
        <img src={"/images2/svg/banner_arrow.svg"} onClick={nextSlide}/>
      </BannerArrowCont>}

      {contentWithArrows && 
      <Indicators>
        {bannerData.map((_, i)=>
          <Indicator onClick={()=>setCurrentSlide(i)} $selected={currentSlide === i} key={"indicator"+i}/>
        )}
      </Indicators>}
    </Banner>
  )
}

const Banner = styled.div`
  height: 500px;
  position: relative;
  user-select: none;

  @media (max-width: 1368px) {
    height: 59.2rem;
  }
`

const BannerCont = styled.div`
  position: absolute;
  top: 0; 
  left: 50%;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  transform: translate(-50%, 0);
`

const BannerArrowCont = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  max-width: 4.5rem;
  width: 20%;
  position: absolute;
  left: ${({$right}) => $right !== "true" && "0"};
  right: ${({$right}) => $right === "true" && "0"};
  transform: scaleX(${({$right}) => $right === "true" ? "-1" : "1"});
  cursor: pointer;
  z-index: 1;

  img{
    width: 100%;
  }
  @media (max-width: 1688px) {
    left: ${({$right}) => $right !== "true" && "3%"};
    right: ${({$right}) => $right === "true" && "3%"};
  }
  @media (max-width: 1024px) {
    max-width: 4rem;
  }
  @media (max-width: 440px) {
    max-width: 3.5rem;
    left: ${({$right}) => $right !== "true" && "5%"};
    right: ${({$right}) => $right === "true" && "5%"};
  }
`

const SliderCont = styled.div`
  position: relative;
  display: flex;
  transition: transform 0.3s ease-in-out;
  transform: translateX(${({$currentSlide}) => -$currentSlide * 100}%);
  height: 100%;
`

const SliderItem = styled.div`
  &::before{
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${({$image}) => $image ? $image : "/images2/banners/bannermarketplace.png"});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }

  width: 100%;
  height: 100%;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
`

const SliderInfo = styled.div`
  max-width: 1512px;
  margin: 0 10%;
  padding: 0 8%;
  height: 100%;
  gap: 6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({$contentPosition}) => $contentPosition === "left" ? "flex-start" : $contentPosition === "right" ? "flex-end" : "center"}; 
  
  h1{
    text-transform: uppercase;
    margin: 0;
    font-family: var(--BarlowCondensed);
    font-size: 10rem;
    font-weight: 600;
    color: white;
    letter-spacing: 1px;
  }
  h2{
    width: ${({$textWidth}) => $textWidth ? `${$textWidth}%` : "100%"};
    margin: 0;
    font-family: var(--BarlowCondensed);
    font-size: ${({$smallText}) => $smallText ? "4.5rem" : "7rem"};
    font-weight: 500;
    color: ${({$textColor}) => $textColor || "white"};
    text-align: ${({$contentPosition}) => $contentPosition || "center"};
  }
  span{
    color: ${({$highlightColor}) => $highlightColor};
  }
  strong{
    color: ${({$highlightColor}) => $highlightColor};
    font-weight: 600;
  }
  @media (max-width: 1688px) {
    padding: 0;
  }
  @media (max-width: 1024px) {
    h2, strong{
      font-size: ${({$smallText}) => $smallText ? "4rem" : "5rem"};
    }
  }
  @media (max-width: 568px) {
    align-items: center;

    h2{
      width: 100%;
      text-align: center;
    }
  }
`
const Indicators = styled.div`
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
`

const Indicator = styled.div`
  width: 1rem;
  height: 1rem;
  margin: 0 2rem;
  background-color: white;
  border-radius: 50%;
  opacity: ${({$selected}) => $selected ? "1" : "0.4" };
  cursor: pointer;
`

export default MainCarouselBanner