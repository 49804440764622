import React from "react";
import SectionInfoWithCard from "../layout/SectionInfoWithCard";
import SectionProjectWithCard from "../layout/SectionProjectWithCard";
import SectionContact from "../layout/SectionContact";
import Banner from "../layout/Banner";

const greenBusiness = {
  icon: "/icons2/nvwhite@2x.png",
};

const i = {
  bannerImage1: "/images2/banners/bannernegociosverdes.jpg",
  // bannerImage2: "/images2/banners/bannerinversiones.png",
  // bannerImage3: "/images2/banners/bannernosotros.png",
  // bannerImage4: "/images2/banners/bannerservicios.png",
};

export default ({ t, i1 }) => {
  const bannerImages = Object.entries(i).filter((item) => {
    if (item[0].includes("bannerImage")) {
      return item;
    }
    return false;
  });

  const dataList = Object.entries(t).filter((item) => {
    if (item[0].includes("dataList_text")) {
      return item;
    }
    return false;
  });

  return (
      <>
        <Banner
          title={t.banner_title}
          staticText={t.banner_text}
          images={bannerImages}
          icon={greenBusiness.icon}
        />
        <SectionInfoWithCard
          text={t.greenBussines_intro}
          titleCard={t.dataList_title}
          dataList={dataList}
        />
        <SectionProjectWithCard title={t.ourProjects} type={"green"}/>

      </>
  );
};
