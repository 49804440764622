/* requires anime.js
<Button
	scale={1.1}
	id={uuid}
	label="String"
	labelColor={color}
	listener={func}
	offsetY={0}
	image={imageVariable} or
	bgColor={color}
	borderRadius={20}
	height={50}
/>
*/
import React, { Component} from 'react'
import anime from "./anime"

class Button extends Component{
	clickHandler = () =>{
		if(this.props.disable){return false}
		
		const t = this
		var scale = this.props.scale || 1.1
		anime({
      targets: "#"+this.props.id,
      scale: scale, duration: 200,
      direction: "alternate",
      easing: "linear",
      complete: function(anim){
      	t.props.listener && t.props.listener("home");
      }
    })
	}
	render(){
		const top = 50 + (this.props.offsetY ? this.props.offsetY : 0)
		return( 
			<div id={this.props.id} className="Button relative"
				style={{cursor:"pointer"}}
				onClick={this.clickHandler}
			>	
				{this.props.image ?
					<img alt="boton" 
						src={this.props.image} 
						className="centeredImage max100"
					/> 
					:
					<div className="centeredImage max100"
						style={{
							backgroundColor: this.props.bgColor || "green",
							borderRadius: this.props.borderRadius || 20,
							height: this.props.height || 50,
							width: this.props.width || 100
						}}
					/>
				}

				<h3 className="absolute"
					style={{
						top:top+"%", 
						left: "50%", 
						transform:"translate(-50%,-50%)",
						color:this.props.labelColor || "white",
						fontSize: this.props.labelSize || "1em",
						margin:0
					}}
				>
					{this.props.label}
				</h3>
			</div>
		)
	}
}

export default Button;
