
import React from 'react'

const i = {
    opportunities:"/icons2/oportunidades.png",
    sectors:"/icons2/sectores.png",
    connections:"/icons2/conexiones.png",
    business:"/icons2/negocios.png",
    users:"/icons2/usuarios.png",
}

const SectionResults = ({ white, t, data }) => {
    const images = Object.entries(i)
    return (
        <div className="results" style={{backgroundColor:`${white?"":"#c8bec973"}`}}>
            {images.map((image, i)=>
                <div className="results_cont" key={"marketplace"+i}>
                    <img src={image[1]} alt="marketplacce"/>
                    <div>{data[image[0]]}<br/>{t[image[0]]}</div>
                </div>
            )}
        </div>
    )
}

export default SectionResults
