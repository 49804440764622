import React, { useState, useEffect, useRef } from "react";
import api from "../api";
import { YellowButton } from "../styled_components/shared/YellowButton";
import { ViewContain, FiltersContainer } from "../styled_components/layout/ViewContain";
import { InfoText } from "../styled_components/shared/InfoText";
import { InfoTitle } from "../styled_components/shared/InfoTitle";
import { RectangleInfo, RectangleInfoCont, RectangleInfoDescription, RectangleInfoImg, RectangleInfoTitle } from "../styled_components/shared/RectangleInfo";
import { MarketplaceIndicator } from "../styled_components/marketplace/MarketplaceIndicator";
import OpportunityCard from "../styled_components/marketplace/OpportunityCard";
import InputField from "../styled_components/form/InputField";
import { MarketplaceSteps } from "../styled_components/marketplace/MarketplaceSteps";
// import { FeaturedBanner } from "../styled_components/marketplace/FeaturedBanner";
import { InfoSubtitle } from "../styled_components/shared/InfoSubtitle";
import { AddFixedButton } from "../styled_components/marketplace/AddFixedButton";
import MainCarouselBanner from "../styled_components/shared/MainCarouselBanner";
import CardsContain from "../styled_components/shared/CardsContain";
import { GreenButton } from "../styled_components/shared/GreenButton";
import ContentLoaderOpportunityCard from "../styled_components/marketplace/ContentLoaderOpportunityCard";

const Index = ({ texts, users = 0, sectorCount = 0, opportunitiesCount = 0, solutions = 0, ods, bannerData }) => {
  const [loading, setLoading] = useState(false);
  const [opportunities, setOpportunities] = useState([]);
  const [params, setParams] = useState({});
  const [showMoreButton, setShowMoreButton] = useState(true)
  //Switched closed opportunities
  const [closed, setClosed] = useState(false);

  const testEnvironment = process.env.REACT_APP_NODE_ENV === 'test'

  const opportunityTypeOptions = [{tag: "NS",name: texts.marketplace_sustainable_opportunities_find_option_1}, {tag: "NC",name: texts.marketplace_sustainable_opportunities_find_option_2}, {tag: "OS",name: texts.marketplace_sustainable_opportunities_find_option_3}, {tag: "OC",name: texts.marketplace_sustainable_opportunities_find_option_4}]
  
  const benefitsAndAdvantages = [{image: "/images2/icons/supply_chain.webp", title: texts.marketplace_benefits_advantages_1_title, text: texts.marketplace_benefits_advantages_1_description}, { image: "/images2/icons/production_models.webp", title: texts.marketplace_benefits_advantages_2_title, text: texts.marketplace_benefits_advantages_2_description}, { image: "/images2/icons/diversification.webp", title: texts.marketplace_benefits_advantages_3_title, text: texts.marketplace_benefits_advantages_3_description}, { image: "/images2/icons/access_to_markets.webp", title: texts.marketplace_benefits_advantages_4_title, text: texts.marketplace_benefits_advantages_4_description}, { image: "/images2/icons/synergies.webp", title: texts.marketplace_benefits_advantages_5_title, text: texts.marketplace_benefits_advantages_5_description}, { image: "/images2/icons/direct_contact.webp", title: texts.marketplace_benefits_advantages_6_title, text: texts.marketplace_benefits_advantages_6_description}]

  const marketplaceIndicators = [{ text: texts.marketplace_indicators_opportunities, cant: opportunitiesCount}, { text: texts.marketplace_indicators_sectors, cant: sectorCount}, { text: texts.marketplace_indicators_connections, cant: solutions}, { text: texts.marketplace_indicators_business, cant: texts.marketplace_indicators_estructured_business}, { text: texts.marketplace_indicators_users, cant: users}]
  
  const stepsToCreateAOpportunity = [{ title: texts.marketplace_creation_item_1_title, description: texts.marketplace_creation_item_1_desc}, { title: texts.marketplace_creation_item_2_title, description: texts.marketplace_creation_item_2_desc}, { title: texts.marketplace_creation_item_3_title, description: texts.marketplace_creation_item_3_desc}, { title: texts.marketplace_creation_item_4_title, description: texts.marketplace_creation_item_4_desc}]

  const page = useRef(1);

  const getApiOpportunities = (fromButton) => {// call api "/api/opportunities/:page" for get other opportunities
		setLoading(true);
    if (fromButton) page.current = page.current + 1
    else {
      page.current = 1
      setOpportunities([])
    }
    api.getOpportunities(params, (r) => {
      setLoading(false);
      setOpportunities(opp =>{ return fromButton ? [...opp, ...r] : [...r]})
      if(r.length < 6) setShowMoreButton(false)
      else setShowMoreButton(true)
    },
    page.current);
	}

  //Trigger on parameter change for filters
  useEffect(() => {
    getApiOpportunities(false)
  }, [params]);

  const addParam = (field, value) => {
    if (field === "type"){
      const setType = (op) => {
        let defaultOption = [null, null]
        let options = {
          "NS": [false, "cadena"],
          "NC": [false, "consumo"],
          "OS": [true, "cadena"],
          "OC": [true, "consumo"],
        }
        return options[op] ?? defaultOption
      }
      return setParams((e) => ({ ...e, ["offers"]: setType(value)[0], ["otype"]: setType(value)[1], ["type"]: value }));
    }
    setParams((e) =>({ ...e, [field]: value }));
  };

  const clickaddOp = () => {
    window.location = `${testEnvironment?"/marketplace":""}/opportunities/new`
  };

  return (
    <>
      <MainCarouselBanner {...{ bannerData }} marketplaceBanner smallText/>
      <ViewContain $paddingX={"10%"} $alignItems={"center"}>
        <InfoText $textAlign={"justify"}>
        {texts.marketplace_info_intro}
        </InfoText>
        <InfoTitle $textTransform={"uppercase"}>
          {texts.marketplace_benefit_title}
        </InfoTitle>
        {benefitsAndAdvantages.map((item, i)=>
          <RectangleInfo $index={i} key={"info"+i}>
            <RectangleInfoImg><img src={item.image} alt="icon" /></RectangleInfoImg>
            <RectangleInfoCont $index={i}>
              <RectangleInfoTitle>{item.title}</RectangleInfoTitle>
              <RectangleInfoDescription $index={i}>{item.text}</RectangleInfoDescription>
            </RectangleInfoCont>
          </RectangleInfo>
        )}
        <InfoText $textAlign={"center"} $fontWeight={"700"} >
          {texts.marketplace_info_explore}
        </InfoText>
        <YellowButton onClick={clickaddOp} $textTransform={"uppercase"}>{texts.marketplace_create_opportunity_button}</YellowButton>
      </ViewContain>
      <ViewContain $paddingY={"5%"}>
        {marketplaceIndicators.map((indicator, i)=>
          <MarketplaceIndicator $index={i} key={"indicator"+i}>
            <div>{indicator.cant}</div>
            <span>{indicator.text}</span>
          </MarketplaceIndicator>
        )}
      </ViewContain>
      {/* <ViewContain $alignItems={"center"} $paddingY={"5%"}>
        <InfoTitle $textTransform={"uppercase"}>
          {texts.marketplace_featured_opportunities}
        </InfoTitle>
        <FeaturedBanner src="/images2/banners/outstanding_opportunity.png"/>
      </ViewContain> */}
      <ViewContain $bgColor={"#EFF3F6"} $paddingY={"2%"}>
        <ViewContain $alignItems={"center"}>
          <InfoTitle $textTransform={"uppercase"}>
            {texts.marketplace_sustainable_opportunities}
          </InfoTitle>
        </ViewContain>
        <ViewContain $paddingX={"5%"}>
          <InputField t="select" data={params} field="type" initValue={texts.marketplace_sustainable_opportunities_find} options={opportunityTypeOptions} include_blank={false} saveData={(e) => addParam("type", e.target.value)} required={false} />
        </ViewContain>
        <FiltersContainer>
          <InputField t="sectors" data={params} field="sector" initValue={texts.marketplace_sustainable_opportunities_sector} include_blank={false} saveData={(e) => addParam("sector", e.target.value)} required={false} getOnly={true}//only for select values
          />
          <InputField t="countries" data={params} field="country" initValue={texts.marketplace_sustainable_opportunities_country} include_blank={false} saveData={(e) => addParam("country", e.target.value)} required={false} flag={false} />
          <InputField t="select" data={params} field="ods" options={ods} initValue={texts.marketplace_sustainable_opportunities_ods} include_blank={false} saveData={(e) => addParam("ods", e.target.value)} required={false} />
          <InputField t="debounced" data={params} field="q" saveData={(e) => addParam("q", e.target.value)} placeholder={texts.marketplace_sustainable_opportunities_keywords} value={params.q || ""} />
        </FiltersContainer>
      </ViewContain>
      <ViewContain $alignItems={"center"} style={{paddingTop: "5%"}}>
        <GreenButton style={{fontSize: "1.8rem"}} $width={"37.8rem"} $height={"3.69rem"} $textTransform={"uppercase"} onClick={() => setClosed((e) => { addParam("closed", !e); setClosed(!e); })} disabled={opportunities.length === 0}>{closed ? texts.marketplace_open_opportunities_button : texts.marketplace_closed_opportunities_button }</GreenButton>
      </ViewContain>
      {opportunities.length > 0 && <CardsContain>
        {opportunities.map((e) => (
          <OpportunityCard opportunity={e} key={e.id + "op"} suitableMessage={texts.marketplace_plus_suitable_message}/>
        ))}
      </CardsContain>}
      {showMoreButton && !loading &&
        <ViewContain $paddingY={"3%"} $alignItems={"center"}>
          <GreenButton style={{fontSize: "1.8rem"}} $width={"37.8rem"} $height={"3.69rem"} $textTransform={"uppercase"} onClick={()=>getApiOpportunities(true)}>{texts.marketplace_more_button}</GreenButton>
        </ViewContain>
      }
      {loading &&
        <CardsContain>
        {Array(6).fill().map((_, i) => (
          <ContentLoaderOpportunityCard key={"contentLoader"+i}/>
        ))}
      </CardsContain>}
      <ViewContain $paddingY={"5%"} $paddingX={"5%"} $alignItems={"center"}>
        <InfoTitle $textTransform={"uppercase"} >{texts.marketplace_creation_title}</InfoTitle>
        {stepsToCreateAOpportunity.map((step, i)=>
          <MarketplaceSteps key={"step"+i}>
            <div>
              <h3>{step.title}</h3>
              <span>{step.description}</span>
            </div>
          </MarketplaceSteps>
        )}
        <InfoSubtitle>{texts.marketplace_creation_subtitle}</InfoSubtitle>
        <div style={{paddingBottom: "5%"}}>
          <YellowButton onClick={clickaddOp} $textTransform={"uppercase"}>{texts.marketplace_create_opportunity_button}</YellowButton>
        </div>
      </ViewContain>
      <AddFixedButton onClick={clickaddOp}>
        <img src="/images2/svg/add_circle.svg" alt="AddOp" />
        <span>{texts.marketplace_fixed_add_button}</span>
      </AddFixedButton>
    </>
  );
}

export default Index
