import React from 'react'

const ProgressBar = ({height = 10, bgColor = "grey", borderRadius = 0, percentage = 0, barColor = "green"}) => {
	return (
		<div className="ProgressBar">
			<div className="progressBg"  style={{position:"relative", height, backgroundColor: bgColor, borderRadius}}>
				<div className="progressBar" style={{ width: percentage + "%", position:"absolute", height, backgroundColor: barColor, borderRadius}}/>
			</div>
		</div>
	)
}

export default ProgressBar