import React, { useState, useEffect, useContext } from "react";
import { LangContext, translations } from "../Language";
import api from "../api";
import { getTotalFields, getPercentage } from "../utils/validators";
// import algoritmo from "../utils/algoritmo";
import { GreyButton } from "../styled_components/shared/GreyButton";
import { styled } from 'styled-components';
import Modal from "../shared/Modal";
import { GreenButton } from "../styled_components/shared/GreenButton";
import LoadingBars from "../styled_components/shared/LoadingBars";
import { object } from "prop-types";

function NewPanel({ data, model, mode, setScore, dispatch, removeBackEvent }) {
  const l = useContext(LangContext);
  const n = translations[l].newPanel;
  const [percentage, setPercentage] = useState(0);
  const [loading, setLoading] = useState("");
  const [totalFields, setTotalFields] = useState(getTotalFields(data));
  const [openConfirm, setOpenConfirm] = useState(false)

  const publish = () => {
    removeBackEvent()
    setLoading(true);
    // if ( //deshabilitado mientras se estandarizan los scores
    //   (model === "opportunities" && data.type?.has_community) ||
    //   (model === "proposals" && !data.type.has_community)
    // ) {
    //   // console.log("publication with scores")
    //   const scores = algoritmo(data);
    //   // console.log(scores)
    //   api.score(
    //     data.id,
    //     data.agent_organization.id,
    //     scores,
    //     model,
    //     (response) => {
    //       setScore(response);
    //     }
    //   );
    // } else {
    // }
    api.publish(data.id, model, (res) => {
      // console.log(res)
      if (res.success && res.success === true) {
        window.location = `/${model}/${data.id}`;
      }
    });
  };

  // const save = (preview) => {
  //   removeBackEvent()
  //   setLoading(true);
  //   // console.log(model)
  //   api.save(data, model, (res) => {
  //     dispatch({
  //       type: "save",
  //       object: res.object,
  //       view: preview ? "view" : "form",
  //     });
  //     setLoading(false);
  //   });
  // };
  
  useEffect(() => {
    setTotalFields(getTotalFields(data))
  }, [data.type.has_community])

  useEffect(() => {
    setPercentage(getPercentage(totalFields, data))
  }, [data, totalFields])

  const changePreview = () => {
    if (mode === "edit") dispatch({type:"view", object: data});
    else dispatch({type:"form", selectedForm: 0});
  };

  if (data.published) return <div />

  return (
    <>
      <NewPanelCont $preview={mode==="preview"}>
        <NewPanelInfo>
          <p>{model==="opportunities"?n.publishMessage:n.propPublishMessage}</p>
        </NewPanelInfo>
        <ButtonsCont>
          <GreyButton $width={"27.7rem"} $height={"3.9rem"} onClick={changePreview} $textTransform={"uppercase"}>{mode === "edit" ? n.preview : n.edit}</GreyButton>
          <GreyButton $width={"27.7rem"} $height={"3.9rem"} onClick={percentage >= 100 ? ()=>{setOpenConfirm(true)} : ()=>{} } $disable={percentage < 100 ? true : false} $textTransform={"uppercase"}>{model==="opportunities"?n.publishButton:n.propPublishButton}</GreyButton>
        </ButtonsCont>
      </NewPanelCont>
      {openConfirm && (
        <Modal title={n.publishModalTitle} close={() => setOpenConfirm(false)} width={"112.8rem"} height={"42.1rem"} >
          <LabelModalInfo>
            <span>{n.publishModalText}</span>
            <ButtonCont>
              {loading ?
                <LoadingBars color={"#fff"} />
              :
                <GreenButton $width={"24.9rem"} $height={"4.3rem"} onClick={publish}>{n.publish}</GreenButton>
              }
            </ButtonCont>
          </LabelModalInfo>
        </Modal>
      )}
    </>
  );
}

const NewPanelCont = styled.div`
  position: ${({$preview}) => $preview && "sticky"};
  bottom: ${({$preview}) => $preview && 0};
  display: flex;
  margin: 1rem 0 5%;
  padding: 2rem 0;
  gap: 10%;
  background-color: #fff;
`
const NewPanelInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  p{
    margin: 0;
    font-size: 1.5rem;
    color: var(--grey5);
    font-weight: 400;
  }
`
const ButtonsCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
`

const LabelModalInfo = styled.div`
  padding: 5% 10% 0;
  height: 100%;
  font-family: var(--BarlowCondensed);
  font-size: 2.5rem;
  text-align: left;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`
const ButtonCont = styled.div`
  width: 24.9rem;
  height: 8rem;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default NewPanel;