import React, { useEffect, useContext } from 'react'
import Features from "./Features"
import SimpleButton from "../shared/SimpleButton"
import anime from "../shared/anime"
import { LangContext, translations } from "../Language"

export default ({ currentUser, service, sendMail, close }) => {
	useEffect(() => { anime({ targets: ".ModalDetail", top: ["-100%", "50%"], duration: 800, }) }, [])
	const l = useContext(LangContext)
	const m = translations[l].modals.modalDetail

	return (
		<div className="ModalBg" onClick={close}>
			<div className="ModalDetail">
			<div className="close" onClick={close}>x</div>
				<div className="containerD">
					<div className="ServiceIcon h100">
						<img src="/icons2/lupa@2x.png" alt="" className="max100" />
					</div>
					<div className="IconName">{service.name[l]}</div>
					<div className="ModalDesc">{service.description[l]}</div>

					{service.directed[l] &&
						<React.Fragment>
							<div className="subtitle">{m.who}</div>
							<div className="ModalDesc">{service.directed[l]}</div>
						</React.Fragment>
					}
					{service.result_desc[l] &&
						<React.Fragment>
							<div className="subtitle">{m.result}</div>
							<div className="ModalDesc">{service.result_desc[l]}</div>
						</React.Fragment>
					}

					{service.results[l].map(feature =>
						<Features
							key={feature[0]}
							title={feature[0]}
							description={feature[1]}
						/>
					)}
					<div className="buttonInfo">
						{currentUser ?
							<a href="/contact">
								<SimpleButton
									id="ServicesInfo" color="orange" fill={true}
									label={m.result}
									// listener={sendMail}
								/>
							</a>
							:
							<div className="text-center">{m.registerInfo}</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}