import React, {useContext, useState, useReducer} from 'react'
import {LangContext, translations} from "../Language"
import Button from "../shared/SimpleButton"
import StaticBanner from "../layout/StaticBanner"

const initialState = {
  estado: false,
  image: "/images/banners/bannerMarketplace.png", 
  title: "MARKETPLACE", subtitle: true
}

const reducer = (state, action) =>{
  if(action === "main") return initialState
  return {estado: true, image:"/images/banners/banner-MPlus.png", title: "", subtitle:false}
}


export default ({modal})=>{
    const l = useContext(LangContext)
    const [data, dispatch] = useReducer(reducer, initialState)
    const subt = translations[l].general.marketBanner

    return(
      <div className="CarrouselJs">
        <StaticBanner {...data}/>
        {data.estado &&
          <>
            <div className="minkaPlus">
              <img className="logominka" alt="" src="/images/logos/logoBlanco.png"/>
              <img className="logoplus" alt="" src="/images/logos/plusBlanco.png"/>
              <div className="buttonbanner">
                <Button label= {translations[l].home.knowMore} color="pink" fill={true} listener={modal}></Button>
              </div>
            </div>
          </>
        }
        <div className="dots">
            <div className="dot" onClick={()=>dispatch("main")}></div>
            <div className="dot" onClick={()=>dispatch("second")}></div>
        </div>
      </div>
    )
}

