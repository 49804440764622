export const pins = [ //countryCode, %x, %y, country
  ["BD", 73, 41, "Bangladesh"],
  ["BO", 20, 68, "Bolivia"],
  ["CO", 18, 55, "Colombia"],
  ["EC", 16, 59, "Ecuador"],
  ["AR", 22, 88, "Argentina"],
  ["CR", 15, 51, "Costa Rica"],
  ["FR", 43, 23, "Francia"],
  ["ES", 42, 29, "España"],
  ["MA", 41, 34, "Marruecos"],
  ["NG", 44, 51, "Nigeria"],
  ["PE", 17, 64, "Perú"],
  ["MX", 9, 42, "México"],
  ["DO", 18, 43, "República Dominicana"],
  ["TN", 45, 34, "Túnez"],
  ["EG", 51, 37, "Egipto"],
  ["BZ", 13, 46, "Belice"],
  ["GT", 12, 47, "Guatemala"],
  ["SV", 12, 48, "El Salvador"],
  ["HN", 13, 48, "Honduras"],
  ["NI", 14, 49, "Nicaragua"],
  ["PA", 16, 52, "Panamá"],
  ["VE", 20, 54, "Venezuela"],
  ["GY", 22, 53, "Guyana"],
  ["SR", 23, 53, "Surinam"],
  ["GF", 24, 54, "Guayana Francesa"],
  ["BR", 26, 66, "Brasil"],
  ["PY", 23, 73, "Paraguay"],
  ["CL", 19, 81, "Chile"],
  ["UY", 25, 86, "Uruguay"],
]