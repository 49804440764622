import React, { useEffect, useState } from 'react'
import Title from './Title'

export default ({data, inv=false, title="", sTitle}) => {
    const [actions, setActions] = useState([])
    useEffect(() => {
        if (sTitle){
            sTitle.map((title)=>title.shift())
            sTitle.map((title,i)=>title.push(data[i][1]))
            setActions(sTitle)
        }
    }, [])

    const array = actions.length===0?data:actions

    return (
    <div className={`sectionAction${inv?'-inverse':''}${sTitle?'-sTitle':''}`}>
            <Title text={title} color={inv?'white':''}/>
            {data&&<ul className={`sectionAction_items${inv?'-inverse':''}${sTitle?'-sTitle':''}`}>
                {array.map((action, i)=>
                    <li key={"sectionAction"+i}>
                        {sTitle&&
                        <div className="sectionAction-sTitle_sTitle">
                            <Title text={action[0]} margin={10}/>
                        </div>
                        }
                        {action[1]}
                    </li>
                )}
            </ul>}
        </div>
    )
}
