import React from "react";

export default ({ url, children, id = false, remote = false, className = "rails_form", method = "post", multipart = false }) => {
	let attr = {
		className: className,
		action: url,
		acceptCharset: "UTF-8",
		method: method
	}
	if (multipart) attr.encType = "multipart/form-data";
	if (id) attr.id = id;
	if (remote) attr["data-remote"] = true

	return (
		<div>
			<form {...attr}>
				<input type='hidden' name='authenticity_token' value={document.querySelector('meta[name="csrf-token"]').content} />
				<input type="hidden" name="_method" value={method == "put" ? "patch" : method} />
				<input name="utf8" type="hidden" value="✓" />
				{children}
			</form>
		</div>
	)
}