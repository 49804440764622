import React from 'react'
export default ({text,color,margin=5,align,interline=false, axiforma=false, style={}}) => {
    const styles = {
        ...style,
        color:color,
        marginBottom:`${margin+"%"}`,
        textAlign:align, 
        lineHeight:`${interline?"1.7":""}`, 
        fontFamily:`${axiforma?"Axiforma":""}`
    }
    return(
    <div className={`title${axiforma?"-axiforma":""}`} style={styles}>{text}</div>
    )}
