import React, { useContext} from 'react'
import RoundFlag from "../shared/RoundFlag"
import {LangContext, translations} from "../Language"
import { MapIndicators, MapIndicatorsCont, MapIndicatorsCountry, MapIndicatorsItem, MapIndicatorsSelectCont } from '../styled_components/map/MapIndicators'
import DownIcon from '../shared/icons/DownIcon'

export default ({iconCountry, selectCountry, countries, numOpportunity, numProjects, opportunityText, projectsText, lang}) => {
	const countriesLabel = translations[lang].general.country
	
	return( 
		<MapIndicators >
			<MapIndicatorsCountry >
				<RoundFlag id={iconCountry} width={"11.8rem"} height={"11.8rem"}/>
				<MapIndicatorsSelectCont>
					<select onChange={(e)=>selectCountry(e.target.value)} value={iconCountry} style={{textTransform: "uppercase"}}>
						{countries.map((country) => <option key={country} value={country}>{countriesLabel[country]}</option>)}
					</select>
					<DownIcon color={"#7E143B"}/>
				</MapIndicatorsSelectCont>
			</MapIndicatorsCountry>
			<MapIndicatorsCont>
				<MapIndicatorsItem>{numOpportunity} <span>{opportunityText}</span></MapIndicatorsItem>
				<MapIndicatorsItem>{numProjects} <span>{projectsText}</span></MapIndicatorsItem>
			</MapIndicatorsCont>
		</MapIndicators>
	)
}
