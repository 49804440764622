import React, { useState } from "react";
import SectionActions from "./SectionActions";
import Title from "./Title";

const i = {
  identify_Img: "/icons2/indentificamos@2x.png",
  connect_Img: "/icons2/conectamos@2x.png",
  structure_Img: "/icons2/estructuramos@2x.png",
  accompany_Img: "/icons2/acompañamos@2x.png"
}

export default ({ tr }) => {
  const [implementationStage, setImplementationStage] = useState("identify");

  const stages = {
    identify: tr.implementationStage1,
    connect: tr.implementationStage2,
    structure: tr.implementationStage3,
    accompany: tr.implementationStage4,
  };

  const stagesItem = Object.entries(stages);
  
  const dir_title = `${implementationStage}_title`
  const dir_text = `${implementationStage}_text`
  const dir_Img = `${implementationStage}_Img`
  const dir_achievement1 = `${implementationStage}_achievement1`
  const dir_achievement2 = `${implementationStage}_achievement2`
  const dir_achievement3 = `${implementationStage}_achievement3`
  
  const actionsText = Object.entries(tr).filter((item) => {
    if (item[0].includes(`actions_text_${implementationStage}`)) {
      return item
    }
    return false
  })

  return (
    <div className="implementationStage">
      <div className="implementationStage_select">
        {stagesItem.map((stage, i) => (
          <div
          key={"implementationStage" + i}
            className={`implementationStage_tag${
              implementationStage === stage[0] ? "-selected" : ""
            }`}
            onClick={() => setImplementationStage(stage[0])}
          >
          {stage[1]}
          </div>
        ))}
      </div>
      <div className="implementationStage_cont">
        <div className="implementationStage_info">
          <Title text={tr[dir_title]}/>
          <div className="implementationStage_text">{tr[dir_text]}</div>
        </div>
        <div className="implementationStage_info2">
          <img src={i[dir_Img]} alt="StageImg" />
          <div className="implementationStage_info2-text">
              <span className="implementationStage_info2-text1">{tr[dir_achievement1]}</span>
              <span className="implementationStage_info2-text2">{tr[dir_achievement2]}</span>
              <span className="implementationStage_info2-text1">{tr[dir_achievement3]}</span>
          </div>
        </div>
      </div>
      <SectionActions title={tr.actions_title} data={actionsText} inv/>
    </div>
  );
};
