import React, { useEffect, useState } from "react";
import Title from "./Title";

export default ({ title, text, img = [], inverse, purple }) => {
  const [showImage, setShowImage] = useState(0);
  
  const nextImg = () => {
    let currentImg = showImage;
    currentImg = (currentImg + 1) % img.length;
    setShowImage(currentImg);
  };
  
  return (
    <div className={`sectionCircle ${purple?"sectionPurple":""}`}>
      <div className={`sectionCircle_cont ${inverse?"circleInverseText":""} ${purple?"sectionPurpleText":""}`}>
        <Title text={title} align={inverse?"right":"left"} axiforma={true} />
        <div className="sectionCircle_text">{text}</div>
      </div>
      <div className={`sectionCircle_cont2 ${inverse?"circleInverse":""}`}>
        <div className="sectionCircle_cImg">
          {img.map((img, i) => (
            <img
              key={"image" + i}
              className="sectionCircle_img"
              src={img[1]}
              alt="image"
              style={{ opacity: `${showImage === i ? "1" : "0"}`}}
              onClick={()=>nextImg()}
            />
          ))}
        </div>
      </div>

      {/* mobile */}
      <div className={`sectionCircle_cont-mobile ${purple?"sectionPurpleText":""}`}>
        <Title text={title} interline={true} axiforma={true} />
        <div className="sectionCircle_cImg">
          {img.map((img, i) => (
            <img
            key={"image" + i}
            className="sectionCircle_img"
            src={img[1]}
            alt="image"
            style={{ opacity: `${showImage === i ? "1" : "0"}` }}
          />
          ))}
        </div>
        <div className="sectionCircle_text">{text}</div>
      </div>
    </div>
  );
};
