import React from 'react'
import SectionContact from '../layout/SectionContact'

function Footer({ texts }){
	const countries = [texts.footer_colombia, texts.footer_mexico, texts.footer_spain]
	
	let marketPlaceLink 
	if (!process.env.REACT_APP_NODE_ENV || process.env.REACT_APP_NODE_ENV === 'development') marketPlaceLink = "http://marketplace.minka.com:3000" // development environment
	else if (process.env.REACT_APP_NODE_ENV === 'test') marketPlaceLink = "http://minka-dev.onrender.com/marketplace" // test environment
	else marketPlaceLink = "https://marketplace.minka-dev.com" // production environment

	return(
		<footer id='footer'>
			<div className="footer_top">
				<div className="footer_info_cont">
					<div className="footer_about">
						<h3>{texts.footer_about}</h3>
						<div className="footer_info">
							<a href="/services"><span>{texts.footer_consulting}</span></a>
							<a href="/bridge2zero"><span>{texts.footer_service_bridge2zero}</span></a>
							<a href="/business"><span>{texts.footer_business}</span></a>
							<a href={marketPlaceLink}><span>{texts.footer_marketplace}</span></a>
							<a href="/about_us"><span>{texts.footer_about_us}</span></a>
							<a href="/blogentries"><span>{texts.footer_news}</span></a>
						</div>
					</div>
					<div className="footer_social">
						<div className="social_icons">
							<a href="https://www.linkedin.com/company/minkadev/" target="_blank" rel="noopener noreferrer"><img src="/images2/svg/linkedin_icon.svg" alt="linkedin" /></a>
							<a href="https://twitter.com/MinkaDev/" target="_blank" rel="noopener noreferrer"><img src="/images2/icons/x_icon.webp" alt="x" /></a>
							<a href="https://www.instagram.com/minkadev/" target="_blank" rel="noopener noreferrer"><img src="/images2/svg/instagram_icon.svg" alt="instagram" /></a>
							<a href="https://www.facebook.com/MinkaDev/" target="_blank" rel="noopener noreferrer"><img src="/images2/svg/fecebook_icon.svg" alt="facebook" /></a>
							<a href="https://www.youtube.com/user/MinkaDev" target="_blank" rel="noopener noreferrer"><img src="/images2/svg/youtube_icon.svg" alt="youtube" /></a>
						</div>
						<span>info@minka-dev.com</span>
					</div>
					<div className="footer_location">
						<h3>{texts.footer_where_are_we}</h3>
						<div className="footer_info">
							{countries.map((country, i)=>
								<span key={"Country"+i}>{country}</span>
							)}
						</div>
					</div>
				</div>
				<SectionContact texts={texts}/>
			</div>
			<div className="footer_bottom">
				<div>{texts.footer_rights}</div>
				<a href="/privacy">{texts.footer_privacy}</a>
			</div>
		</footer>
	)
}

export default Footer;