import React, { useEffect, useRef, useState } from "react";
import FichaProposal from "../Proposals/FichaProposal";
import CardsContain from "../styled_components/shared/CardsContain";
import OpportunityCard from "../styled_components/marketplace/OpportunityCard";
import { ViewContain } from "../styled_components/layout/ViewContain";
import LoadingBars from "../styled_components/shared/LoadingBars";
import api from "../api";
import { GreyButton } from "../styled_components/shared/GreyButton";

const testEnvironment = process.env.REACT_APP_NODE_ENV === 'test'

const SectionOporPropo = ({ proposals, currentUser, opportunityTab, c, opportunitiesCount = 0 }) => {
  const [showMoreButton, setShowMoreButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const page = useRef(1);
  
  const getApiOpportunities = (fromButton) => {// call api "/api/opportunities/:page" for get other opportunities
		setLoading(true);
    if (fromButton) page.current = page.current + 1
    api.getUserOpportunities({user_id: currentUser.id}, (r) => {
      setLoading(false);
      setData(data => [...data, ...r])
      if (r.length < 6) setShowMoreButton(false)
    },
    page.current);
	}

  useEffect(() => {
    getApiOpportunities()
  }, [])

  useEffect(() => {
    console.log('data', data)
    if (data.length < opportunitiesCount) setShowMoreButton(true)
  }, [data])
  

  const clickaddOp = () => {
    window.location = `${testEnvironment?"/marketplace":""}/opportunities/new`
  };

  console.log('proposals', proposals)

  return (
    <>
      <CardsContain>
        {opportunityTab ? (
          <>
            {data.map((opportunity) => (
              <OpportunityCard opportunity={opportunity} key={opportunity.id + "op"} edit={!opportunity.published}/>
            ))}
          </>
        ) : (
          <>
            {proposals.map((proposal) => (
              <OpportunityCard opportunity={proposal} key={proposal.id + "op"} edit={!proposal.published} proposal/>
            ))}
          </>
        )}
      </CardsContain>
      {showMoreButton && !loading &&
        <ViewContain $alignItems={"center"} style={{paddingBottom: "3%"}}>
          <GreyButton $width={"25rem"} $height={"4.8rem"}  $textTransform={"uppercase"} onClick={()=>getApiOpportunities(true)}>{c.moreButton}</GreyButton>
        </ViewContain>
      }
      {loading &&
        <ViewContain $alignItems={"center"} style={{paddingBottom: "3%"}}>
          <LoadingBars color={"#5E5E5E"}/>
        </ViewContain>}
      {opportunityTab && data.length === 0 && !loading &&
        <ViewContain $alignItems={"center"} style={{paddingBottom: "3%"}}>
          <GreyButton $width={"25rem"} $height={"4.8rem"} $textTransform={"uppercase"} onClick={clickaddOp}>{c.createOpportunity}</GreyButton>
        </ViewContain>
      }
    </>
  );
};

export default SectionOporPropo;
