import React, { useEffect, useState } from 'react'

export default ({image, title, subtitle, link, linkText, alignLeft=false, icon, news=false, home=false, center=false, plus, filter}) => {
	const [align, setAlign] = useState("")
	const [alignItems, setAlignItems] = useState("")
	const [margin, setMargin] = useState("")
	useEffect(() => {
		if (alignLeft) {
			setAlign("left")
			setAlignItems("flex-start")
			setMargin("2% 5% 5%")
		}
		if (news||center) {
			setAlign("center")
			setAlignItems("center")
			setMargin("0 0 2% 0")
		}
		if (home){
			setMargin("0 5%")
		}
		if (link){
			setMargin("2% 5% 2%")
		}
	}, [])
	return ( 
	<div className={`staticBanner${plus?"--plus":""}${news?" bannerNews":""}${filter?" banner-filter":""}`} style={{backgroundImage: "url("+image+")",justifyContent:`${center?"center":""}`, alignItems:alignItems}}>
		{icon&&
		<img className={`staticBanner-icon${plus?"--plus":""}`} src={icon} alt="icon"/>}
		<h3 style={{alignSelf:`${alignLeft?'flex-start':''}`,textAlign:align, margin:`${alignLeft?'0 0 0 5%':margin}`}}>{title}</h3>
		{subtitle && 
		<p style={{textAlign:align, width:`${home?"75%":""}`,margin:margin}}>{subtitle}</p>}
		{link&&
		<div className="staticBanner-link" style={{alignSelf:`${alignItems||''}`,textAlign:align, margin:`${alignLeft?'0 0 0 5%':''}`}}>
			<a href={link}>{linkText}</a>
			<img src="/icons2/arrowRightYellow.png" alt="arrow" />
		</div>}
	</div>
	)
}