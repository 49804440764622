//Oportunidades con agent_organization solamente
const getOrganizationScore = (data) => {
  //Todos los puntajes se evaluan de 0 a 100
  //------- > Organization
    //edad_organizacion
    //country_score: país de la organización
    //organization_activity
    //organization_sustainability
  //------- > Agent Organization
    //eficiencia: nb_benefited, projects_budget
    //impacto: nb_benefited (nb_projects)
    //participation_score: varios campos miscellaneos //TODO CHECK
    //challenge_score
  //----- > Territorio
    //work_score: varios campos miscellaneos //TODO CHECK
    //alliances_score: varios campos miscellaneos //TODO CHECK

  //TODO: CHECKEAR c8h1 e impacto

  const organization = data.organization;
  const opportunity_organization = data.agent_organization;
  const territory = data.territory;
  //Cálculo de calificación H1P1

  //NON EXISTANT: rel_ong et al rel ... afecta "funcionamiento"
  var c8h1 = 0;

  var score = 0;

  //
  if (organization.age === "LT1") score = 25;
  else if (organization.age === "B15") score = 50;
  else if (organization.age === "B510") score = 75;
  else if (organization.age === "GT10") score = 100;
  var edad_organizacion = score;

  //Cálculo de calificación H1P3
  //País
  score = 0;
  let cnt = organization.country;
  if (
    cnt === "AF" ||
    cnt === "AO" ||
    cnt === "BJ" ||
    cnt === "BO" ||
    cnt === "CM" ||
    cnt === "TD" ||
    cnt === "CG" ||
    cnt === "CM" ||
    cnt === "TD" ||
    cnt === "CG" ||
    cnt === "CD" ||
    cnt === "CIV" ||
    cnt === "ER" ||
    cnt === "GA" ||
    cnt === "GN" ||
    cnt === "GQ" ||
    cnt === "HT" ||
    cnt === "LY" ||
    cnt === "MW" ||
    cnt === "MR" ||
    cnt === "MMR" ||
    cnt === "NE" ||
    cnt === "SY" ||
    cnt === "CF" ||
    cnt === "ST" ||
    cnt === "SN" ||
    cnt === "SS" ||
    cnt === "SR" ||
    cnt === "VE" ||
    cnt === "ZW" ||
    cnt === "CG" ||
    cnt === "CD" ||
    cnt === "CIV" ||
    cnt === "ER" ||
    cnt === "GA"
  )
    score = 10;
  else if (
    cnt === "DZ" ||
    cnt === "BT" ||
    cnt === "BF" ||
    cnt === "DJ" ||
    cnt === "GM" ||
    cnt === "IR" ||
    cnt === "IQ" ||
    cnt === "LA" ||
    cnt === "LR" ||
    cnt === "MG" ||
    cnt === "ML" ||
    cnt === "FM" ||
    cnt === "NG" ||
    cnt === "SL" ||
    cnt === "SD" ||
    cnt === "TZ" ||
    cnt === "TJ" ||
    cnt === "TG" ||
    cnt === "UZ"
  )
    score = 20;
  else if (
    cnt === "AR" ||
    cnt === "BD" ||
    cnt === "BA" ||
    cnt === "BI" ||
    cnt === "CV" ||
    cnt === "KH" ||
    cnt === "EC" ||
    cnt === "EG" ||
    cnt === "ET" ||
    cnt === "HN" ||
    cnt === "IN" ||
    cnt === "KE" ||
    cnt === "KI" ||
    cnt === "LS" ||
    cnt === "MZ" ||
    cnt === "NI" ||
    cnt === "SZ" ||
    cnt === "UG" ||
    cnt === "YE"
  )
    score = 30;
  else if (
    cnt === "BZ" ||
    cnt === "BR" ||
    cnt === "CR" ||
    cnt === "SV" ||
    cnt === "PH" ||
    cnt === "GD" ||
    cnt === "GY" ||
    cnt === "ID" ||
    cnt === "MH" ||
    cnt === "JO" ||
    cnt === "KW" ||
    cnt === "LB" ||
    cnt === "MT" ||
    cnt === "NP" ||
    cnt === "PK" ||
    cnt === "PY" ||
    cnt === "DO" ||
    cnt === "KN" ||
    cnt === "UA"
  )
    score = 40;
  else if (
    cnt === "AL" ||
    cnt === "BS" ||
    cnt === "BB" ||
    cnt === "CN" ||
    cnt === "HR" ||
    cnt === "SB" ||
    cnt === "JM" ||
    cnt === "MV" ||
    cnt === "MA" ||
    cnt === "NA" ||
    cnt === "PW" ||
    cnt === "RU" ||
    cnt === "SM" ||
    cnt === "VC" ||
    cnt === "RS" ||
    cnt === "LK" ||
    cnt === "UY" ||
    cnt === "VN" ||
    cnt === "ZM"
  )
    score = 50;
  else if (
    cnt === "AG" ||
    cnt === "AZ" ||
    cnt === "BY" ||
    cnt === "DM" ||
    cnt === "FJ" ||
    cnt === "GH" ||
    cnt === "GR" ||
    cnt === "GT" ||
    cnt === "IT" ||
    cnt === "MD" ||
    cnt === "MN" ||
    cnt === "CZ" ||
    cnt === "KG" ||
    cnt === "RO" ||
    cnt === "WS" ||
    cnt === "LC" ||
    cnt === "SC" ||
    cnt === "TT" ||
    cnt === "TR" ||
    cnt === "VU"
  )
    score = 60;
  else if (
    cnt === "BH" ||
    cnt === "BW" ||
    cnt === "BRN" ||
    cnt === "BG" ||
    cnt === "CO" ||
    cnt === "SK" ||
    cnt === "ES" ||
    cnt === "HU" ||
    cnt === "KZ" ||
    cnt === "LU" ||
    cnt === "MX" ||
    cnt === "ME" ||
    cnt === "OM" ||
    cnt === "PA" ||
    cnt === "PE" ||
    cnt === "PL" ||
    cnt === "QA" ||
    cnt === "ZA" ||
    cnt === "TO" ||
    cnt === "TN"
  )
    score = 70;
  else if (
    cnt === "DE" ||
    cnt === "SA" ||
    cnt === "AM" ||
    cnt === "AT" ||
    cnt === "BE" ||
    cnt === "CL" ||
    cnt === "CY" ||
    cnt === "AE" ||
    cnt === "SI" ||
    cnt === "EE" ||
    cnt === "FR" ||
    cnt === "IL" ||
    cnt === "JP" ||
    cnt === "LV" ||
    cnt === "MK" ||
    cnt === "NL" ||
    cnt === "PT" ||
    cnt === "PR" ||
    cnt === "RW" ||
    cnt === "CH"
  )
    score = 80;
  else score = 100;
  var country_score = score;

  //Cálculo de calificación H1P2
  score = 0;
  //actividad de la organización
  const acts = [
    [["aco"], 10, 5],
    [["ast"], 15, 10],
    [["fin", "gp", "ip"], 20, 10],
    [["fyc"], 15, 10],
  ];

  for (const act of acts) {
    for (const tag of act[0]) {
      if (organization.act[tag] !== undefined) {
        score = score + act[1];
        if (organization.act[tag] === 0) organization.act[tag] = 100;
        if (organization.act[tag] >= 50) score = score + act[2];
      }
    }
  }

  if (score < 10) score = 10;
  var organization_activity = score;

  //Cálculo de calificación H1P7
  //Sostenibilidad de la organización
  score = 0;
  if (organization.sustainability === "GPR") score = 80;
  else if (organization.sustainability === "GDO") score = 50;
  else if (organization.sustainability === "DLN") score = 100;
  else if (organization.sustainability === "VPS") score = 80;
  else if (organization.sustainability === "OTHA") score = 20;
  var organization_sustainability = score;

  //// -----AGENT-ORGANIZATION
  //Cálculo de calificación eficiencia
  score = 0;
  if (opportunity_organization.nb_benefited === "P500") score = 100;
  else if (
    opportunity_organization.nb_benefited === "NGPS" ||
    opportunity_organization.nb_benefited === "NOEST"
  )
    score = 0;
  else if (
    opportunity_organization.projects_budget === "E4M3" ||
    opportunity_organization.projects_budget === "ME40" ||
    opportunity_organization.projects_budget === "SIUA"
  ) {
    if (opportunity_organization.nb_benefited === "P110") score = score + 50;
    else if (opportunity_organization.nb_benefited === "P11100")
      score = score + 55;
    else if (opportunity_organization.nb_benefited === "P101200")
      score = score + 60;
    else if (opportunity_organization.nb_benefited === "P201500")
      score = score + 65;

    if (opportunity_organization.projects_budget === "E4M3") score = score + 25;
    else if (
      opportunity_organization.projects_budget === "ME40" ||
      opportunity_organization.projects_budget === "SIUA"
    )
      score = score + 30;
  } else if (
    opportunity_organization.projects_budget === "E314" ||
    opportunity_organization.projects_budget === "MA14"
  ) {
    if (opportunity_organization.nb_benefited === "P110") score = score + 30;
    else if (opportunity_organization.nb_benefited === "P11100")
      score = score + 40;
    else if (opportunity_organization.nb_benefited === "P101200")
      score = score + 50;
    else if (opportunity_organization.nb_benefited === "P201500")
      score = score + 60;

    if (opportunity_organization.projects_budget === "E314") score = score + 10;
  }
  var eficiencia = score;

  //Cálculo de calificación de impacto
  score = 0;
  if (
    opportunity_organization.nb_benefited === "NGPS" ||
    opportunity_organization.nb_benefited === "NOEST"
  )
    score = 0;
  // puntaje afectado al eliminar el campo nb_projects (Diapositiva 9)
  // else {
  //   if (opportunity_organization.nb_projects === 'NGN') {
  //     if (opportunity_organization.nb_benefited === 'P110')
  //       score = 5;
  //     else if (opportunity_organization.nb_benefited === 'P11100')
  //       score = 10;
  //     else if (opportunity_organization.nb_benefited === 'P101200')
  //       score = 20;
  //     else if (opportunity_organization.nb_benefited === 'P201500')
  //       score = 40;
  //     else if (opportunity_organization.nb_benefited === 'P500')
  //       score = 60;
  //   }
  //   else if (opportunity_organization.nb_projects === 'UAC') {
  //     if (opportunity_organization.nb_benefited === 'P110')
  //       score = 80;
  //     else if (opportunity_organization.nb_benefited === 'P11100')
  //       score = 85;
  //     else if (opportunity_organization.nb_benefited === 'P101200')
  //       score = 90;
  //     else if (opportunity_organization.nb_benefited === 'P201500')
  //       score = 95;
  //     else if (opportunity_organization.nb_benefited === 'P500')
  //       score = 100;
  //   }
  //   else if (opportunity_organization.nb_projects === 'SAD') {
  //     if (opportunity_organization.nb_benefited === 'P110')
  //       score = 75;
  //     else if (opportunity_organization.nb_benefited === 'P11100')
  //       score = 80;
  //     else if (opportunity_organization.nb_benefited === 'P101200')
  //       score = 85;
  //     else if (opportunity_organization.nb_benefited === 'P201500')
  //       score = 90;
  //     else if (opportunity_organization.nb_benefited === 'P500')
  //       score = 100;
  //   }
  //   else if (opportunity_organization.nb_projects === 'MDZ') {
  //     if (opportunity_organization.nb_benefited === 'P110')
  //       score = 70;
  //     else if (opportunity_organization.nb_benefited === 'P11100')
  //       score = 75;
  //     else if (opportunity_organization.nb_benefited === 'P101200')
  //       score = 80;
  //     else if (opportunity_organization.nb_benefited === 'P201500')
  //       score = 85;
  //     else if (opportunity_organization.nb_benefited === 'P500')
  //       score = 100;
  //   }
  // }
  var impacto = score;

  //Cálculo de calificación H1P5
  //
  const participation_check = {
    participation_network: { LO: 10, MI: 20, HI: 30 },
    experience_management: { LO: 3.3, MI: 6.7, HI: 10 },
    influence: { LO: 3.3, MI: 6.7, HI: 10 },
    experience_development: { LO: 6.3, MI: 13.3, HI: 20 },
    leadership: { LO: 10, MI: 20, HI: 30 },
    credibility_ong: { LO: 3.3, MI: 6.7, HI: 10 },
    credibility_people: { LO: 3.3, MI: 6.7, HI: 10 },
    market_orientation: { LO: 3.3, MI: 6.7, HI: 10 },
    decision_tools: { LO: 3.3, MI: 6.7, HI: 10 },
  };

  score = 0;
  for (let tag in participation_check)
    score = score + (participation_check[tag][opportunity_organization[tag]] || 0);
  var participation_score = score;

  //Cálculo de calificación H1P6
  score = 100;
  const challenges = [
    ["li", 10],
    ["pt", 10],
    ["vm", 1],
    ["se", 1],
    ["ga", 1],
    ["oz", 10],
    ["op", 5],
    ["in", 5],
    ["ot", 10],
  ];
  for (const e of challenges) {
    if (opportunity_organization.challenges.includes(e[0]))
      score = score - e[1];
  }
  var challenge_score = score;

  //TERRITORY--------------------------
  //Work Check
  score = 0;
  const work_check = {
    work: { EDS: 2, PRD: 4, NAD: 6, PRA: 8, DAD: 10 },
    trust: { EDS: 15, PRD: 12, NAD: 9, PRA: 6, DAD: 3 },
    presence: { EDS: 2, PRD: 4, NAD: 6, PRA: 8, DAD: 10 },
    public: { EDS: 2, PRD: 4, NAD: 6, PRA: 8, DAD: 10 },
    order: { EDS: 30, PRD: 24, NAD: 18, PRA: 12, DAD: 6 },
    communities: { EDS: 3, PRD: 6, NAD: 9, PRA: 12, DAD: 15 },
    cooperative: { EDS: 2, PRD: 4, NAD: 6, PRA: 8, DAD: 10 },
  };

  score = 0;
  for (let tag in work_check)
    score = score + (work_check[tag][territory[tag]] || 0);
  var work_score = score;

  const alliances_check = {
    alliances: { EDS: 5, PRD: 10, NAD: 15, PRA: 20, DAD: 25 },
    collaboration: { EDS: 5, PRD: 10, NAD: 15, PRA: 20, DAD: 25 },
    synergies: { EDS: 5, PRD: 10, NAD: 15, PRA: 20, DAD: 25 },
    convoke: { EDS: 5, PRD: 10, NAD: 15, PRA: 20, DAD: 25 },
  };

  score = 0;
  for (let tag in alliances_check)
    score = score + (alliances_check[tag][territory[tag]] || 0);
  var alliances_score = score;

  var org = {};
  //impacto está dando 0. Revisar
  org.solidez = Math.round(
    edad_organizacion * 0.2 + participation_score * 0.3 + challenge_score * 0.2 + impacto * 0.3
  );
  org.riesgo_externo = Math.round(country_score * 0.3 + work_score * 0.7);
  org.trayectoria = Math.round(org.solidez * 0.9 + org.riesgo_externo * 0.1);

  //c8h1 no está funcionando.
  org.funcionamiento = Math.round(
    organization_activity * 0.2 + organization_sustainability * 0.3 + c8h1 * 0.3 + eficiencia * 0.2
  );
  org.total = Math.round(
    org.funcionamiento * 0.5 + org.trayectoria * 0.4 + alliances_score * 0.1
  );

  return org;
};

export default getOrganizationScore;
