import React, { useEffect } from "react";

const ContactForm = ({ t }) => {

  function validateEmail5561283000000431936() {
    var form = document.forms["WebToLeads5561283000000431936"];
    var emailFld = form.querySelectorAll("[ftype=email]");
    var i;
    for (i = 0; i < emailFld.length; i++) {
      var emailVal = emailFld[i].value;
      if (emailVal.replace(/^\s+|\s+$/g, "").length != 0) {
        var atpos = emailVal.indexOf("@");
        var dotpos = emailVal.lastIndexOf(".");
        if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailVal.length) {
          alert("Introduzca una dirección de correo electrónico válida. ");
          emailFld[i].focus();
          return false;
        }
      }
    }
    return true;
  }

  function checkMandatory5561283000000431936() {
    var mndFileds = new Array("Company", "Last Name");
    var fldLangVal = new Array("Empresa", "Apellidos");
    for (i = 0; i < mndFileds.length; i++) {
      var fieldObj = document.forms["WebToLeads5561283000000431936"][mndFileds[i]];
      if (fieldObj) {
        if (fieldObj.value.replace(/^\s+|\s+$/g, "").length == 0) {
          if (fieldObj.type == "file") {
            alert("Seleccione un archivo para cargar.");
            fieldObj.focus();
            return false;
          }
          alert(fldLangVal[i] + " no puede estar vacío.");
          fieldObj.focus();
          return false;
        } else if (fieldObj.nodeName == "SELECT") {
          if (fieldObj.options[fieldObj.selectedIndex].value == "-None-") {
            alert(fldLangVal[i] + " no puede ser nulo.");
            fieldObj.focus();
            return false;
          }
        } else if (fieldObj.type == "checkbox") {
          if (fieldObj.checked == false) {
            alert("Please accept  " + fldLangVal[i]);
            fieldObj.focus();
            return false;
          }
        }
        try {
          if (fieldObj.name == "Last Name") {
            name = fieldObj.value;
          }
        } catch (e) {}
      }
    }
    if (!validateEmail5561283000000431936()) {
      return false;
    }
    document
      .querySelector(".crmWebToEntityForm .formsubmit")
      .setAttribute("disabled", true);
  }

  function tooltipShow5561283000000431936(el) {
    var tooltip = el.nextElementSibling;
    var tooltipDisplay = tooltip.style.display;
    if (tooltipDisplay == "none") {
      var allTooltip = document.getElementsByClassName("zcwf_tooltip_over");
      for (i = 0; i < allTooltip.length; i++) {
        allTooltip[i].style.display = "none";
      }
      tooltip.style.display = "block";
    } else {
      tooltip.style.display = "none";
    }
  }

  useEffect(() => {
    const script = document.createElement("script");

    script.id = "wf_anal";
    script.src =
      "https://crm.zohopublic.com/crm/WebFormAnalyticsServeServlet?rid=f325ddba1a42e33b2747eb0f5cdb4ce0a8d3dc7695edb451719e5ae23ae84efdgid932cae59a84e815e3939d6eb75752740a6e68e692b82d2f3b46b47d447ef16e4gid885e3c1045bd9bdcc91bdf30f82b5696gid14f4ec16431e0686150daa43f3210513&tw=61690b96c1d0471b638f31426f38e68aa67fb7ed6da86f32dc10ad817fe55a0a";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    //   <!-- Note :
    //  - You can modify the font style and form style to suit your website.
    //  - Code lines with comments Do not remove this code are required for the form to work properly, make sure that you do not remove these lines of code.
    //  - The Mandatory check script can modified as to suit your business needs.
    //  - It is important that you test the modified form before going live.-->
    <form
      className="sectionContact_form"
      action="https://crm.zoho.com/crm/WebToLeadForm"
      name="WebToLeads5561283000000431936"
      method="POST"
      onSubmit={() => {
        javascript: document.charset = "UTF-8";
        return checkMandatory5561283000000431936();
      }}
      acceptCharset="UTF-8"
    >
      <input
        type="text"
        style={{ display: "none" }}
        name="xnQsjsdp"
        defaultValue="932cae59a84e815e3939d6eb75752740a6e68e692b82d2f3b46b47d447ef16e4"
      ></input>
      <input type="hidden" name="zc_gad" id="zc_gad" defaultValue=""></input>
      <input
        type="text"
        style={{ display: "none" }}
        name="xmIwtLD"
        defaultValue="f325ddba1a42e33b2747eb0f5cdb4ce0a8d3dc7695edb451719e5ae23ae84efd"
      ></input>
      <input
        type="text"
        style={{ display: "none" }}
        name="actionType"
        defaultValue="TGVhZHM="
      ></input>
      <input
        type="text"
        style={{ display: "none" }}
        name="returnURL"
        defaultValue="https&#x3a;&#x2f;&#x2f;www.minka-dev.com&#x2f;"
      ></input>
      {/* <!-- Do not remove this code. --> */}
      <div className="zcwf_row">
        <div className="zcwf_col_fld">
          <input
            className="sectionContact_formInput"
            placeholder={t.contactUs_form_name}
            type="text"
            id="First_Name"
            name="First Name"
            maxLength="40"
            required
          ></input>
          <div className="zcwf_col_help"></div>
        </div>
      </div>
      <div className="zcwf_row">
        <div className="zcwf_col_fld">
          <input
            className="sectionContact_formInput"
            placeholder={t.contactUs_form_lname}
            type="text"
            id="Last_Name"
            name="Last Name"
            maxLength="80"
            required
          ></input>
          <div className="zcwf_col_help"></div>
        </div>
      </div>
      <div className="zcwf_row">
        <div className="zcwf_col_fld">
          <input
            className="sectionContact_formInput"
            placeholder={t.contactUs_form_position}
            type="text"
            id="LEADCF3"
            name="LEADCF3"
            maxLength="255"
            required
          ></input>
          <div className="zcwf_col_help"></div>
        </div>
      </div>
      <div className="zcwf_row">
        <div className="zcwf_col_fld">
          <input
            className="sectionContact_formInput"
            placeholder={t.contactUs_form_organization}
            type="text"
            id="Company"
            name="Company"
            maxLength="200"
            required
          ></input>
          <div className="zcwf_col_help"></div>
        </div>
      </div>
      <div className="zcwf_row">
        <div className="zcwf_col_fld">
          <select className="sectionContact_formSelect relative" id="LEADCF15" name="LEADCF15" required>
            <option value="">{t.contactUs_form_organization_country}</option>
            <option value="Afganist&aacute;n">Afganist&aacute;n</option>
            <option value="Albania">Albania</option>
            <option value="Alemania">Alemania</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Antigua&#x20;y&#x20;Barbuda">
              Antigua y Barbuda
            </option>
            <option value="Arabia&#x20;Saudita">Arabia Saudita</option>
            <option value="Argelia">Argelia</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaiy&aacute;n">Azerbaiy&aacute;n</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Banglad&eacute;s">Banglad&eacute;s</option>
            <option value="Barbados">Barbados</option>
            <option value="Bar&eacute;in">Bar&eacute;in</option>
            <option value="B&eacute;lgica">B&eacute;lgica</option>
            <option value="Belice">Belice</option>
            <option value="Ben&iacute;n">Ben&iacute;n</option>
            <option value="Bielorrusia">Bielorrusia</option>
            <option value="Birmania">Birmania</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia&#x20;y&#x20;Herzegovina">
              Bosnia y Herzegovina
            </option>
            <option value="Botsuana">Botsuana</option>
            <option value="Brasil">Brasil</option>
            <option value="Brun&eacute;i">Brun&eacute;i</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina&#x20;Faso">Burkina Faso</option>
            <option value="Burundi">Burundi</option>
            <option value="But&aacute;n">But&aacute;n</option>
            <option value="Cabo&#x20;Verde">Cabo Verde</option>
            <option value="Camboya">Camboya</option>
            <option value="Camer&uacute;n">Camer&uacute;n</option>
            <option value="Canad&aacute;">Canad&aacute;</option>
            <option value="Catar">Catar</option>
            <option value="Chad">Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Chipre">Chipre</option>
            <option value="Ciudad&#x20;del&#x20;Vaticano">
              Ciudad del Vaticano
            </option>
            <option value="Colombia">Colombia</option>
            <option value="Comoras">Comoras</option>
            <option value="Corea&#x20;del&#x20;Norte">Corea del Norte</option>
            <option value="Corea&#x20;del&#x20;Sur">Corea del Sur</option>
            <option value="Costa&#x20;de&#x20;Marfil">Costa de Marfil</option>
            <option value="Costa&#x20;Rica">Costa Rica</option>
            <option value="Croacia">Croacia</option>
            <option value="Cuba">Cuba</option>
            <option value="Dinamarca">Dinamarca</option>
            <option value="Dominica">Dominica</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egipto">Egipto</option>
            <option value="El&#x20;Salvador">El Salvador</option>
            <option value="Emiratos&#x20;&Aacute;rabes&#x20;Unidos">
              Emiratos &Aacute;rabes Unidos
            </option>
            <option value="Eritrea">Eritrea</option>
            <option value="Eslovaquia">Eslovaquia</option>
            <option value="Eslovenia">Eslovenia</option>
            <option value="Espa&ntilde;a">Espa&ntilde;a</option>
            <option value="Estados&#x20;Unidos">Estados Unidos</option>
            <option value="Estonia">Estonia</option>
            <option value="Etiop&iacute;a">Etiop&iacute;a</option>
            <option value="Filipinas">Filipinas</option>
            <option value="Finlandia">Finlandia</option>
            <option value="Fiyi">Fiyi</option>
            <option value="Francia">Francia</option>
            <option value="Gab&oacute;n">Gab&oacute;n</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Ghana">Ghana</option>
            <option value="Granada">Granada</option>
            <option value="Grecia">Grecia</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guyana">Guyana</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea&#x20;ecuatorial">Guinea ecuatorial</option>
            <option value="Guinea-Bis&aacute;u">Guinea-Bis&aacute;u</option>
            <option value="Hait&iacute;">Hait&iacute;</option>
            <option value="Honduras">Honduras</option>
            <option value="Hungr&iacute;a">Hungr&iacute;a</option>
            <option value="India">India</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Irak">Irak</option>
            <option value="Ir&aacute;n">Ir&aacute;n</option>
            <option value="Irlanda">Irlanda</option>
            <option value="Islandia">Islandia</option>
            <option value="Islas&#x20;Marshall">Islas Marshall</option>
            <option value="Islas&#x20;Salom&oacute;n">
              Islas Salom&oacute;n
            </option>
            <option value="Israel">Israel</option>
            <option value="Italia">Italia</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Jap&oacute;n">Jap&oacute;n</option>
            <option value="Jordania">Jordania</option>
            <option value="Kazajist&aacute;n">Kazajist&aacute;n</option>
            <option value="Kenia">Kenia</option>
            <option value="Kirguist&aacute;n">Kirguist&aacute;n</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Laos">Laos</option>
            <option value="Lesoto">Lesoto</option>
            <option value="Letonia">Letonia</option>
            <option value="L&iacute;bano">L&iacute;bano</option>
            <option value="Liberia">Liberia</option>
            <option value="Libia">Libia</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lituania">Lituania</option>
            <option value="Luxemburgo">Luxemburgo</option>
            <option value="Macedonia&#x20;del&#x20;Norte">
              Macedonia del Norte
            </option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malasia">Malasia</option>
            <option value="Malaui">Malaui</option>
            <option value="Maldivas">Maldivas</option>
            <option value="Mal&iacute;">Mal&iacute;</option>
            <option value="Malta">Malta</option>
            <option value="Marruecos">Marruecos</option>
            <option value="Mauricio">Mauricio</option>
            <option value="Mauritania">Mauritania</option>
            <option value="M&eacute;xico">M&eacute;xico</option>
            <option value="Micronesia">Micronesia</option>
            <option value="Moldavia">Moldavia</option>
            <option value="M&oacute;naco">M&oacute;naco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montenegro">Montenegro</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="N&iacute;ger">N&iacute;ger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Noruega">Noruega</option>
            <option value="Nueva&#x20;Zelanda">Nueva Zelanda</option>
            <option value="Om&aacute;n">Om&aacute;n</option>
            <option value="Pa&iacute;ses&#x20;Bajos">
              Pa&iacute;ses Bajos
            </option>
            <option value="Pakist&aacute;n">Pakist&aacute;n</option>
            <option value="Palaos">Palaos</option>
            <option value="Panam&aacute;">Panam&aacute;</option>
            <option value="Pap&uacute;a&#x20;Nueva&#x20;Guinea">
              Pap&uacute;a Nueva Guinea
            </option>
            <option value="Paraguay">Paraguay</option>
            <option value="Per&uacute;">Per&uacute;</option>
            <option value="Polonia">Polonia</option>
            <option value="Portugal">Portugal</option>
            <option value="Reino&#x20;Unido">Reino Unido</option>
            <option value="Rep&uacute;blica&#x20;Centroafricana">
              Rep&uacute;blica Centroafricana
            </option>
            <option value="Rep&uacute;blica&#x20;Checa">
              Rep&uacute;blica Checa
            </option>
            <option value="Rep&uacute;blica&#x20;del&#x20;Congo">
              Rep&uacute;blica del Congo
            </option>
            <option value="Rep&uacute;blica&#x20;Democr&aacute;tica&#x20;del&#x20;Congo">
              Rep&uacute;blica Democr&aacute;tica del Congo
            </option>
            <option value="Rep&uacute;blica&#x20;Dominicana">
              Rep&uacute;blica Dominicana
            </option>
            <option value="Ruanda">Ruanda</option>
            <option value="Ruman&iacute;a">Ruman&iacute;a</option>
            <option value="Rusia">Rusia</option>
            <option value="Samoa">Samoa</option>
            <option value="San&#x20;Crist&oacute;bal&#x20;y&#x20;Nieves">
              San Crist&oacute;bal y Nieves
            </option>
            <option value="San&#x20;Marino">San Marino</option>
            <option value="San&#x20;Vicente&#x20;y&#x20;las&#x20;Granadinas">
              San Vicente y las Granadinas
            </option>
            <option value="Santa&#x20;Luc&iacute;a">Santa Luc&iacute;a</option>
            <option value="Santo&#x20;Tom&eacute;&#x20;y&#x20;Pr&iacute;ncipe">
              Santo Tom&eacute; y Pr&iacute;ncipe
            </option>
            <option value="Senegal">Senegal</option>
            <option value="Serbia">Serbia</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra&#x20;Leona">Sierra Leona</option>
            <option value="Singapur">Singapur</option>
            <option value="Siria">Siria</option>
            <option value="Somalia">Somalia</option>
            <option value="Sri&#x20;Lanka">Sri Lanka</option>
            <option value="Suazilandia">Suazilandia</option>
            <option value="Sud&aacute;frica">Sud&aacute;frica</option>
            <option value="Sud&aacute;n">Sud&aacute;n</option>
            <option value="Sud&aacute;n&#x20;del&#x20;Sur">
              Sud&aacute;n del Sur
            </option>
            <option value="Suecia">Suecia</option>
            <option value="Suiza">Suiza</option>
            <option value="Surinam">Surinam</option>
            <option value="Tailandia">Tailandia</option>
            <option value="Tanzania">Tanzania</option>
            <option value="Tayikist&aacute;n">Tayikist&aacute;n</option>
            <option value="Timor&#x20;Oriental">Timor Oriental</option>
            <option value="Togo">Togo</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad&#x20;y&#x20;Tobago">
              Trinidad y Tobago
            </option>
            <option value="T&uacute;nez">T&uacute;nez</option>
            <option value="Turkmenist&aacute;n">Turkmenist&aacute;n</option>
            <option value="Turqu&iacute;a">Turqu&iacute;a</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Ucrania">Ucrania</option>
            <option value="Uganda">Uganda</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekist&aacute;n">Uzbekist&aacute;n</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Vietnam">Vietnam</option>
            <option value="Yemen">Yemen</option>
            <option value="Yibuti">Yibuti</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabue">Zimbabue</option>
          </select>
          <img alt="Arrow" src="/icons/arrowDropdown.png" />
          <div className="zcwf_col_help"></div>
        </div>
      </div>
      <div className="zcwf_row">
        <div className="zcwf_col_fld">
          <input
            className="sectionContact_formInput"
            placeholder={t.contactUs_form_email}
            type="text"
            ftype="email"
            id="Email"
            name="Email"
            maxLength="100"
            required
          ></input>
          <div className="zcwf_col_help"></div>
        </div>
      </div>
      <div className="zcwf_row wfrm_fld_dpNn">
        <div className="zcwf_coSelect zcwf_col_fld">
          <select className="sectionContact_formSelect" id="Lead_Status" name="Lead Status" required>
            <option value="">{t.contactUs_form_status}</option>
            <option value="Nuevo&#x20;prospecto">{t.contactUs_form_status_op1}</option>
            <option value="Intento&#x20;de&#x20;contacto">
              {t.contactUs_form_status_op2}
            </option>
            <option value="Contactar&#x20;en&#x20;el&#x20;futuro">
              {t.contactUs_form_status_op3}
            </option>
            <option value="Contactado">{t.contactUs_form_status_op4}</option>
            <option value="Posible&#x20;cliente&#x20;no&#x20;solicitado">
              {t.contactUs_form_status_op5}
            </option>
            <option value="Posible&#x20;cliente&#x20;perdido">
              {t.contactUs_form_status_op6}
            </option>
            <option value="No&#x20;contactado">{t.contactUs_form_status_op7}</option>
            <option value="Previamente&#x20;clasificado">
              {t.contactUs_form_status_op8}
            </option>
            <option value="No&#x20;habilitado">{t.contactUs_form_status_op9}</option>
          </select>
          <img alt="Arrow" src="/icons/arrowDropdown.png" />
          <div className="zcwf_col_help"></div>
        </div>
      </div>
      <div className="zcwf_row wfrm_fld_dpNn">
        <div className="zcwf_col_fld">
          <select className="sectionContact_formSelect" id="Lead_Source" name="Lead Source" required>
            <option value="">{t.contactUs_form_source}</option>
            <option value="Aviso">{t.contactUs_form_source_op1}</option>
            <option value="Recomendaci&oacute;n&#x20;de&#x20;empleado">
              {t.contactUs_form_source_op2}
            </option>
            <option value="Recomendaci&oacute;n&#x20;externa">
              {t.contactUs_form_source_op3}
            </option>
            <option value="Twitter">{t.contactUs_form_source_op4}</option>
            <option value="Facebook">{t.contactUs_form_source_op5}</option>
            <option value="Socio">{t.contactUs_form_source_op6}</option>
            <option value="Linkedin">{t.contactUs_form_source_op7}</option>
            <option value="Relaciones&#x20;p&uacute;blicas">
              {t.contactUs_form_source_op8}
            </option>
            <option value="Eventos">{t.contactUs_form_source_op9}</option>
            <option value="Formulario&#x20;Web">{t.contactUs_form_source_op10}</option>
            <option value="Chat&#x20;web">{t.contactUs_form_source_op11}</option>
            <option value="Marketplace">{t.contactUs_form_source_op12}</option>
          </select>
          <img alt="Arrow" src="/icons/arrowDropdown.png" />
          <div className="zcwf_col_help"></div>
        </div>
      </div>
      <div className="sectionContact_form-terms">
        <input type="checkbox" required/>
        <span>
          {t.contactUs_terms1} <a href="/privacy" target="_blank" rel="noopener noreferrer">{t.contactUs_terms2}</a>
        </span>
      </div>
      <div className="zcwf_row">
        <div className="zcwf_col_lab"></div>
        <div className="zcwf_col_fld">
          <input
            type="submit"
            id="formsubmit"
            className="contact_submit formsubmit zcwf_button"
            value={t.contactUs_send}
            title="Enviar"
          ></input>
          <input
            type="reset"
            className="contact_submit zcwf_button"
            name="reset"
            value="Reset"
            title="."
          ></input>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
