import React from 'react'
import styled from 'styled-components'

const ContentLoaderOpportunityCard = () => {
  return (
    <CardContent>
      <img src='/images2/graphics/contentLoaderCard.gif' alt='contentLoaderCard'/>
    </CardContent>
  )
}

export default ContentLoaderOpportunityCard

const CardContent = styled.div`
  display: flex;
	position: relative;
	flex-direction: column;
	width: 36.7rem;
	height: 67.8rem;
	cursor: pointer;
	font-family: var(--Roboto);
  img{
    width: 100%;
    height: 100%;
  }

	@media (max-width: 768px) {
		width: 234px;
		height: 433px;
	}
`