import React from 'react'
import GreenButton from './GreenButton'
import { styled } from 'styled-components'

const SectionBridgeMap = ({text, actionButton, buttonText}) => {
  return (
    <BridgeMap>
      <BridgeMapImg>
        <img src="/images2/graphics/bridgeMapResized.png" alt="map"/>
        <img src="/images2/graphics/coordIconResized.png" alt="icon" />
      </BridgeMapImg>
      <BridgeMapInfo>
        <BridgeMapInfoText dangerouslySetInnerHTML={{__html: text}}/>
        <GreenButton text={buttonText} action={actionButton} textTransform={"uppercase"}/>
      </BridgeMapInfo>
    </BridgeMap>
  )
}

const BridgeMap = styled.div`
  position: relative;
  height: auto;
  margin: 3% 0;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  @media only screen and (orientation: portrait) {
    align-items: center;
  }
`
const BridgeMapImg = styled.div`
  width: 50%;
  height: auto;
  background-position: top;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: -1;

  img:nth-child(1){
    max-width: 100%;
    max-height: 100%;
  }
  img:nth-child(2){
    position: absolute;
    width: 26%;
    opacity: 0.5;
    top: 0;
    left: 35.5%;
  }
  @media only screen and (orientation: portrait) {
    position: relative;
    height: auto;
    width: 100%;
    padding: 0 5%;
    img:nth-child(2){
      width: 20%;
      left: 40%;
    }
  }
`

const BridgeMapInfo = styled.div`
  width: 100%;
  max-width: 60%;
  margin: 0 0 5% 0;
  display: flex;
  flex-direction: column;
  @media only screen and (orientation: portrait) {
    max-width: 100%;
    align-items: center;
  }
`

const BridgeMapInfoText = styled.p`
  font-size: 3.6rem;
  font-weight: 600;
  margin: 0 0 5%;
  color: var(--darkPurple);
  
  span{
    color: var(--pink2);
  }
  @media only screen and (orientation: portrait) {
    margin: 0 0 10%;
  }
`

export default SectionBridgeMap