import React from "react";
import MinkaMap from "../layout/MinkaMap";
import MainCarouselBanner from "../styled_components/shared/MainCarouselBanner";
import { ViewContain } from "../styled_components/layout/ViewContain";
import { InfoText } from "../styled_components/shared/InfoText";
import { GreenButton } from "../styled_components/shared/GreenButton";
import { InfoTitle } from "../styled_components/shared/InfoTitle";
import { ServicesInfo, ServicesInfoCont, ServicesInfoDescription, ServicesInfoImg, ServicesInfoTitle } from "../styled_components/home/ServicesInfo";
import { BusinessInfo, BusinessInfoCont, BusinessInfoDescription, BusinessInfoTitle } from "../styled_components/home/BusinessInfo";
import NewsCarouselBanner from "../styled_components/shared/NewsCarouselBanner";

const Index = ({ texts, countries, bannerData, blogs, lang }) => {
  
  const bannerNewsData = blogs.map((blog)=>({title: blog?.title, banner_image: blog.logo || blog.image, title_color: blog.title_color, button_action: {text: texts.homepage_last_news_button, action: `/blogentries/${blog?.info_link || blog?.id}`}}))
  
  const businessInfo = [{image: "/images2/cards/green-business.webp", title: texts.homepage_business_info_1_title, text: texts.homepage_business_info_1_description}, { image: "/images2/cards/inclusive-business.webp", title: texts.homepage_business_info_2_title, text: texts.homepage_business_info_2_description}, { image: "/images2/cards/circle-business.webp", title: texts.homepage_business_info_3_title, text: texts.homepage_business_info_3_description}]
  
  const servicesInfo = [{image: "/images2/icons/diagnostic.webp", title: texts.homepage_services_info_1_title, text: texts.homepage_services_info_1_description}, { image: "/images2/icons/strategy.webp", title: texts.homepage_services_info_2_title, text: texts.homepage_services_info_2_description}, { image: "/images2/icons/implementation.webp", title: texts.homepage_services_info_3_title, text: texts.homepage_services_info_3_description}, { image: "/images2/icons/evaluation.webp", title: texts.homepage_services_info_4_title, text: texts.homepage_services_info_4_description}]

  const navigate = (url) => {
    window.location = url;
  };

  return (
    <>
      <MainCarouselBanner {...{ bannerData }}/>
      <ViewContain $paddingX={"10%"} $alignItems={"center"}>
        <InfoText $textAlign={"center"} $fontWeight={"600"} >
          {texts.homepage_info_1}
        </InfoText>
        <GreenButton $textTransform={"uppercase"} onClick={()=>navigate("/services")} $width={"54.8rem"} $height={"5.5rem"}>{texts.homepage_green_button}</GreenButton>
        <ViewContain $paddingY={"5%"} $alignItems={"center"}>
          <InfoTitle $textTransform={"uppercase"}>
            {texts.homepage_info_title_1}
          </InfoTitle>
          {servicesInfo.map((item, i)=>
            <ServicesInfo $index={i} key={"info"+i}>
              <ServicesInfoImg><img src={item.image} alt="icon" /></ServicesInfoImg>
              <ServicesInfoCont $index={i}>
                <ServicesInfoTitle>{item.title}</ServicesInfoTitle>
                <ServicesInfoDescription>{item.text}</ServicesInfoDescription>
              </ServicesInfoCont>
            </ServicesInfo>
          )}
          <ViewContain $paddingY={"3%"} $alignItems={"center"}>
            <GreenButton $textTransform={"uppercase"} onClick={()=>navigate("/services")} $width={"54.8rem"} $height={"5.5rem"}>{texts.homepage_services_button}</GreenButton>
          </ViewContain>
          <InfoTitle $textTransform={"uppercase"} dangerouslySetInnerHTML={{__html: texts.homepage_info_title_2}}/>
          {businessInfo.map((item, i)=>
            <BusinessInfo $image={item.image} key={"info"+i} >
              <BusinessInfoCont $index={i}>
                <BusinessInfoTitle dangerouslySetInnerHTML={{__html: item.title}}/>
                <BusinessInfoDescription>{item.text}</BusinessInfoDescription>
              </BusinessInfoCont>
            </BusinessInfo>
          )}
          <ViewContain $paddingY={"3%"} $alignItems={"center"}>
            <GreenButton $textTransform={"uppercase"} onClick={()=>navigate("/business")} $width={"54.8rem"} $height={"5.5rem"}>{texts.homepage_business_button}</GreenButton>
          </ViewContain>
        </ViewContain>
        <InfoTitle $textTransform={"uppercase"} dangerouslySetInnerHTML={{__html: texts.homepage_map_title}}/>
        <MinkaMap countries={countries} texts={texts} lang={lang}/>
        <ViewContain $paddingY={"3%"} $alignItems={"center"}>
          <GreenButton $textTransform={"uppercase"} onClick={()=>navigate("/business?section=projects")} $width={"54.8rem"} $height={"5.5rem"}>{texts.homepage_projects_button}</GreenButton>
        </ViewContain>
      </ViewContain>
      <ViewContain $paddingY={"5%"}>
        <InfoTitle $textTransform={"uppercase"}>
          {texts.homepage_last_news}
        </InfoTitle>
        <NewsCarouselBanner bannerData={bannerNewsData}/>
      </ViewContain>
    </>
  )
}

export default Index