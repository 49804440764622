import React, {useState, useContext, useEffect} from "react"
import {LangContext, translations} from "../Language"
import PlaceholderImage from "../shared/PlaceholderImage"
import RoundFlag from "../shared/RoundFlag"
import NormalButton from "../shared/NormalButton"
import Modal from "../shared/Modal"
import Loading from "../shared/Loading"
import api from "../api"
import { ViewContain } from "../styled_components/layout/ViewContain.js"
import InfoGreyCard from "../styled_components/shared/InfoGreyCard.js"

export default ({ data, c, form, selectedForm, saveForm, changeForm, formButtonText, formInfo }) =>{

  return(
    <ViewContain id={"layout"} $gap={"5rem"}>
      {data.map((item, i) =>
				<InfoGreyCard {...{i, c, item, form, selectedForm, saveForm, changeForm, formButtonText, formInfo}} key={"card"+i}>
          {item.component}
        </InfoGreyCard>
			)}
    </ViewContain>
  )
}

//view: sendContact (imgage) , modal (to open contactOrg)
export const OrganizationWidget = ({org, view = false, curId, curOrgId, currentUserOrg, opId}) => {
  const g = translations[useContext(LangContext)].general
  const [modal, setModal] = useState(false)
  const [message, setMessage] = useState("")
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  useEffect(() => {
    let to = setTimeout(() => {success&&setMessage("");success&&setModal(false)}, 5000)
    return () => clearTimeout(to)
  }, [success])

  const configuration = {
    message_empty: "message_empty",
    error_message: "error_message",
    success_message: "success_message",
  
    message_color: "green",
    error_color: "red",
  };

  const callError = (msg, info) => {
    setMessageAlert([msg, !info ? "error" : "message"]);
    setLoading(false);
  };

  const validate = () => {
    if (message === "") {
      callError(g[configuration.message_empty]);
      return false;
    }
    return true;
  };

  const processResponse = (res) => {
    if (!res.success || res.success === false) {
      setLoading(false);
      callError(g[configuration.error_message]);
    } else {
      callError(g[configuration.success_message],"info");
      setSuccess(true)
    }
  };

  const contactOp = () => {
    const information = {
      userId: curId,
      opId: opId,
      message: message,
    };
    api.contactOp(information, processResponse);
  };
  
  const handleSubmit = (e) => {
    /* Validations */
    if (loading) return;
    if (!validate(e)) return;
    /* After Validations */
    setMessageAlert(false)
    setLoading(true);
    /* API CALLS */
    contactOp();
  };

  return(
    <div className="organizationWidget">
      <div className="view_info-organizationName flex flex-align-center mb20">
          <div className="view_info-organizationFlag">
            <RoundFlag id={org.country} className={"roundSize"}/>
            {g.country[org.country]}
          </div>
        <div className="ml10">
          <h3 className="m0" >{org.name}</h3>
        </div>
      </div>
      <div className="flex flex-column">
        <div className="view_info-logo">
          <PlaceholderImage width={"auto"} height={"100%"} image={org.image_url?.includes("missing") ? null : org.image_url} />
        </div>
      </div>
      {view && curId && currentUserOrg && curId !== curOrgId &&
      <div className="organizationWidget_butt">
        <NormalButton id="contactButton" label={g.contact} purple bgColor={"var(--creamPurple)"} color={"var(--darkPurple)"} listener={()=>setModal(true)}/>
      </div>
      }
    <hr/>
    {modal &&
      <Modal title={g.contactTitle} initialOpen={true} close={()=>setModal(false)} width={"916px"} height={"491px"}>
        <div className="TextAreaForm">
          <div className="contact_message" visibility={messageAlert||loading?"true":"false"}>
            <h3
              className="contact-message"
              visibility={messageAlert?"true":"false"}
              style={{
                color:
                  messageAlert[1] === "error"
                    ? configuration.error_color
                    : configuration.message_color,
              }}
            >
              {messageAlert[0]}
            </h3>
            {loading && <Loading id="login_load" />}
          </div>
          <textarea className="textArea" value={message} placeholder={g.message} name="msg" onChange={(e) => setMessage(e.target.value)}/>
          <NormalButton id="send" label={g.sendMessage} listener={handleSubmit} />
        </div>
      </Modal>
    }
    </div>
  )
}
