import React, { Component} from 'react'
//import "./Temp.css"

//Requires flags folder in public and countries.js
class CountriesSelector extends Component{
	render(){
		return( 
			<div id="CountriesSelector">
			
			</div>
		)
	}
}

export default CountriesSelector;
