import { useState } from 'react'

export default (model, object) => {
	const [data, setData] = useState({ [model]: object })

	const saveData = (e, association) => {
		let value;
		if (e.target.type === "file") {
			value = e.target.files[0]
		} else {
			value = e.target.type === "checkbox" ? saveCheckbox(e.target, association) : (e.target.getAttribute("data-include") ? saveCheckAssociation(e.target) : save(e.target))
		}
		if (association) {
			setData(prev => {
				let p = { ...prev[model] }
				p[association][e.target.name] = value
				return { [model]: p }
			})
		} else {
			setData(prev => { return { [model]: { ...prev[model], [e.target.name]: value } } })
		}
	}

	const save = e => (e.value ?? e.getAttribute("value"))

	const saveCheckAssociation = e => {
		const field = e.name
		let value = { ...data[model][field] }
		const intVal = parseInt(e.value)
		const newValue = intVal > 100 ? 100 : (intVal < 0 ? 0 : intVal)
		value[e.getAttribute("data-include")] = newValue
		return value
	}

	const saveCheckbox = (e, association) => {
		if (e.getAttribute("data-boolean")) return e.checked
		const field = e.name
		const newValue = e.getAttribute("value")
		const include = e.getAttribute("data-include")
		let value
		const prevField = association ? data[model][association][field] : data[model][field]
		if (!include) {
			value = !prevField ? value = [] : [...prevField]
			if (e.checked) value.push(newValue)
			if (!e.checked) value = value.filter(e => e !== newValue)
		} else {
			value = !prevField ? value = {} : { ...prevField }
			if (e.checked) value[newValue] = 0
			if (!e.checked) delete value[newValue]
		}
		return value
	}

	const updateObject = (obj) => setData({ [model]: obj })

	return [data[model], saveData, updateObject]
}
