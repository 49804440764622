import React, { useEffect, useRef, useState } from "react";
import ViewProject from "./ViewProject";
import api from "../api";
import Loading from "../shared/Loading";

const SectionProjectsServices = ({ tr, lang, serviceTypes }) => {
  const [projects, setProjects] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const contentRef = useRef({});
  const leftRef = useRef({});
  const rightRef = useRef({});
  const itemRef = useRef({});

  const typesIndex = {
		diagnostic: "Diagnóstico",
		strategy: "Estrategia",
		evaluation: "Evaluación",
    identify: "Implementación - Identificar",
    connect: "Implementación - Conectar",
    structure: "Implementación - Estructurar",
    accompany: "Implementación - Acompañar",
	}

  useEffect(() => {
    setLoading(true);
    api.getProjects(
      (r) => {
        setLoading(false);
        setProjects((ser) => {
          return [...ser, ...r.results];
        });
      },
      page,
      100
      );
    }, []);

  useEffect(() => {
    const content = contentRef.current;
    const left = leftRef.current;
    const right = rightRef.current;
    const item = itemRef.current;
    let limit = content.scrollWidth;
    let scroll = content.scrollLeft;
    let offset = content.offsetWidth;

    if (scroll === 0) {
      leftRef.current.style.visibility = "hidden";
    }
    if (offset >= limit) {
        right.style.visibility = "hidden";
    }

    const handleScroll = () => {
      scroll = content.scrollLeft;
      let limitCont =
        window.innerWidth <= 768 ? scroll + item.offsetWidth : scroll + offset;
      if (limitCont >= limit) {
        rightRef.current.style.visibility = "hidden";
      } else {
        leftRef.current.style.visibility = "visible";
        rightRef.current.style.visibility = "visible";
      }
      if (scroll === 0) {
        leftRef.current.style.visibility = "hidden";
      }
    };
    const handleClick = (dir) => {
      if (dir === -1) {
        content.scrollLeft += -item.offsetWidth;
      } else {
        content.scrollLeft += item.offsetWidth;
      }
    };
    if (content && left && right) {
      content.addEventListener("scroll", handleScroll);
      left.addEventListener("click", () => {
        handleClick(-1);
      });
      right.addEventListener("click", () => {
        handleClick(1);
      });
    }
    return () => {
      content.removeEventListener("scroll", handleScroll);
      left.removeEventListener("click", () => {
        handleClick(-1);
      });
      right.removeEventListener("click", () => {
        handleClick(1);
      });
    };
  }, [projects]);
  
  const projectsFilter = Object.values(projects).filter((item) => {if (item["service_types"].includes(typesIndex[serviceTypes])) {return item}
  return false})

  return (
    <>
      {loading ? (
        <Loading id="loadService" />
      ) : (
        <div className="projects_cont">
          <img
            className="projects_arrow"
            src="/icons2/arrowLeft.png"
            alt="Left"
            ref={leftRef}
          />
          {projectsFilter && (
            <div className="projects_view" ref={contentRef}>
              {projectsFilter.map((e, i) => (
                <ViewProject
                  key={e.id + "ficha" + i}
                  lang={lang}
                  project={e}
                  tr={tr}
                  itemRef={itemRef}
                />
              ))}
            </div>
          )}
          <img
            className="projects_arrow"
            src="/icons2/arrowRight.png"
            alt="Right"
            ref={rightRef}
          />
        </div>
      )}
    </>
  );
};

export default SectionProjectsServices;
