import React, { useEffect, useRef, useState } from "react";
import ColorSection from "./ColorSection";
import Title from "./Title";

export default ({wallInfo}) => {
    const [mobile, setMobile] = useState(false)

    const contentRef = useRef({})
    const leftRef = useRef({})
    const rightRef = useRef({})
    const itemRef = useRef({})
    useEffect(() => {
        const content = contentRef.current
        const left = leftRef.current
        const right = rightRef.current
        const item = itemRef.current
        let limit = content.scrollWidth
        let scroll = content.scrollLeft
        let offset = content.offsetWidth
        if (scroll === 0){
            leftRef.current.style.visibility = "hidden"
        }
        const handleScroll=()=>{
            scroll = content.scrollLeft
            if((scroll+offset)>=limit){
                right.style.visibility = "hidden"
            }else{
                left.style.visibility = "visible"
                right.style.visibility = "visible"
            }
            if (scroll === 0){
                left.style.visibility = "hidden"
            }
        }
        const handleClick = (dir)=>{
            if (dir===-1) {
                content.scrollLeft += (-item.offsetWidth) + 70
            }else{
                content.scrollLeft += (item.offsetWidth) + 70
            }
        }
        if(content&&left&&right){
            content.addEventListener('scroll', handleScroll)
            left.addEventListener('click',()=>{handleClick(-1)})
            right.addEventListener('click',()=>{handleClick(1)})
        }
        return ()=> {
            content.removeEventListener('scroll',handleScroll)
            left.removeEventListener('click',()=>{handleClick(-1)})
            right.removeEventListener('click',()=>{handleClick(1)})
        }
    }, [])
    useEffect(() => {
      if (window.innerWidth<=768) {
          setMobile(true)
      }
    }, [])
  return (
    <div className="ourWall" >
        <div className="ourWall_title">
          <img
            className="ourWall_title-arrows"
            src={mobile?"/icons2/arrowLeft.png":"/icons2/arrowpurpleleft.png"}
            alt="Left"
            ref={leftRef}
          />
          <Title text="Nuestro Muro" margin={false}/>
          <img
            className="ourWall_title-arrows"
            src={mobile?"/icons2/arrowRight.png":"/icons2/arrowpurpleright.png"}
            alt="Right"
            ref={rightRef}
          />
        </div>
        <div className="ourWall_cont" ref={contentRef}>
          {wallInfo.map((info) => (
            <WallElement key={"wallInfo" + info.id} {...{ info }} itemRef={itemRef}/>
          ))}
        </div>
    </div>
  );
};

const WallElement = ({info, itemRef}) => {
	return (
		<div className="wallElement" ref={itemRef}>
      <img className="wallElement_corner" src="/icons2/cornerWhite.png" alt="" />
			<img className="wallElement_in" src="/icons2/linkidcomparte@2x.png" alt="LinkedIn"/>
			<div className="wallElement_cont">
				<img className="wallElement_quotes-init" src="/images2/graphics/comillasmoradas@2x.png" alt="quotes" />
				<div>{info.text}</div>
				<img className="wallElement_quotes-end" src="/images2/graphics/comillasmoradas@2x.png" alt="quotes" />
			</div>
		</div>
	)
}