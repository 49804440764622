import React from 'react'
import Title from './Title'

export default ({title, text, img, inv=false, url, linkText}) => {
    return (
        <div className="sectionInfo">
            <div className="sectionInfo_cont">
                <div className="sectionInfo_title">
                    <Title text={title} align="left"/>
                    <img src="/icons2/semicirculo@2x.png" alt="circle" />
                </div>
                <div className="sectionInfo_text">
                    {text}
                </div>
                <a href={url}>{linkText}</a>
            </div>
            <img className={`sectionInfo_img ${inv?"sectionInfo_img--inverso":""}`} src={img} alt=""/>
        </div>
    )
}
