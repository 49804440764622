//props.data = [{label: "Label", component: <Component/>,]

import React from 'react'
import Widget from "./Widget"

export default ({ data }) => {

	return (
		<div id="Accordion">
			{data.map((item, i) =>
				<div className={"BorderedWidget relative"} key={i}>
					{i+1 + ". " + item.label}
					{item.component}
				</div>
			)}
		</div>
	)
}