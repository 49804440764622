import React, { useEffect, useState } from "react";
import MainCarouselBanner from "../styled_components/shared/MainCarouselBanner";
import { ViewContain } from "../styled_components/layout/ViewContain";
import { InfoTitle } from "../styled_components/shared/InfoTitle";
import BusinessInfoCard from "../styled_components/business/BusinessInfoCard";
import { InfoText } from "../styled_components/shared/InfoText";
// import BusinessFilter from "../styled_components/business/BusinessFilter";
import LoadingBars from "../styled_components/shared/LoadingBars";
// import api from "../api";
import ProjectCard from "../styled_components/business/ProjectCard";

const Index = ({ texts, projects, bannerData }) => {
  const [loading, setLoading] = useState(false)
  // const [projects, setProjects] = useState([])
  const [page, setPage] = useState(1)

  const businessInfo = [{image: "/images2/cards/green-business.webp", title: texts.business_type_1_title, description: texts.business_type_1_description, data: [texts.business_type_1_data_1, texts.business_type_1_data_2, texts.business_type_1_data_3, texts.business_type_1_data_4]}, { image: "/images2/cards/inclusive-business.webp", title: texts.business_type_2_title, description: texts.business_type_2_description, data: [texts.business_type_2_data_1, texts.business_type_2_data_2, texts.business_type_2_data_3]}, { image: "/images2/cards/circle-business.webp", title: texts.business_type_3_title, description: texts.business_type_3_description, data: [texts.business_type_3_data_1, texts.business_type_3_data_2, texts.business_type_3_data_3]}]

  // useEffect(() => { //pending for filter
  //   setLoading(true);
  //   api.getProjects(
  //     (r) => {
  //       setLoading(false);
  //       setProjects((ser) => {
  //         if (r?.results) {
  //           return [...ser, ...r?.results];
  //         }else return []
  //       });
  //     },
  //     page,
  //     100
  //   );
  // }, []);

  useEffect(() => {
    const urlActual = window.location.href;
    const url = new URL(urlActual);
    const parametros = new URLSearchParams(url.search);
    const section = parametros.get('section');

    if (section === "projects") {
      const element = document.getElementById("project_list");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [])
  

  return (
    <>
      <MainCarouselBanner {...{ bannerData }} smallText/>
      <ViewContain $paddingX={"10%"} $alignItems={"center"}>
        <ViewContain $paddingY={"5%"}>
          <InfoTitle dangerouslySetInnerHTML={{__html: texts.business_title_1}} />
          <InfoText dangerouslySetInnerHTML={{__html: texts.business_info_1}} $textAlign={"center"} $fontSize={"3.5rem"} $margin={"5rem 0"}/>
        <InfoTitle dangerouslySetInnerHTML={{__html: texts.business_title_2}} $textTransform={"uppercase"} />
        </ViewContain>
        {businessInfo.map((business, i)=>
          <BusinessInfoCard {...{text: texts, business}} key={"business"+i}/>
        )}
      </ViewContain>
      <ViewContain $paddingY={"5%"} $paddingX={"5%"} id="project_list">
        {/* <BusinessFilter title={text.business_filter_title}/> */}
        <InfoTitle dangerouslySetInnerHTML={{__html: texts.business_filter_title}} />
      </ViewContain>
      <ViewContain $flexDirection={"row"} $flexWrap={"wrap"} $justifyContent={"center"} $gap={"10rem"} $paddingX={"5%"} $alignItems="center">
        {loading || projects?.length === 0 ? 
          <ViewContain $alignItems={"center"}><LoadingBars/></ViewContain>
        : 
          projects.map((project)=>
          <ProjectCard {...{project, text: texts}} key={"project"+project.id}/>
        )}
      </ViewContain>
      <ViewContain $paddingY={"5%"}/>
    </>
  )
}

export default Index