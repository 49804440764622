import React from "react"
import { ViewContain } from "../styled_components/layout/ViewContain.js"
import InfoGreyCard from "../styled_components/shared/InfoGreyCard.js"

export default ({ data, c, form, selectedForm, saveForm, changeForm}) => (
  <div id="ProposalLayout">
    <ViewContain $gap={"5rem"} $paddingY={"5%"}>
      {data.map((item, i) =>
				<InfoGreyCard {...{i, c, item, form, selectedForm, saveForm, changeForm}} key={"card"+i}>
          {item.component}
        </InfoGreyCard>
			)}
    </ViewContain>
  </div>
)