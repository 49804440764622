import React from "react";
import BridgeBanner from "../layout/BridgeBanner";
import SectionBridgeMap from "../layout/SectionBridgeMap";
import BridgeInfo from "./BridgeInfo";
import GreenButton from "../layout/GreenButton";
import { ViewContain } from "../styled_components/layout/ViewContain";
import styled from "styled-components";
import { InfoTitle } from "../styled_components/shared/InfoTitle";

const icons = {
  imginfo1: "/images2/svg/information.svg",
  imginfo2: "/images2/svg/evaluation.svg",
  imginfo3: "/images2/svg/register.svg",
  imginfo4: "/images2/svg/contact.svg",
  imginfo5: "/images2/svg/ai.svg",
};

const Bridge = ({ texts }) => {

  const contactButton = () => {
    let sectionContact = document.getElementById("contact");
    sectionContact.scrollIntoView({ behavior: "smooth" });
  };
  
  const bannerData = {
    banner_image: "/images2/banners/Bridge2ZeroBanner.png",
    banner_title: "Bridge<span>2</span>Zero",
    banner_text1: texts.bridge_banner_info_1,
    banner_text2: texts.bridge_banner_info_2,
    text_button: texts.bridge_text_button,
    button_action: contactButton,
  }

  return (
    <>
      <BridgeBanner {...{bannerData}}/>
      <ViewContain $alignItems={"center"} $paddingX={"10%"} $paddingY={"5%"}>
        <BridgeIntroText className="bridge_intro-text" dangerouslySetInnerHTML={{ __html: texts.bridge_intro_text }} />
        <ViewContain $paddingY={"5%"}>
          <InfoTitle dangerouslySetInnerHTML={{ __html: texts.bridge_map_title }}/>
          <SectionBridgeMap text={texts.bridge_map_description} actionButton={contactButton} buttonText={texts.bridge_text_button}/>
        <InfoTitle>
          {texts.bridge_info_title} Bridge<span style={{color: "#7DA03C", fontWeight: "600"}}>2</span>Zero?
        </InfoTitle>
        </ViewContain>
        <BridgeInfo text1={texts.bridge_info_text_1} text2={texts.bridge_info_text_2} icon1={icons.imginfo1} icon2={icons.imginfo2} />
        <BridgeInfo text1={texts.bridge_info_text_3} text2={texts.bridge_info_text_4} icon1={icons.imginfo3} icon2={icons.imginfo4} />
        <BridgeInfo text1={texts.bridge_info_text_5} icon1={icons.imginfo5} />
        <ViewContain $paddingY={"5%"}>
          <BridgeRegulationCont>
            <BridgeRegulationText dangerouslySetInnerHTML={{ __html: texts.bridge_regulation_text }} />
            <GreenButton text={texts.bridge_text_button} action={contactButton} textTransform={"uppercase"}/>
          </BridgeRegulationCont>
        </ViewContain>
      </ViewContain>
    </>
  );
};

const BridgeIntroText = styled.div`
  margin: 3% 0 0 0;
  font-size: 3.6rem;
  font-weight: 600;
  color: var(--darkPurple);
  text-align: justify;

  span{
    color: var(--pink2);
  }
`

const BridgeRegulationCont = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media only screen and (orientation: portrait) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5%;
    text-align: justify;
  }
`
const BridgeRegulationText = styled.div`
  flex: 1;
  font-size: 3rem;
  font-weight: 300;
  color: var(--darkPurple);
  margin: 0 5% 5% 0;
`

export default Bridge;
