import React, {useState, useEffect, useRef, useContext} from 'react'
import {LangContext, translations} from "../Language"

var timer;
export default ({testimonies, lang}) =>{
	const [index, setIndex] = useState(0)
	const l = useContext(LangContext)
	const savedCallback = useRef();

	useEffect(()=>{savedCallback.current = loop})
	useEffect(()=>{
		timer = setInterval(()=>{savedCallback.current(1)}, 8000)
		return ()=> clearInterval(timer)
	}, [])

	const click = (qty) => {clearInterval(timer); loop(qty)}
	const loop = function(qty){
		let newQty = index + qty
		if(testimonies[newQty]){setIndex(newQty);return}
		setIndex(qty === 1 ? 0 : testimonies.length - 1)
	}

	if(!testimonies[index]) return <div></div>

	return( 
		<div className="Testimonials flex flex-justify">
			<div><img src="../icons/arrowLeft.png" onClick={()=> click(-1)} alt="Left"/></div>
			<div className="contTestimonials flex flex-justify">
				<div className="photo"><img src={testimonies[index].photo} alt="Photo"/></div>
				<div className="nameTestimonial">{testimonies[index].name}</div>
				<div className="nameTestimonial">{testimonies[index].position[l]}</div>
				<div className="commentary flex">
					<div className="marksLeft"><img src="../icons/marksLeft.png" alt="markLeft"/></div>				
					<div className="comment">{testimonies[index].description[l]}</div>
					<div className="marksRight"><img src="../icons/marksRight.png" alt="Right"/></div>	
				</div>
			</div>
			<div><img src="../icons/arrowRight.png" onClick={()=> click(1)} alt=""/></div>
		</div>
	)
}

