import React, { useContext } from 'react'
import { LangContext, translations } from "../Language"
import Flag from '../shared/RoundFlag'

export default ({ proposal }) => {
	const l = useContext(LangContext)
	return (
		<div className="fichaProposal">
			<img className="fichaProposal_img" src={proposal.image} alt="" />
			<div className="fichaProposal_sector">SECTOR: {proposal.sector}</div>
			<div className="fichaProposal_cont">
				<div className="fichaProposal_name">{proposal.title}</div>
				<div className="fichaProposal_beneficiary">
					<img src="/icons2/beneficiarios@2x.png" alt="img" />
					<div>Beneficiarios: {proposal.nb_affected}</div>
				</div>
				<div className="fichaProposal_plus">
					<Flag id={proposal.organization.country}/>
					{proposal.plus && <img className="fichaProposal_plus-img" src="/icons2/plus@2x.png" alt="plus" />}
				</div>
			</div>
			{proposal.selected && <div className="fichaProposal--selected">{translations[l].general.selected}</div>}
		</div>
	)
}
