import React from "react";
import Controller from "./Controller";

export default function Show({ proposal, lang, currentUser, opportunityUser }) {
  return (
    <Controller
      {...{ proposal, lang, currentUser, opportunityUser }}
      fromShowComponent={true}
    />
  );
}
