import React, { useContext, useState } from 'react'
import styled from "styled-components";
import { LangContext, translations } from "../../Language"
import { aboutUsTexts } from '../../utils/newTexts';


const WorkTeamCard = ({ member }) => {
	const [showDescription, setShowDescription] = useState(false)

	const lang = useContext(LangContext)
	
	const handleShowDescription = (e) => {
		setShowDescription(!showDescription)
	}

	return (
		<Card>
			<CardImgCont>
        <CardImg src={member.photo} alt="image"/>
        <CardSocialLink href={member.li} target="_blank">
          <CardSocialImg src={"/images2/svg/linkedin.svg"}/>
        </CardSocialLink>
      </CardImgCont>
			<CardInfoCont>
				<CardMemberInfo>
          <CardName>{member.name}</CardName>
          <CardPosition>{member.position[lang]}</CardPosition>
        </CardMemberInfo>
        <CardMoreInfo onClick={()=>setShowDescription(true)}>{aboutUsTexts.about_us_work_team_card_read} +</CardMoreInfo>
			</CardInfoCont>
			<CardDescription $showCard={showDescription} onClick={()=>setShowDescription(false)}>
        <CardDescriptionText>{member?.description[lang]}</CardDescriptionText>
      </CardDescription>
		</Card>
	)
}

export default WorkTeamCard

//Styles
const Card = styled.div`
  display: flex;
	position: relative;
	flex-direction: column;
  align-items: center;
  justify-content: center;
	width: 56.2rem;
	height: 65.7rem;
	font-family: var(--Roboto);
`

const CardImgCont = styled.div`
  position: relative;
  width: 30rem;
  height: 30rem;
  display: flex;
`
const CardImg = styled.img`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  object-fit: cover;
  border-radius: 50%;
`

const CardSocialLink = styled.a`
	position: absolute;
	bottom: 2%;
  right: -4rem;
	width: 5.4rem;
	height: 5.4rem;
`
const CardSocialImg = styled.img`
  width: 100%;
  height: 100%;
`

const CardInfoCont = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #361A3C;
  padding: 7%;
  position: relative;
  font-family: var(--BarlowCondensed);
`
const CardMemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
const CardName = styled.h3`
	font-size: 5rem;
  font-weight: 600;
	margin: 0;
`
const CardPosition = styled.h4`
  font-size: 4rem;
  font-weight: 600;
	margin: 0;
`

const CardMoreInfo = styled.button`
  background-color: transparent;
  appearance: none;
  border: none;
  position: relative;
  align-self: flex-end;
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
`

const CardDescription = styled.div`
  position: absolute;
  visibility: ${({$showCard}) => $showCard ? 'visible' : 'hidden'};
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	background-color: #000000b3;
	z-index: 1;
  overflow: hidden;
  padding: 8% 6%;
`

const CardDescriptionText = styled.div`
  font-size: 3rem;
  font-weight: 400;
  color: #fff;
  overflow: auto;
`