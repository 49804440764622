import styled from "styled-components";

export const ServicesInfo = styled.div`
  width: 100%;
  max-height: 25.6rem;
  display: flex;
  padding: 2rem 5rem;
  gap: 3rem;
  user-select: none;
  
  @media (max-width: 900px) {
    padding: 2rem 2rem
  }
  @media (max-width: 500px) {
    max-height: max-content;
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 5rem;
    text-align: center;
  }
`

export const ServicesInfoImg = styled.div`
  max-width: 21.4rem;
  max-height: 21.4rem;
  border-radius: 50%;
  img{
    max-width: 100%;
    max-height: 100%;
  }
`

export const ServicesInfoCont = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  position: relative;
  color: var(--darkPurple);
`

export const ServicesInfoTitle = styled.span`
  position: relative;
  font-family: var(--BarlowCondensed);
  font-size: 5rem;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 2rem;

  @media (max-width: 440px) {
    text-align: center;
  }
  `

export const ServicesInfoDescription = styled.span`
  position: relative;
  font-family: var(--Roboto);
  font-size: 3rem;
  font-weight: 300;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    font-size: 2.5rem;
  }
`