import React from "react";
import MainCarouselBanner from "../styled_components/shared/MainCarouselBanner";
import { ViewContain } from "../styled_components/layout/ViewContain";
import { GreenButton } from "../styled_components/shared/GreenButton";
import { InfoTitle } from "../styled_components/shared/InfoTitle";
import ServicesInfoCard from "../styled_components/services/ServicesInfoCard";

const Index = ({ texts, bannerData }) => {
  const servicesInfo = [{image: "/images2/icons/diagnostic.webp", title: texts.services_info_1_title, text: texts.services_info_1_description, target: texts.services_info_1_target, actions: [texts.services_info_1_action_1, texts.services_info_1_action_2, texts.services_info_1_action_3]}, { image: "/images2/icons/strategy.webp", title: texts.services_info_2_title, text: texts.services_info_2_description, target: texts.services_info_2_target, actions:[texts.services_info_2_action_1, texts.services_info_2_action_2, texts.services_info_2_action_3, texts.services_info_2_action_4]}, { image: "/images2/icons/implementation.webp", title: texts.services_info_3_title, text: texts.services_info_3_description, target: texts.services_info_3_target}, { image: "/images2/icons/evaluation.webp", title: texts.services_info_4_title, text: texts.services_info_4_description, target: texts.services_info_4_target, actions:[texts.services_info_4_action_1, texts.services_info_4_action_2, texts.services_info_4_action_3, texts.services_info_4_action_4]}]
  
  const implementationActions = [{title: texts.services_implementation_1_title, description: texts.services_implementation_1_description, actions: [texts.services_implementation_1_action_1, texts.services_implementation_1_action_2, texts.services_implementation_1_action_3, texts.services_implementation_1_action_4]},{title: texts.services_implementation_2_title, description: texts.services_implementation_2_description, actions: [texts.services_implementation_2_action_1, texts.services_implementation_2_action_2, texts.services_implementation_2_action_3, texts.services_implementation_2_action_4]},{title: texts.services_implementation_3_title, description: texts.services_implementation_3_description, actions: [texts.services_implementation_3_action_1, texts.services_implementation_3_action_2, texts.services_implementation_3_action_3, texts.services_implementation_3_action_4]},{title: texts.services_implementation_4_title, description: texts.services_implementation_4_description, actions: [texts.services_implementation_4_action_1, texts.services_implementation_4_action_2, texts.services_implementation_4_action_3, texts.services_implementation_4_action_4]}]

  return (
    <>
      <MainCarouselBanner {...{ bannerData }} smallText/>
      <ViewContain $paddingY={"5%"} $alignItems={"center"}>
				<ViewContain $paddingX={"10%"} $alignItems={"center"}>
          <InfoTitle $textTransform={"uppercase"}>
            {texts.services_info_title_1}
          </InfoTitle>
        </ViewContain>
        <ViewContain $alignItems={"center"} $gap={"1rem"}>
					{servicesInfo.map((service, i)=>
						<ServicesInfoCard {...{text: texts, service, i, implementationActions }} key={"Card"+i}/>
					)}
        </ViewContain>
      </ViewContain>
    </>
  )
}

export default Index