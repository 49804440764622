export default {
	//api
	getFilteredMap: (selected, listener) => call("GET", `api/selected/${selected}`, {},  listener ),
	getProjects: (listener, page, cant) => call("GET", "api/projects", { page: page, cant: cant }, listener),
	getSectors: (listener, getOnly) => call("GET", "api/sectors", { getOnly }, listener),
	login: (data, listener) => call("POST", "api/login", data, listener),
	contact: (data, listener) => call("POST", "api/contact", data, listener),
	contactOp: (data, listener) => call("POST", "api/contactOp", data, listener),
	register: (data, listener) => call("POST", "api/register", data, listener),
	update: (data, listener, id) => call("PUT", "api/updateUser/"+id, data, listener),
	forgotPass: (data, listener) => call("POST", "api/forgotPass", data, listener),
	updatePassword: (data, listener) => call("PUT", "api/updatePassword/", data, listener),
	logOut: (listener) => call("DELETE", "api/logout", {}, listener),
	getSelectors: (lang, listener) => call("GET", "api/selectors", { lang: lang }, listener),
	getOtherNews: (listener, page) => call("GET", `api/blogentries`, { page }, listener),

	//opportunities
	getOpportunities: (data, listener, page) => call("GET", "opportunities", {...data, page }, listener),
	getUserOpportunities: (data, listener, page) => call("GET", "opportunities/user_opp", {...data, page }, listener),
	saveSubSector: (data, listener) => call("POST", "opportunities/sub_sectors", { ...data }, listener),
	changeType: (data, listener) => call("POST", "opportunities/type", { ...data }, listener),
	postOpOds: (data, listener) => call("POST", "opportunities/ods", { ...data }, listener),
	closeOpportunity: (data, listener) => call("POST", "opportunities/close", { ...data }, listener),
	upload: (model, upload_type, file, data, listener) => call("POST", `uploads/${upload_type}/${model}`, { ...data, file }, listener),
	destroyFile: (model, upload_type, id, file_id, listener) => call("DELETE", `uploads/${upload_type}/${model}/destroy`, { id, file_id }, listener),
	score: (id, agent_organization, scores, model, listener) => call("POST", `${model}/score`, { id, agent_organization, scores }, listener),

	//New objects general
	new: (controller, listener) => call("GET", `${controller}/new`, {}, listener),
	save: (object, controller, listener) => call("PUT", `${controller}/save/${object.id}`, { data: JSON.stringify(object) }, listener),
	publish: (id, controller, listener) => call("PUT", `${controller}/${id}`, {controller}, listener),
	create: (object, images = {}, controller, listener) => {
		const params = { data: JSON.stringify(object), images }
		const c = object.id ? { url: `${controller}/${object.id}`, method: "PUT" } : { url: controller, method: "POST" }
		return call(c.method, c.url, params, listener)
	},
}


const setParams = function (data, get) {
	if (!get) data = { ...data, authenticity_token: document.querySelector('meta[name="csrf-token"]').content }
	var formData = get ? {} : new FormData();
	var checkNested = function (fD, key, value) {
		const insertField = (k, v) => get ? fD[k] = v : fD.append(k, v)

		if (typeof value != "object") return insertField(key, value)
		if (Array.isArray(value)) return insertField(key, value.join(","))
		if (key === "images" && !get) { for (let k in value) fD.append(k, value[k], value[k].name); return }
		// console.log(key, value)
		if (key === "file" && !get) { fD.append(key, value, value.name); return }
		for (var obj in value) checkNested(fD, `${key}[${obj}]`, value[obj]);
	}
	for (var obj in data) checkNested(formData, obj, data[obj])
	return formData;
}

const setDefaults = function (data = {}, defaults = {}) { for (var key in defaults) data[key] = defaults[key]; return data; }

const call = function (method, services, data, listener) {
	const formData = setParams(data, method === "GET")
	let url = window.location.origin
	let service = `/middleware/${services}`
	// console.log(method, service, formData)
	//Set Headers
	var myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");

	var miInit = { method: method, headers: myHeaders, mode: 'cors', cache: 'default' };
	//Include formData in body if post or put
	if (formData && (method === "POST" || method === "PUT" || method === "DELETE")) {
		//console.log('miInit =>', miInit); 
		formData.append("noEmpty", "true") //avoid multipart errors
		miInit.body = formData;
	}
	//Call
	function middlewareListener(data) {
		if (listener != null && listener !== undefined) { listener(data) } else { console.log("Middleware data:", data) }
	}
	var built = new URL(url + service)
	if (method === "GET") Object.keys(formData).forEach(key => built.searchParams.append(key, formData[key]))
	const controller = new AbortController()

	fetch(built, { ...miInit, signal: controller.signal })
		.then(response => response.json()).then((data) => middlewareListener(data))
		.catch(err => console.log("Api error:", err))

	return controller
}
