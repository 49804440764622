// height, interval
import React, { useEffect, useRef, useState } from "react";

export default ({ interval = 5000, text={}, images, title, staticText, icon }) => {
  const [currentBannerSlide, setCurrentBannerSlide] = useState(0);
  
  const bannerText = Object.entries(text).filter((item) => {
    if (item[0].includes("banner_text")) {
      return item
    }
    return false
  })

  useEffect(() => {
    let banner = document.querySelector(".banner");
    let slides = document.getElementsByClassName("banner_slide");
    let indicators = document.getElementsByClassName("banner_indicator");
    let text = document.getElementsByClassName("banner_text");
    let currentSlide = currentBannerSlide;
    let slideInterval = setInterval(() => nextSlide(), interval);

    const handleClick = (i) => {
      currentSlide = i-1
      nextSlide(i)
    }
    
    const nextSlide = () => {
      for (let i = 0; i < slides.length; i++) {
        slides[i].classList.remove("banner_show");
        indicators.length!==0&&indicators[i].classList.remove("banner_indicator_selected");
        text.length!==0&&text[i].classList.remove("text_show")
      }
      currentSlide = (currentSlide + 1) % slides.length;
      slides[currentSlide]?.classList.add("banner_show");
      indicators.length!==0&&indicators[currentSlide].classList.add("banner_indicator_selected");
      text.length!==0&&text[currentSlide].classList.add("text_show")
      setCurrentBannerSlide(currentSlide);
    };
    
    if (currentBannerSlide===0) {
      slides[currentSlide].classList.add("banner_show");
      indicators.length!==0&&indicators[currentSlide].classList.add("banner_indicator_selected");
      text.length!==0&&text[currentSlide].classList.add("text_show")
    }
  
  banner?.addEventListener("click",nextSlide)
  
  for (let i = 0; i < slides.length; i++){
    indicators.length!==0&&indicators[i].addEventListener("click", ()=> handleClick(i))
  }
  
	return () => {
    clearInterval(slideInterval);
    banner.removeEventListener("click",nextSlide)
    for (let i = 0; i < slides.length; i++){
      indicators.length!==0&&indicators[i].removeEventListener("click", handleClick)
    }
    };
}, [currentBannerSlide]);

  return (
    <div className={"banner"}>
      <div className="banner_cont">
        {images.map((image, i) => (
          <div
            key={"imageBanner" + i}
            className="banner_slide"
            style={{ backgroundImage: "url(" + image[1] + ")" }}
          ></div>
        ))}
      </div>
      {icon&&<img src={icon} alt="icon"/>}
      {title && 
        <h3 className="banner_title">{title}</h3>
      }
      {staticText &&
        <span className="banner_sText">{staticText}</span>}
      {text && 
      bannerText.map((text,i)=>
        <p className="banner_text" key={"bannerText"+i}>{text[1]}</p>
      )}
      {!staticText&&
        <div className="banner_indicators">
          {images.map((image, i) => (
            <div
              key={"indicator" + i}
              className="banner_indicator"
            />
          ))}
        </div>}
    </div>
  );
};
