import React from 'react'
import styled from 'styled-components'

const RoundFlag = ({ id, width, height }) => {
	return (
		<RoundFlagCont id={id} style={{width, height}}>
		{id && <img alt={id} src={`/images/flags/${id.toLowerCase()}.svg`}/>}
		</RoundFlagCont>
	)
}

const RoundFlagCont = styled.div`
	display: inline-block;
	background-color: var(--grey1);
	border-radius: 100% !important;
	-moz-border-radius: 100% !important;
	-o-border-radius: 100% !important;
	-ms-border-radius: 100% !important;
  overflow:hidden;
	width:4rem;
	height:4rem;
	img{
		width: 100%;
		height: 100%;
	}
`

export default RoundFlag