import React from 'react'

export default ({text, title, image, publication_date, news, itemRef, info_link, id}) =>{
	const regex = /(<([^>]+)>)/ig;
	return( 
		<div className="fichaArticle" ref={itemRef}>
			<img className="fichaArticle_img" src={image} alt="ArticleImg"/>
			<div className="fichaArticle_date" style={!news?{backgroundColor:"white"}:{}}>{publication_date}
			</div>
			<div className="fichaArticle_new">
				<div className="fichaArticle_new-cont">
					<div className="fichaArticle_new-title">{title}</div>
					<div className="fichaArticle_new-text">{(text.replace(/<br>/gi, "").substring(0, 150) + "...").replace(regex, '')}</div>
				</div>
				<a href={`/blogentries/${id}`}>Ver más</a>
			</div>
		</div>
	)
}