import React, { useContext } from 'react'
import SimpleButton from "../shared/SimpleButton"
import { LangContext, translations } from "../Language"
import Title from './Title'
import NormalButton from '../shared/NormalButton'

function ModalPost(props) {
  const score = props.score
  const l = useContext(LangContext)
  const m = translations[l].modals.modalPost
  return (
    <div className="modalPost">
      <Title text={m.congratulations} axiforma/>
      <div className="modalPost_cont">
        <div className="modalPost_subt">{m.vinculation}</div>
        <br/>
        <div className="paragraph">{m.analitic}</div>
        <br/>
        <div className="paragraph">{m.evaluation}</div>
        <ul className="modalPost_list">
          <li className="list">{m.track}</li>
          <li className="list">{m.impact}</li>
          <li className="list">{m.model}</li>
          <li className="list">{m.capacity}</li>
          <li className="list">{m.viability}</li>
          <li className="list">{m.capacityReprecentation}</li>
        </ul>
        <div className="modalPost_statistics">
          <div className="info">
            <div className="dato">Solidez: {score.solidez}%</div>
            <div className="percentage">
              <div className="percentage1" style={{ width: score.solidez + "%" }} />
            </div>
          </div>
          <div className="info">
            <div className="dato">Trayectoria: {score.trayectoria}%</div>
            <div className="percentage">
              <div className="percentage1" style={{ width: score.trayectoria + "%" }} />
            </div>
          </div>
          <div className="info">
            <div className="dato">Riesgo externo: {score.riesgo_externo}%</div>
            <div className="percentage">
              <div className="percentage1" style={{ width: score.riesgo_externo + "%" }} />
            </div>
          </div>
          <div className="info">
            <div className="dato">funcionamiento: {score.funcionamiento}%</div>
            <div className="percentage">
              <div className="percentage1" style={{ width: score.funcionamiento + "%" }} />
            </div>
          </div>
          <div className="info">
              <div className="dato" style={{fontWeight:"600", marginLeft:"15%"}}>Total: {score.total}%</div>
          </div>
        </div>
        <br />
        <div className="modalPost_subt">{m.wish}</div>
        <br />
        <div className="paragraph">{m.evaluationRisk} <a href="mailto://info@minka-dev.com">info@minka-dev.com</a></div>
        <div className="flex flex-justify flex-evenly flex-wrap" style={{margin:"5%"}}>
            <NormalButton
              id="modalVer"
              color="white"
              bgColor="var(--pink2)"
              label="Cerrar"
              fill="true"
              listener={props.listener}
            />
        </div>
      </div>
    </div>
  )
}

export default ModalPost;
