import styled, { keyframes } from "styled-components";

const jumpAnimation = keyframes`
  0% { transform: scale(1.1); }
  100% { transform: scale(1); }
`

export const AddFixedButton = styled.div`
  position: fixed;
  top: 30%;
  right: 0;
  width: 26.5rem;
  height: 8.2rem;
  cursor: pointer;
  transition: all 0.5s;
  animation-name: ${jumpAnimation};
  animation-duration: 2s;
  display: flex;
  align-items: center;
  background-color: rgba(217,217,217, 0.80);
  padding: 1rem 2rem;
  gap: 1rem;

  img{
    height: 100%;
  }

  span{
    color: #fff;
    font-family: var(--Roboto);
    font-size: 2rem;
    font-weight: 400;
    text-transform: uppercase;
  }
`