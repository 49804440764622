import React from 'react'

export default ({pins, selectedCountry}) =>{
	const selectedPin = pins.find((pin)=> pin[0] === selectedCountry) || ["NA", 42, 29, "N/A"]
	return ( 
	<div className="MapImage">
		<div className="relative">
			<img src="/images2/images/map.png" alt="Mapa" className="w100"/>
			{/* <img src="/images2/graphics/mapa@2x.png" alt="Mapa" className="w100" style={{position: "absolute"}} /> */}
			<img className={`mapImage_pins selectedPin`} alt="pin" 
				src={"/images2/icons/locationIcon.png"}
				style={{
					position: "absolute",
					left: selectedPin[1]+"%",
					top: selectedPin[2]+"%",
					transform: "translateY(-100%)",
				}}
			/>
		</div>
	</div>
	)
}