export const validateYoutubeUrl = (url) => {
  const regx = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/img;
  return regx.test(url);
}
export const getYoutubeId = (url) => {
  const regx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  console.log('url', url)
  let testUrl = regx.test(url)
  if (testUrl) {
    const videoId = regx.match(url);
    return videoId[1];
  }else{
    return false
  } 
}