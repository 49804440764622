import React, { useEffect, useState } from "react";
import DownIcon from "../shared/icons/DownIcon";

const HeaderMenuMobile = ({ texts, currentUser, isMarketplace, marketPlaceLink }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [servicesMenu, setServicesMenu] = useState(false);
  const [consultancyMenu, setConsultancyMenu] = useState(false)
  const [businessMenu, setBusinessMenu] = useState(false);
  const [marketplaceMenu, setMarketplaceMenu] = useState(false)

  const navigate = (url) => {
    window.location = url;
  };

  useEffect(() => {
    let element2 = document.querySelector(".app_content");
    const clickEvent = () => {
      setShowMenu(!showMenu);
    };
    if (showMenu) {
      element2.addEventListener("click", clickEvent);
    }
    return () => {
      if (element2) {
        element2.removeEventListener("click", clickEvent);
      }
    };
  }, [showMenu]);

  return (
    <>
      <nav className="header_menu-icon" onMouseLeave={() => setShowMenu(false)}>
        <a className={`header_menu-icon_cont${showMenu ? " menu_open" : ""}`} onClick={() => setShowMenu(!showMenu)} >
          <span className="menu-icon-line"></span>
          <span className="menu-icon-line"></span>
          <span className="menu-icon-line"></span>
        </a>
        <div className={`header_menu-mobile${showMenu?" is-open": ""}`}>
          <div className="header_menu-mobile_info">
            <div className="header_menu-mobile_cont">
              {isMarketplace && <div className="header_menu-mobile_user">
                <a className="header_user" href={currentUser ? `${marketPlaceLink}/profile/` + currentUser.id : `${marketPlaceLink}/login`}>
                  <img className="header_user_icon" src="/images2/icons/user_icon_tablet_mobile.png" alt="User"/>
                  <span>{currentUser ? `${currentUser.first_name} ${currentUser.last_name}` : texts.header_log_in}</span>
                </a>
              </div>}
              <div className="header_menu_services">
                <nav className="pointer" onClick={()=>setServicesMenu(!servicesMenu)} >
                  <span style={{color: servicesMenu?"#FBCF0D":"#D9D9D9"}}>{texts.header_services.toUpperCase()}</span>
                  <DownIcon width={"2.8rem"} height={"1rem"} color={servicesMenu?"#FBCF0D":"#D9D9D9"} rotate={servicesMenu}/>
                </nav>
                <div className={`header_mobile_submenu${servicesMenu?" is-open": ""}`} >
                  <div className="header_mobile_submenuCont">
                    <nav className="pointer" onClick={()=>setConsultancyMenu(!consultancyMenu)}>
                      <span style={{color: consultancyMenu?"#FBCF0D":"#D9D9D9"}}>{texts.header_consulting.toUpperCase()}</span>
                      <DownIcon width={"2.8rem"} height={"1rem"} color={consultancyMenu?"#FBCF0D":"#D9D9D9"} rotate={consultancyMenu}/>
                    </nav>
                    <div className={`header_mobile_submenu${consultancyMenu?" is-open": ""}`}>
                      <div className={`header_mobile_submenuCont header_consultancy_menuCont`}>
                        <nav className="pointer" onClick={() => navigate("/services")}>
                          <span>{texts.header_diagnosis.toUpperCase()}</span>
                        </nav>
                        <nav className="pointer" onClick={() => navigate("/services")}>
                          <span>{texts.header_strategy.toUpperCase()}</span>
                        </nav>
                        <nav className="pointer" onClick={() => navigate("/services")}>
                          <span>{texts.header_implementation.toUpperCase()}</span>
                        </nav>
                        <nav className="pointer" onClick={() => navigate("/services")}>
                          <span>{texts.header_evaluation.toUpperCase()}</span>
                        </nav>
                      </div>
                    </div>
                    <nav className="pointer" onClick={() => navigate("/bridge2zero")}>
                      <span>BRIDGE<span style={{ color: "#7DA03C", margin: "0" }}>2</span>ZERO</span>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="header_subMenu-mobile">
                <nav className="pointer" onClick={()=>setBusinessMenu(!businessMenu)}>
                  <span style={{color: businessMenu?"#FBCF0D":"#D9D9D9"}}>{texts.header_business.toUpperCase()}</span>
                  <DownIcon width={"2.8rem"} height={"1rem"} color={businessMenu?"#FBCF0D":"#D9D9D9"} rotate={businessMenu}/>
                </nav>
                <div className={`header_mobile_submenu${businessMenu?" is-open": ""}`}>
                  <div className="header_mobile_submenuCont">
                    <nav className="pointer" onClick={() => navigate("/business")}>
                      <span>{texts.header_green.toUpperCase()}</span>
                    </nav>
                    <nav className="pointer" onClick={() => navigate("/business")}>
                      <span>{texts.header_inclusive.toUpperCase()}</span>
                    </nav>
                    <nav className="pointer" onClick={() => navigate("/business")}>
                      <span>{texts.header_cicular.toUpperCase()}</span>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="header_subMenu-mobile">
                <nav className="pointer" onClick={()=>setMarketplaceMenu(!marketplaceMenu)}>
                  <span style={{color: marketplaceMenu?"#FBCF0D":"#D9D9D9"}}>{texts.header_marketplace.toUpperCase()}</span>
                  <DownIcon width={"2.8rem"} height={"1rem"} color={marketplaceMenu?"#FBCF0D":"#D9D9D9"} rotate={marketplaceMenu}/>
                </nav>
                <div className={`header_mobile_submenu${marketplaceMenu?" is-open": ""}`}>
                  <div className="header_mobile_submenuCont">
                    <nav className="pointer" onClick={() => navigate(marketPlaceLink)}>
                      <span>{texts.header_marketplace.toUpperCase()}</span>
                    </nav>
                    <nav className="pointer" onClick={() => navigate(marketPlaceLink + "/plus")}>
                      <span>MINKADEV PLUS</span>
                    </nav>
                  </div>
                </div>
              </div>
              <nav className="pointer" onClick={() => navigate("/about_us")}>
                <span>{texts.header_about_us.toUpperCase()}</span>
              </nav>
              <nav className="pointer" onClick={() => navigate("/blogentries")}>
                <span>{texts.header_news.toUpperCase()}</span>
              </nav>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeaderMenuMobile;
