import React, { useState } from 'react'
import { saveDirect } from "../Form/FormField"
import api from "../api"
import LoadingBars from '../styled_components/shared/LoadingBars'

export default function useUploader(id, model, field, upload_type, saveData) {
  const [file, setFile] = useState(false)
  const [loading, setLoading] = useState(false)

  const destroy = (item) => {
    setLoading(true)
    api.destroyFile(model, upload_type, id, item.file.id, response => {
      if (!response.status) {
        saveDirect(saveData, field, response)
      } else {
        alert(JSON.stringify(response.errors))
        // setMessage(JSON.stringify(response.errors))
      }
      setLoading(false)
    })
  }

  const submit = (file) => {
    if (!file) return
    setLoading(true)
    api.upload(model, upload_type, file, { id, authenticity_token: window.at },
      response => {
        if (!response.status) {
          saveDirect(saveData, field, response)
          setFile(false)
        } else {
          alert(JSON.stringify(response.errors))
          // setMessage(JSON.stringify(response.errors))
        }
        setLoading(false)
      }
    )
  }

  return {file, setFile, submit, destroy, loading}
}
