import styled from "styled-components";


export const NewOpportunityCard = styled.div`
  width: 54.8rem;
  height: 57.9rem;
  background-color: ${({$bgColor}) => $bgColor || "#7E143A"} ;
  padding: 5% 4%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;

  &:hover {
    opacity: 0.8;
  }
  
  h2{
    font-family: var(--BarlowCondensed);
    font-size: 5rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 2%;
  }
  span{
    font-size: 2.5rem;
    text-align: center;
    font-family: var(--Roboto);
    margin: 0;
    font-weight: 500;
  }
`

export const CardLabel = styled.label`
  display: flex;
  width: 100%;
  gap: 2rem;
  align-items: center;
  cursor: pointer;
  
  span{
    font-family: var(--Roboto);
    font-weight: 300;
    font-size: 2.5rem;
    text-align: left;
  }
` 

export const CardRadio = styled.input.attrs({type: "radio"})`

  &::before{
    content: "";
    background-color: white;
    display: flex;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
  
  cursor: pointer;
  appearance: none;
  
  &:checked {
    &::before{
      background-color: orange;
    }
  }

  &:checked ~ span {
    color: orange;
  }
`