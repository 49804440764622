import React from 'react'
import NormalButton from '../shared/NormalButton'

export default ({project,tr,itemRef}) => {
	const regex = /(<([^>]+)>)/ig;

    return (
        <div className="viewProject" ref={itemRef}>
            <img src={project.logo} alt="ProjectImg" />
            <div>{(project.description.replace(/<br>/gi, "").substring(0, 200) + "...").replace(regex, '')}</div>
            <a href={`/business/${project.title}`}>
                <NormalButton id={"viewProject"} label={tr.ourProyects_button}purple={true} color={"#351A3C"} bgColor={"#C8BEC9"}/>
            </a>
        </div>
    )
}
